import React from "react";

import { Main, Page } from '../../../components/Sidebar';


export default function BonusReportPage({ sidebar }: any) {
    return (
        <Page>
            <Main close={sidebar} style={{marginRight:"25vw"}}>
                <div className="m-4">
                    <p className="text-left font-bold text-2xl">BONUS REPORT</p>
                </div>
            </Main>
            
        </Page>
    );
}
