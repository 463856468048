
import { useAxios } from "../../../utils/hooks";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from "react-icons/fa";
import CustomLoader from "../../../components/loader";
import { useState } from "react";

function PeopAddEmp(props: any) {
  const AxiosInstance = useAxios();
  const [loading, setLoading] = useState(false);

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleFileChange = async (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        setLoading(true);

        // Simulate a delay of 3 seconds
        await delay(3000);

        const res = await AxiosInstance.current?.post(`/route/users/postUserExcelDet`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // transformRequest: (data, headers) => {
          //     return data; // Prevent Axios from transforming the FormData
          // },
        });
        if (res && res.data) {
          setLoading(false);
          const messages = res.data.message || [];
          if (messages.length > 0) {
            messages.forEach((errorObj: any) => {
              if (errorObj.status === 400) {
                toast.error(errorObj.error);
              } else {
                toast.success(errorObj.error);
              }
            });
          } else {
            toast.error('An error occurred while submitting the form!!!');
          }
          setTimeout(() => {
            props.close();
          }, 3000);
        } else {
          toast.error('No Data Uploaded.');
        }
      } catch (error) {
        toast.error('An error occurred while submitting the form.');
        console.error('Error:', error);
      }
    } else {
      toast.error('Please select a file to upload.');
    }
  }


  const handledownload = async () => {
    try {
      const response = await AxiosInstance.current?.get('/route/users/downloadExcelfile', {
        responseType: 'blob',
      });
      const blob = new Blob([response?.data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Employee Details.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  }

  return (
    <div className="w-full bg-white p-5 mt-5 mb-5 rounded-lg shadow-lg" >
      {loading && (
        <CustomLoader size={200} colors={"green"} />
      )}
      <div  >
        <div className='text-black p-2'>
          <FaArrowLeft className='fill-blue-500 font-bold text-3xl float-right' onClick={() => props.close()}
            data-tooltip-id={'my-tooltip'} data-tooltip-content="Back to People" />
        </div>
        <div >
          <div className=" p-4 h-[640px] max-h-[150vh] bg-white mx-auto ">
            <p className="text-black text-2xl font-bold mt-2">
              Bulk add employees and contractors
            </p>
            <div className="text-gray text-left mt-4">
              <p>
                1.Please download our template (last updated on 20/02/2023).
              </p>
              <p>
                2.Edit this file in Excel, and add all your
                employees/contractors.
              </p>
              <p>
                3.Save and upload this file below. Any other file will be
                rejected.
              </p>
              <p>
                4.Salary updates performed here will be effective from 1st of
                the current month, to add a custom effective date please use
                bulk salary revision.
              </p>
            </div>
            <br />
            <p className="text-black text-2xl font-bold mt-2">
              Template file instructions -
            </p>
            <div className="text-gray text-left  mt-4">
              <p>
                1. There are two tabs. Please fill in the data accordingly.
              </p>
              <p>2. Please do not add/remove columns.</p>
              <p>
                3. If there is no data for a particular cell, leave it empty.
                Don't enter "-", "NA" etc.
              </p>
              <p>
                4. Please do not use all capital letters in address, name,
                title etc.
              </p>
            </div>
            <br />
            <div className="flex justify-between items-center">
              <div className="relative bg-blue-500 text-white cursor-pointer px-4 py-2 rounded border-2 border-blue-700">
                <label className="block text-left mb-1" htmlFor="file">Upload File</label>
                <input type="file" id="file" name="file" accept=".xlsx" className="hidden" onChange={handleFileChange} />
              </div>
              <div className="relative bg-blue-500 text-white cursor-pointer px-4 py-2 rounded border-2 border-blue-700">
                <button onClick={handledownload}>Download Excel</button>
              </div>
            </div>

            <br />
            <div className="text-left">
              {/* <button className="bg-[#007bff] text-white cursor-pointer text-[10px] px-5 py-2.5 rounded-[5px] border-[none] hover:bg-[#0056b3]">Submit file</button> */}
              <button
                type="button"
                className="bg-[hsl(0,100%,50%)] text-white cursor-pointer text-[10px] px-5 py-2.5 rounded-[5px] border-[none]"
                onClick={() => {
                  props.close();
                }}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default PeopAddEmp;
