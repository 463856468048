/* eslint-disable @typescript-eslint/no-unused-vars */

import { CompanyApi } from "../../../services/company";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

interface User {
    user: string;
    sidebar: boolean;
    setRole: (data: any) => void;
}

export default function AddRole(userData: User) {
    const { addRole, getButton, checkRole } = CompanyApi();
    const [formData, setFormData] = useState<string[]>([]);
    const [Tab, setTabData] = useState<string[]>([]);
    const [Button, setButton] = useState<any>([]);
    const [designation, setDesignation] = useState("");
    const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(false);
    const [isSecondAccordionOpen, setIsSecondAccordionOpen] = useState(false);

    useEffect(() => {
        fetchButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]); // Added userData as dependency

    const fetchButton = async () => {
        const Buttons = await getButton();
        setButton(Buttons.data.data);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await addRole(formData, Tab, designation);
            if (response.status === 200) {
                toast.success('Role added successfully');
                userData.setRole(false);
            } else {
                toast.error('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error('Error in submitting role. Please try again later.');
        }
    };

    const handleFirstAccordionToggle = () => {
        setIsFirstAccordionOpen(!isFirstAccordionOpen);
    };

    const handleSecondAccordionToggle = () => {
        setIsSecondAccordionOpen(!isSecondAccordionOpen);
    };

    const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, buttonValue: string) => {
        const { checked } = event.target;
        setFormData(prevFormData => checked ? [...prevFormData, buttonValue] : prevFormData.filter(value => value !== buttonValue));
    };


    const toggleCheckboxTab = (event: React.ChangeEvent<HTMLInputElement>, buttonValue: string) => {
        const { checked } = event.target;
        setTabData(prevFormData => checked ? [...prevFormData, buttonValue] : prevFormData.filter(value => value !== buttonValue));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDesignation(event.target.value); // Update the input field value
    };

    return (
        <div className="modal-content p-1 shadow-md rounded-lg bg-white ">
            <div className='relative block max-h-[40vw] p-4  bg-gray-100 w-[33vw] overflow-y-scroll'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="text-black block text-center mb-2 text-xl font-bold dark:text-white" htmlFor="addRole">Add Designation</label>
                        <input
                            type="text"
                            id="addRole"
                            name="addRole"
                            value={designation}
                            onChange={handleChange}
                            className="w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-800"
                        />
                    </div>
                    <br />
                    <div className="accordion">
                        <div className={`accordion-item ${isFirstAccordionOpen ? 'open' : ''} border-b`}>
                            <div
                                className={`accordion-title cursor-pointer flex items-center justify-between px-4 py-2 bg-gray-200 h-30`}
                                onClick={handleFirstAccordionToggle}
                            >
                                <span className='text-left font-bold' style={{ width: '500px' }}>Button Access</span>
                                <svg
                                    className={`h-6 w-00 transform transition-transform ${isFirstAccordionOpen ? 'rotate-0' : 'rotate-180'}`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </div>
                            {isFirstAccordionOpen && Button
                                .filter((button: any) => button.buttonValue && button.buttonName !== null)
                                .map((button: any, index: number) => (
                                    button.buttonValue !== null && (
                                        <div key={index} className="text-left m-2">
                                            <div className="flex items-center">
                                                <input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    id={button.buttonValue}
                                                    name={button.buttonValue}
                                                    onChange={(e) => toggleCheckbox(e, button.buttonValue)}
                                                    checked={formData.includes(button.buttonValue)}
                                                />
                                                <label htmlFor={button.buttonValue}>{button.buttonName}</label>
                                            </div>
                                        </div>
                                    )
                                ))}
                        </div>
                        <br />
                        <div className={`accordion-item ${isSecondAccordionOpen ? 'open' : ''} border-b`}>
                            <div
                                className={`accordion-title cursor-pointer flex items-center justify-between px-4 py-2 bg-gray-200 h-30`}
                                onClick={handleSecondAccordionToggle}
                            >
                                <span className='text-left font-bold' style={{ width: '500px' }}>Tab Access</span>
                                <svg
                                    className={`h-6 w-00 transform transition-transform ${isSecondAccordionOpen ? 'rotate-0' : 'rotate-180'}`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </div>
                            {isSecondAccordionOpen && Button
                                .filter((tab: any) => tab.tabValue && tab.tabName !== null)
                                .map((tab: any, index: number) => (
                                    tab.tabValue !== null && (
                                        <div key={index} className="text-left m-2">
                                            <div className="flex items-center">
                                                <input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    id={tab.tabValue}
                                                    name={tab.tabValue}
                                                    onChange={(e) => toggleCheckboxTab(e, tab.tabValue)}
                                                    checked={Tab.includes(tab.tabValue)}
                                                />
                                                <label htmlFor={tab.tabValue}>{tab.tabName}</label>
                                            </div>
                                        </div>
                                    )
                                ))}
                        </div>
                    </div>

                    <div className="flex justify-center space-x-20 mt-4">
                        <div>
                            <button
                                className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                        <div>
                            <button onClick={() => userData.setRole(false)}
                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

