
import { usersUpdate } from "../pages/People/Components/PeopleDetails";
import Functions from "../utils/functions";
import { useAxios } from "../utils/hooks";
import { useEffect, useState } from 'react';
interface users {
  // natureOfEmployment: string,
  firstName: string,
  lastName: string,
  gender: string,
  dateOfBirth: string,
  email: string,
  mobile: number | undefined,
  countryCode: number | undefined,
  monthlySalary: number,
  joiningDate: string,
  designation: string,
  department: string,
  // isTeacher: boolean | undefined,
  location: string,
  // residentIndia: string,
}

interface AddBulk {
  file: "",

}
interface BankDetails {
  accountNo: number;
  id: number;
  ifscCode: string;
  panCardNo: string;
  beneficiaryName: string;
  status?: string;
}
interface Employee {
  id: number;
  natureOfEmployment: string;
  departmentName: string;
  managerName: string;
  firstName: string;
  email: string;
  mobile: number;
  designation: string;
  location: string;
  date: string
}
// interface Doc {
//   id:number,
//   firstName:string,
//   Description:string,
//   file:string
// }

export const UsersApi = () => {
  const AxiosInstance = useAxios();
  const [data, setData] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasLoggedValue] = useState(false);


  const getUserId = async (userId: number) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/users/getData?userId=${userId}`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }


  const fetchusers = async () => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/users/getAllusers`);
      // setData(res.data.data);
      return res.data.data
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  const fetchurl = async () => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/users/getLogoUrl`)
      return res
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  const postbulkusers = async (AddBulk: AddBulk) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/users/postOnusersDetails`, AddBulk);
      setData(res.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }




  const fetchDataall = async () => {
    setLoading(true);
    try {
      const response: any = await AxiosInstance.current?.get('/route/users/getAllusers');
      return response.data.data
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  const postusers = async (Onboarding: users) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(
        `/route/users/postOnusersDetails`,
        Onboarding
      );
      setData(res.data.data);

      return {
        status: res.status,
        // Include other properties from the response if needed
      };
    } catch (err: any) {
      setError(err);
      return {
        status: err.response ? err.response.status : 500,
        // Include other properties from the error response if needed
      };
    } finally {
      setLoading(false);
    }
  };


  const postFile = async (file: File) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const res: any = await AxiosInstance.current?.post(
        '/route/users/postOnusersDetails',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setData(res.data.data);

      return {
        status: res.status,
        // Include other properties from the response if needed
      };
    } catch (err: any) {
      setError(err);
      return {
        status: err.response ? err.response.status : 500,
        // Include other properties from the error response if needed
      };
    } finally {
      setLoading(false);
    }
  };




  const fetchDataemploye = async () => {
    setLoading(true);
    try {
      const response: any = await AxiosInstance.current?.get('/route/users/getAllemployee');
      return response.data.data
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };


  const fetchDatacontarctors = async () => {
    setLoading(true);
    try {
      const response: any = await AxiosInstance.current?.get(`/route/users/getAllcontractors`);
      return response.data.data
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchBulkOnbording = async () => {
    setLoading(true);
    try {
      const response: any = await AxiosInstance.current?.get(`route/Onboarding/postbulkemployee`)
      if (response && response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
    }
    setLoading(false)
  }


  const getUserCount = async () => {
    const res: any = await AxiosInstance.current?.get(`/route/users/getAllCount`);
    return res;
  }
  const AssignAllRole = async () => {
    const res: any = await AxiosInstance.current?.get(`/route/roles/getAllRoles`);
    return res.data;
  }

  const getRoleMenus = async () => {
    const res: any = await AxiosInstance.current?.get(`/route/roles/getRolesMenusByRole`);
    return res.data;

  }

  const getAllManager = async (selectedValue: string | null) => {
    const res: any = await AxiosInstance.current?.get(`/route/users/getAllmanager?value=${selectedValue}`);
    return res.data;

  }

  const getAllDepartment = async () => {
    const res: any = await AxiosInstance.current?.get(`/route/Company/getAlldepartment`);
    return res.data;

  }

  const getAllJobRole = async () => {
    const res: any = await AxiosInstance.current?.get(`/route/users/getAllDesignation`);
    return res.data;

  }

  const templateDownload = async () => {
    const res:any = await AxiosInstance.current?.get(`/route/users/templateDownload`, {
      responseType: 'blob',
    });
    return res.data;
  }

  const downloadExitData = async () =>{
    const res:any = await AxiosInstance.current?.get(`/route/users/downloadExitData`, {
      responseType: 'blob',
    });
    return res.data;
  }


  const uploadExcelFile = async (formData: any) => {
    const res: any = await AxiosInstance.current?.post(`/route/users/uploadExcelFile`, formData);
    return res.data;
  }

  const handleView = async () => {

    // Make an API call to fetch the file data
    const response = await AxiosInstance.current?.get(`/route/Company/getFileData`); // Replace with your API endpoint
    return response
  };

  const updateUserData = async (updateUserdata: usersUpdate) => {
    try {
      setLoading(true);
      const res = await AxiosInstance.current?.post(`/route/users/updateUserdata`, updateUserdata);
      return res;
    } catch (err: any) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  const geteBankDetails = async (userId: number) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/bankDetails/getBankDetails?userId=${userId}`);
      const DecryptedData = Functions.decryptedData(res.data.data);
      return DecryptedData;
    } catch (err: any) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  ///don't remove sanket
  // const geteBankDetails = async (userId: number) => {
  //   try {
  //     setLoading(true);
  //     const res: any = await AxiosInstance.current?.get(`/route/bankDetails/getBankDetails?userId=${userId}`);
  //     console.log("res>>>", res);
  //     const secretKey = "asdfghjklasdfghjklasdfghjklasdfg";
  //     const iv = "asdfghjklasdfghj";
  //     const decipher = createDecipheriv("aes-256-cbc", secretKey, iv);
  //     let decryptedData = decipher.update(res.data.data, "hex", "utf8");
  //     decryptedData += decipher.final("utf8");
  //     console.log("Decrypted Data:", decryptedData);
  //     return decryptedData;
  //   } catch (err: any) {
  //     return err;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const updateBankDetails = async (BankDetails: BankDetails) => {
    try {
      setLoading(true);
      const res = await AxiosInstance.current?.post(`/route/bankDetails/updateBankDetails`, BankDetails);
      return res;
    } catch (err: any) {
      return err;
    } finally {
      setLoading(false);
    }
  };
  const getUserDocData = async (userId: number) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/users/getUserDocData?userId=${userId}`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  const generateDoc = async (data: any) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/users/generateDoc`, data, {
        responseType: 'blob', // Set the response type to blob
      });
      return res
    } catch (error: any) {
      setError(error)
      throw error;
    } finally {
      setLoading(false)
    }
  }

  const generateDocEmail = async (data: any) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/users/generateDocEmail`, data, {
      });
      return res
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  const Reminder = async (userId: number) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/users/sendReminder`, { userId });
      return res.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const postCheckEmail = async (checkEmail: any, type: string) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/users/postCheckEmail`, { checkEmail, type });
      return res;
    } catch (error: any) {
      setError(error);
    }
  }
  const generateTemplate = async (data: any) => {
    try {
      const res: any = await AxiosInstance.current?.post(`http://localhost:3002/route/users/generateTemplate`, data);
      return res
    } catch (error) {
      throw error;
    } finally {
    }
  }

  const readTemplate = async (data: any) => {
    console.log("data>>>", data);
    try {
      const res: any = await AxiosInstance.current?.get(`http://localhost:3002/route/users/readTemplate?Document_Type=${data}`);
      return res.data
    } catch (error) {
      throw error;
    } finally {
    }
  }
  const getAlllevel = async (value:any) => {
    const res: any = await AxiosInstance.current?.get(`/route/matrixPay/getAllmatrixLevel?value=${value}`);
    return res.data;

  }


  // const Editbutton = async () =>{
  //  const response = await AxiosInstance.current?.get (`/route/   /getData`)
  //  return response
  // }
  useEffect(() => {
    // Optionally, you can cancel the request on unmount.
    // This requires using axios's cancel token feature.

  }, [hasLoggedValue, data]);

  return { data, loading, error, downloadExitData, uploadExcelFile, templateDownload, fetchurl, getAllJobRole, getAllManager, getAllDepartment, postFile, getUserId, handleView, AssignAllRole, fetchusers, postusers, fetchDataall, fetchDataemploye, fetchDatacontarctors, fetchBulkOnbording, postbulkusers, getUserCount, updateUserData, updateBankDetails, geteBankDetails, getRoleMenus, getUserDocData, generateDoc, generateDocEmail, Reminder, postCheckEmail, generateTemplate, readTemplate, getAlllevel }
}