import { useEffect, useState } from "react";

interface Props {
    isValue: any[];
    setCurrentItems: (pageNumber: any) => void;
}
export default function Pagination(props: Props) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = props.isValue.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(props.isValue.length / itemsPerPage);
    const startButton = Math.max(1, currentPage - 3);
    const visibleButtons = Array.from({ length: Math.min(4, totalPages) }, (_, index) => startButton + index);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);

    };
    useEffect(() => {
        props.setCurrentItems(currentItems);
        if (currentPage > totalPages) {
            setCurrentPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPages, currentPage, props.isValue])
    return (
        <div className='flex justify-center items-center my-4'>


            <button type="button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-2 py-2 mr-2 text-xs font-medium text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                Previous
            </button>
            {(currentPage > 5) && (
                <button type="button"
                    onClick={() => handlePageChange(1)}
                    className={`px-4 py-2 text-xs font-medium text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition ${currentPage === 1 ? 'bg-blue-500 text-white border-blue-500' : ''}`}
                >
                    {1}
                </button>
            )}

            {(totalPages > 4 && currentPage > 5) && <span className="px-4 py-2 text-gray-300 text-4xl font-medium">...</span>}

            <div className='flex space-x-2 overflow-hidden'>
                {visibleButtons.map((pageNumber) => (
                    <button type="button"
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={`px-4 py-2 text-xs font-medium text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition ${currentPage === pageNumber ? 'bg-blue-500 text-white border-blue-500' : ''}`}
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>
            {(totalPages > 4 && currentPage < totalPages) && <span className="px-4 py-2 text-gray-300 text-4xl font-medium">...</span>}

            {(totalPages > 4 && currentPage < totalPages) && (
                <button type="button"
                    onClick={() => handlePageChange(totalPages)}
                    className={`px-4 py-2 text-xs font-medium text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition ${currentPage === totalPages ? 'bg-blue-500 text-white border-blue-500' : ''}`}
                >
                    {totalPages}
                </button>
            )}
            <button type="button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 text-xs ml-2 font-medium text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                Next
            </button>
        </div>
    )
}

