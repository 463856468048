
import { CompanyApi } from "../../../services/company";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";


// interface User {
//     user: string;
//     sidebar: boolean;
//     setDepartmentDetails: (data: any) => void;
// }


const validationSchema = Yup.object({
    departmentName: Yup.string().required('Department Name is required'),
    // shift: Yup.string().required('Shift is required'),
    // startTime: Yup.string().required('Start Time is required'),
    // endTime: Yup.string().required('End Time is required'),
});

interface props { getCompanyData: () => Promise<void>; user: string; sidebar: boolean; setDepartmentDetails: Dispatch<SetStateAction<boolean>>; }


export default function DepartmentDetails(props: props) {
    const { addDepartmentDetails } = CompanyApi();
    const formik = useFormik({
        initialValues: {
            departmentName: '',
            // shift: '',
            // startTime: '',
            // endTime: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                // Call your API or perform the necessary actions to submit the form
               const response =  await addDepartmentDetails(values);
                // Display success message
                if(response.data.status === 200 ){
                    toast.success(response.data.message);
                    props.getCompanyData();
                    props.setDepartmentDetails(false);
                }
                else{
                    toast.error(response.data.message);
                }
            } catch (error) {
                // Display error message
                toast.error('Error submitting department details');
            }
        },
    });

    return (


        <div className="modal-content py-4 px-6 rounded-lg bg-white">
            <div className='relative block max-h-[40vw] shadow-md rounded-lg bg-white w-[30vw] '>
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        {/* <h2 className="text-xl font-bold text-center py-4 px-6 text-white">Department Name</h2><br /> */}
                        <label className="text-black block text-center mb-2 text-md font-bold dark:text-white" htmlFor="departmentName">Department Name</label>
                        <input
                            type="text"
                            id="departmentName"
                            name="departmentName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.departmentName}
                            className={`block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border  ${formik.touched.departmentName && formik.errors.departmentName
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                        {formik.touched.departmentName && formik.errors.departmentName ? (
                            <div className="text-red-500 text-left">{formik.errors.departmentName}</div>
                        ) : null}
                        </div>
                    {/* </div>
                    <div>
                        <h2 className="text-xl font-bold text-center py-4 px-6 text-white">Shift</h2><br />
                        <label className="text-white block text-left mb-2 text-xl font-medium dark:text-white" htmlFor="departmentName">Shift</label>
                        <input
                            type="text"
                            id="shift"
                            name="shift"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shift}
                            className={`block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border ${formik.touched.shift && formik.errors.shift
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                        {formik.touched.shift && formik.errors.shift ? (
                            <div className="text-red-500 text-left">{formik.errors.shift}</div>
                        ) : null}
                    </div>
                    <div>
                        <h2 className="text-xl font-bold text-center py-4 px-6 text-white">Start Time</h2><br />
                        <label className="text-white block text-left mb-2 text-xl font-medium dark:text-white" htmlFor="departmentName">startTime</label>
                        <input
                            type="time"
                            id="startTime"
                            name="startTime"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.startTime}
                            className={`block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border ${formik.touched.startTime && formik.errors.startTime
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                        {formik.touched.startTime && formik.errors.startTime ? (
                            <div className="text-red-500 text-left">{formik.errors.startTime}</div>
                        ) : null}
                    </div>
                    <div>
                        <h2 className="text-xl font-bold text-center py-4 px-6 text-white">Start Time</h2><br />
                        <label className="text-white block text-left mb-2 text-xl font-medium dark:text-white" htmlFor="departmentName">endTime</label>
                        <input
                            type="time"
                            id="endTime"
                            name="endTime"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.endTime}
                            className={`block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border ${formik.touched.endTime && formik.errors.endTime
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                        {formik.touched.endTime && formik.errors.endTime ? (
                            <div className="text-red-500 text-left">{formik.errors.endTime}</div>
                        ) : null}
                    </div> */}
                    {/* Repeat the above pattern for other input fields (shift, startTime, and endTime) */}

                    <div className="flex justify-center space-x-20 mt-4">
                        <div>
                            <button
                                className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    props.setDepartmentDetails(false);
                                }}
                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
