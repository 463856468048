import React from "react";
import { Fullpage, Page } from '../../../components/Sidebar';


export default function HREmpolyeeView({ sidebar }: any) {
    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="m-4 w-9/12">
                    <p className="text-center font-bold text-2xl">HR- Employee View</p>
                </div>
            </Fullpage>
        </Page>
    );
}
