import React, { useEffect, useReducer, useRef, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa';
import { IoRadioButtonOnSharp } from 'react-icons/io5'
import { FingerMachineApi } from '../../../services/FingerMachineService';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface MachineData {
  id: number;
  machineID: string;
  ipAddress: string;
  done: boolean;
}
interface MachineErrors {
  machineID: string;
  ipAddress: string;
}

const machineSchema = Yup.object().shape({
  machineID: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'not allow special characters').required('Machine ID is required'),
  ipAddress: Yup.string().matches(
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
    'Invalid IP address'
  ).required('IP address is required'),
});

export default function FingerPrintSetup() {
  const run = useRef(false);
  const [machines, dispatch] = useReducer(machinesReducer, []);
  const [machine, setMachine] = useState<MachineData>({ id: NaN, machineID: '', ipAddress: '', done: false });
  const { fetchMachine, AddMachine, deleteMachineid } = FingerMachineApi();
  const [machineErrors, setMachineErrors] = useState<MachineErrors>({ machineID: '', ipAddress: '' });


  useEffect(() => {
    if (run.current) return;
    run.current = true
    updatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);


  async function handleAddTask(machine: MachineData) {
    try {

      setMachineErrors({ machineID: '', ipAddress: '' });
      await machineSchema.validate(machine, { abortEarly: false });
      if (machine.machineID !== "" && /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(machine.ipAddress)) {
        setMachine({ id: NaN, machineID: '', ipAddress: '', done: false });

        const data = await AddMachine(machine);
        if (data.status === 200) {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        if (data !== undefined) {
          dispatch({
            type: 'added',
            id: data.data.id,
            machineID: machine.machineID,
            ipAddress: machine.ipAddress,
          });
        }
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = error.inner.reduce((acc, { path, message }) => ({
          ...acc,
          [path as keyof MachineErrors]: message,
        }), {} as Partial<MachineErrors>);

        setMachineErrors(prevErrors => ({ ...prevErrors, ...errors }));
      }

    }
  }

  function handleChangeTask(machine: any) {
    dispatch({
      type: 'changed',
      machine: machine,
    });
  }

  async function handleDeleteTask(machineId: any) {
    const data = await deleteMachineid(machineId);
    if (data.status === 200) {
      toast.success(data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (data !== undefined) {
      dispatch({
        type: 'deleted',
        id: machineId,
      });
    }
  }

  async function updatedData() {
    try {
      const data = await fetchMachine();
      dispatch({
        type: 'setInitialTasks',
        machines: data,
        id: data.id,
      });

    } catch (error) {
      console.error('Error fetching holiday data:', error);
    }
  }
  return (
    <div className="bg-sky-100 shadow-md rounded-lg max-h-96 ">
      <h1 className='text-3xl font-bold bg-sky-100 text-left mx-2 pt-2'>Add Fingerprint Machines</h1>
      <div className='pb-2'>
        <div className='bg-sky-100 w-full flex justify-between '>
          <div className='flex '>
            <label className='ml-2 mt-2.5'>machine ID :</label>
            <div className='flex-col '>
              <div>
                <input className='bg-sky-100  text-center  text-gray-700 p-1 m-2'
                  type='text'
                  placeholder="machineID"
                  value={machine.machineID}
                  onChange={(e) => setMachine({ ...machine, machineID: e.target.value })}
                />
              </div>
              <div>{machineErrors.machineID && <b className='text-red-500 text-xs mt-1'>{machineErrors.machineID}</b>}</div>
            </div>
          </div>
          <div className='flex'>
            <label className='mt-2.5' >IP Address :</label>
            <div className='flex-col '>
              <div>
                <input className='bg-sky-100  text-center text-gray-700 p-1 m-2'
                  type='text'
                  placeholder="ipAddress"
                  value={machine.ipAddress}
                  onChange={(e) => setMachine({ ...machine, ipAddress: e.target.value })}
                />
              </div>
              <div>
                {machineErrors.ipAddress && <b className='text-red-500 text-xs mt-1'>{machineErrors.ipAddress}</b>}
              </div>
            </div>
          </div>
          <div>
            <button className='text-white bg-sky-500 hover:bg-sky-600 hover:border-sky-900 focus:ring-2 focus:outline-none focus:ring-sky-300 active:bg-sky-900 rounded-md text-sm px-5 py-2 m-2'
              onClick={() => {
                handleAddTask(machine);
              }}>
              Add
            </button>
          </div>
        </div>

        <div className='overflow-y-scroll max-h-80'>
          <table className="table w-full">
            <thead className='sticky top-0'>
              <tr className='bg-sky-800'>
                <th className="py-3 w-20  text-center text-slate-800">Online</th>
                <th className="py-3 text-center text-slate-800">Machine Id</th>
                <th className="py-3 text-center text-slate-800">IP Address</th>
                <th className="py-3 w-20 text-center text-slate-800">Action</th>
                <th className="py-3 w-20 text-center text-slate-800">Delete</th>
              </tr>
            </thead>
            <tbody className="bg-white font-medium divide-y-2">
              {machines.map((machine: any) => (
                <tr key={machine.id} className='bg-sky-100'>
                  <Machine machine={machine} onChange={handleChangeTask} onDelete={handleDeleteTask} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

function Machine({ machine, onChange, onDelete }: any) {
  const [isEditing, setIsEditing] = useState(false);
  const [machineErrors, setMachineErrors] = useState<MachineErrors>({ machineID: '', ipAddress: '' });
  const { updateMachineid } = FingerMachineApi();
  async function updateMachine() {
    try {

      setMachineErrors({ machineID: '', ipAddress: '' });
      await machineSchema.validate(machine, { abortEarly: false });
      if (machine.machineID !== "" && /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(machine.ipAddress)) {
        setIsEditing(false);
        const data = await updateMachineid(machine);
        if (data.status === 200) {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = error.inner.reduce((acc, { path, message }) => ({
          ...acc,
          [path as keyof MachineErrors]: message,
        }), {} as Partial<MachineErrors>);

        setMachineErrors(prevErrors => ({ ...prevErrors, ...errors }));
      }

    }
  }
  return (
    <>
      <td>
        {
          machine.done ? (
            <div className='flex justify-center m-1'>
              <IoRadioButtonOnSharp className='fill-green-700 ' />
            </div>) : (<div className='flex justify-center m-4'>
              <IoRadioButtonOnSharp className='fill-red-700' />
            </div>)

        }
      </td>

      {isEditing ? (
        <>
          <td className='w-48'>
            <div>
              <input className={`bg-sky-100 text-center p-1 rounded-sm w-48 border ${machineErrors.machineID ? 'border-red-500 placeholder-red-700 mt-6 focus:ring-red-500  focus:border-red-500' : ''} `}
                type='text'
                value={machine.machineID}
                placeholder='machineID'
                onChange={(e) => {
                  onChange({
                    ...machine,
                    machineID: e.target.value,
                  });
                }}
              />
            </div>
            {machineErrors.machineID && (
              <b className='text-red-500 text-xs w-full text-center'>{machineErrors.machineID}</b>
            )}
          </td>
          <td className='w-48'>
            <div>
              <input className={`bg-sky-100 text-center p-1 rounded-sm w-48 border ${machineErrors.ipAddress ? 'border-red-500 placeholder-red-700 mt-6 focus:ring-red-500  focus:border-red-500' : ''} `}
                type='text'
                value={machine.ipAddress}
                placeholder='ipAddress'
                onChange={(e) => {
                  onChange({
                    ...machine,
                    ipAddress: e.target.value,
                  });
                }}
              />
            </div>
            {machineErrors.ipAddress && (
              <b className='text-red-500 text-xs w-full text-center'>{machineErrors.ipAddress}</b>
            )}
          </td>

          <td>
            <button onClick={updateMachine}>Save</button>
          </td>
        </>
      ) : (
        <>
          <td className='w-48'>
            {machine.machineID}
          </td>
          <td className='w-48'>
            {machine.ipAddress}
          </td>
          <td>
            <button onClick={() => setIsEditing(true)}>Edit</button></td>
        </>
      )}

      <td className="text-center text-sky-900 p-2 ">
        <div className="grid place-items-center">
          <FaTrashAlt className="fill-red-600  " onClick={() => onDelete(machine.id)}>
            Delete
          </FaTrashAlt>
        </div>
      </td>
    </>
  );
}

function machinesReducer(machines: any, action: any) {
  switch (action.type) {
    case 'added': {
      return [
        ...machines,
        {
          id: action.id,
          machineID: action.machineID,
          ipAddress: action.ipAddress,
          done: false,
        },
      ];
    }
    case 'changed': {
      return machines.map((t: any) => {
        if (t.id === action.machine.id) {
          return action.machine;
        } else {
          return t;
        }
      });
    }
    case 'deleted': {
      return machines.filter((t: any) => t.id !== action.id);
    }
    case 'setInitialTasks':
      return action.machines;
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
