import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import { UsersApi } from "../../../services/users";
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { FaArrowLeft } from 'react-icons/fa';
import { PayableSalaryApi } from '../../../services/payableSalary';
import wordsToNumbers from 'words-to-numbers';


interface Buttonstype {
  employeeCode: string,
  // natureOfEmployment: string,
  gender: string,
  firstName: string,
  lastName: string,
  email: string,
  // role: string,
  matrixLevel: string,
  countryCode: number | undefined,
  mobile: number | undefined,
  personalContactNumber: number | undefined,
  // emergencyContactNumber: number | undefined,
  // personalContactEmailId: string,
  // aadharCardDetails: string,
  // panCardDetails: string,
  permanentAddress: string,
  currentAddress: string,
  // emergencyContactPerson: string,
  // bloodGroup: string,
  age: number,
  experienceYear: number,
  monthlySalary: number,
  joiningDate: string,
  dateOfBirth: string,
  designation: string,
  department: string,
  managerId: string,
  isTeacher: boolean,
  location: string,
  // residentIndia: string,
}

interface MatrixDataItem {
  [key: string]: any;
}
const boardUpSchema = Yup.object({
  firstName: Yup.string().min(2).max(25).required('Enter Your First Name !'),
  lastName: Yup.string().min(2).max(25).required('Enter Your Last Name !'),
  email: Yup.string().email().required("Enter Your Email !"),
  monthlySalary: Yup.number().required("Annual Salary is required").typeError('Annual Salary ir required'),
  experienceYear: Yup.number().required("Working Experience is required").typeError('Working Experience is required'),

  age: Yup.number().required("Age is required").typeError('Age is required'),
  // role: Yup.string().required("Select Role !"),
  countryCode: Yup.mixed(),
  mobile: Yup.number()
    .required('Mobile number is required')
    .test('is-ten-digits', 'Mobile number must have exactly 10 digits', (value) => {
      if (!value) return false;
      return /^\d{10}$/.test(value.toString());
    }),
  personalContactNumber: Yup.number()
    .required('Mobile number is required')
    .test('is-ten-digits', 'Mobile number must have exactly 10 digits', (value) => {
      if (!value) return false;
      return /^\d{10}$/.test(value.toString());
    }),
  // emergencyContactNumber: Yup.number()
  //   .required('Mobile number is required')
  //   .test('is-ten-digits', 'Mobile number must have exactly 10 digits', (value) => {
  //     if (!value) return false;
  //     return /^\d{10}$/.test(value.toString());
  //   }),
  // aadharCardDetails: Yup.string()
  //   .required('Enter Your Aadhar Card Details!')
  //   .matches(/^\d{12}$/, 'Invalid Aadhar Card Number'),
  // panCardDetails: Yup.string()
  //   .required('Enter Your Pan Card Details!')
  //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN Card Number'),
  // bloodGroup: Yup.string().required("Enter Your Blood Group!"),
  // personalContactEmailId: Yup.string().email().required("Enter Your Personal Email ID !"),
  permanentAddress: Yup.string().required("Enter Your Permanent Address !"),
  // emergencyContactPerson: Yup.string().required("Enter Emergency Contact Person Name !"),
  joiningDate: Yup.string().min(4).max(20).required('Enter Your Hiring Date !'),
  currentAddress: Yup.string().min(4).max(20).required('Enter Your Current Address !'),
  department: Yup.string().required('Select Your Department Name!'),
  // managerId: Yup.string().required('Enter Your Manager Name !'),
  location: Yup.string().required('Select Your Location !'),
  // residentIndia: Yup.string().required('Select Your Resident'),
  dateOfBirth: Yup.date().max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0], ({ max }) => `Date Of Birth must be earlier than ${max}`).required("Enter your date of birth"),
  // isTeacher: Yup.string().required("select isTeacher or not")
})



function PeopleUser(props: any) {
  const { postusers, getAllManager, getAllDepartment, getAllJobRole, postCheckEmail, getAlllevel } = UsersApi();
  // const [roles, setRoles] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [managers, setManager] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [jobRole, setJobRole] = useState([]);
  const [salaryLevel, setSalaryLevel] = useState([]);
  const { getMatrixData } = PayableSalaryApi();
  const [matrixAllData, setMatrixData] = useState<MatrixDataItem[]>([]);

  const formIk = useFormik<Buttonstype>({
    validationSchema: boardUpSchema,
    initialValues: {
      employeeCode: "",
      firstName: "",
      lastName: "",
      email: "",
      matrixLevel: "",
      // personalContactEmailId: "",
      monthlySalary: 0,
      experienceYear: 0,
      // bloodGroup: "",
      age: 0,
      // role: "",
      mobile: undefined,
      personalContactNumber: undefined,
      // emergencyContactNumber: undefined,
      permanentAddress: "",
      currentAddress: "",
      countryCode: undefined,
      // emergencyContactPerson: "",
      // panCardDetails: "",
      // aadharCardDetails: "",
      joiningDate: "",
      dateOfBirth: "",
      designation: "",
      department: "",
      managerId: "",
      isTeacher: false,
      location: "",
      // residentIndia: "",
      // natureOfEmployment: "",
      gender: ""
    },
    onSubmit: async (values) => {
      try {
        const response = await postusers(values);
        // Check if response status is 200 for success
        if (response && response.status === 200) {

          toast.success('Form submitted successfully!');
          // Close the element or modal when form is submitted successfully
          // Assuming props.close() closes the modal or element
          setTimeout(() => {
            // Close the element or modal after showing the success message
            props.close(); // Assuming props.close() closes the modal or element
          }, 1000); //
        } else {
          // Display error for other status codes or undefined responses
          toast.error('Form submission failed!');
        }
      } catch (error) {
        console.error('An error occurred:', error);
        toast.error('Form submission failed due to an error.');
      }
    },
  });
  useEffect(() => {
    // getRole();
    getDepartment();
    getAllJobRoles();
    // getAllsalaryLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const getRole = async () => {
  //   const roles = await AssignAllRole();
  //   setRoles(roles.data);
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getManager = async (user: string) => {
    const managers = await getAllManager(user);
    setManager(managers.data)

  }

  const getDepartment = async () => {
    const departments = await getAllDepartment();
    setDepartment(departments.data)

  }

  const getAllJobRoles = async () => {
    const Designation = await getAllJobRole()
    setJobRole(Designation.data.data)

  }

  // const getAllsalaryLevel = async () => {
  //   // console.log(matrixLevel);

  //   const salaryLevelData = await getAlllevel(matrixLevel)
  //   console.log(salaryLevelData);

  //   setSalaryLevel(salaryLevelData.data)

  // }
  // const handleLevelChange = async (e: any) => {
  //   const { value } = e.target;
  //   formIk.handleChange(e); // Update formIk state

  //   // Fetch and set the salary data based on the selected level
  //   const salaryLevelData = await getAlllevel(value);
  //   setSalaryLevel(salaryLevelData.data)
  //   // Update local state with salary options
  //   formIk.setFieldValue('monthlySalary', ''); // Reset monthlySalary selection
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;

    // Use setFieldValue to update the field value
    formIk.setFieldValue("isTeacher", selectedValue === "true");

    // Assuming you have an API call function named getManager() defined elsewhere
    if (selectedValue === 'true') {
      // Make API call for Yes selection
      getManager("true"); // Pass appropriate parameters for 'Yes'
    } else if (selectedValue === 'false') {
      // Make API call for No selection
      getManager("false"); // Pass appropriate parameters for 'No'
    }
  };

  const fullPhoneNumber = typeof formIk.values.mobile === 'number'
    ? `${formIk.values.mobile}`
    : '';

  // const handleKeyUpPersonalContactEmailId = async (event: any) => {
  //   await handleKeyUp(event, 'personalContactEmailId');
  // }

  const handleKeyUpEmail = async (event: any) => {
    await handleKeyUp(event, 'email');
  }

  const handleKeyUpEmployeeCode = async (event: any) => {
    await handleKeyUp(event, "employeeCode")
  }

  const calculateAge = (dateOfBirth: string): number => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateOfBirth = e.target.value;
    formIk.setFieldValue('dateOfBirth', dateOfBirth);

    if (dateOfBirth) {
      const age = calculateAge(dateOfBirth);
      formIk.setFieldValue('age', age.toString());
    } else {
      formIk.setFieldValue('age', '');
    }
  };

  const fetchMatrixData = async () => {
    try {
      const response = await getMatrixData();
      setMatrixData(response.data.data);
    } catch (error) {
      console.error('Error fetching matrix data:', error);
      // Handle error here, such as displaying an error message to the user
    }
  };

  useEffect(() => {
    fetchMatrixData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const fetchMatrixData = async () => {
  //   try {
  //     const response = await getMatrixData();
  //     setMatrixData(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching matrix data:", error);
  //     // Handle error here, such as displaying an error message to the user
  //   }
  // };
  const convertColumnName = (name: string) => {
    if (name === 'experienceYear') return "Experience In Year";
    if (name === 'levelThirteenk') return "Level 13(C)";
    const match = name.match(/level(\w+)/);
    if (match) {
      const numericPart = wordsToNumbers(match[1]);
      return `Level ${numericPart}`;
    }

    return name;
  };

  const columnsToSkip = ['id', 'experienceYear', 'updatedAt', 'createdAt'];
  const filteredKeys = Object.keys(matrixAllData[0] || {}).filter(
    (key) => !columnsToSkip.includes(key)
  );
  const handleLevelChange = async (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    formIk.handleChange(e); // Update formik state

    // Fetch and set the salary data based on the selected level
    try {
      const salaryLevelData = await getAlllevel(value); // Inspect the structure of the data
      // Assuming salaryLevelData.data is an array of objects
      const formattedSalaryLevels = salaryLevelData.data.map((salary: { [x: string]: any; }) => {
        const key = Object.keys(salary)[0]; // Get the first key (e.g., 'levelOne')
        return salary[key]; // Extract the value corresponding to the key
      });
      setSalaryLevel(formattedSalaryLevels);
    } catch (error) {
      console.error('Error fetching salary level data:', error);
    }

    formIk.setFieldValue('monthlySalary', 0); // Reset monthlySalary selection
  };
  const handleKeyUp = async (event: any, fieldName: string) => {
    try {
      const typedValue = event.target.value;
      let response;
      if (fieldName === 'email') {
        response = await postCheckEmail(typedValue, 'email');
      } else if (fieldName === 'personalContactEmailId') {
        response = await postCheckEmail(typedValue, 'personalContactEmailId');
      } else if (fieldName === 'employeeCode') {
        response = await postCheckEmail(typedValue, "employeeCode");
      }
      if (response.data.status === 400) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while checking the email.");
    }
  }


  return (
    <div className="w-full bg-white p-5 mt-5 mb-5 rounded-lg shadow-lg" >
      <div className='float-right text-black p-2'>
        <FaArrowLeft className='fill-blue-500 font-bold text-3xl' onClick={() => props.close()}
          data-tooltip-id={'my-tooltip'} data-tooltip-content="Back to People" />
      </div>
      <form
        className="p-4 mt-10 bg-white border border-t-gray-300 rounded-lg w-3/4 mx-auto shadow-md"
        onSubmit={formIk.handleSubmit}
      >
        <div >

          <h1>Employee Onboarding</h1>
          <br />
          <label className='block text-left mb-[5px]' htmlFor="name">Employee Code</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="employeeCode"
            name="employeeCode"
            value={formIk.values.employeeCode}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            onKeyUp={handleKeyUpEmployeeCode}
            placeholder="Employee Code"
          />
          {formIk.errors.employeeCode && formIk.touched.employeeCode ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.employeeCode}</label>
          ) : null}
          {/* <div className="text-left">
            <h1>Is he/she a Manager or Not</h1>
            <div>
              <input
                type="radio"
                id="managerYes"
                name="isTeacher"
                value="true"
                checked={formIk.values.isTeacher === true}
                onChange={(e) => handleInputChange(e)}
                onBlur={formIk.handleBlur}
              />
              <label htmlFor="managerYes" className="ml-2">
                Yes
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="managerNo"
                name="isTeacher"
                value="false"
                checked={formIk.values.isTeacher === false}
                onChange={(e) => handleInputChange(e)}
                onBlur={formIk.handleBlur}
              />
              <label htmlFor="managerNo" className="ml-2">
                No
              </label>
            </div>

            {formIk.errors.isTeacher && formIk.touched.isTeacher ? (
              <label className="text-sm text-red-600 block text-left mt-1">
                {formIk.errors.isTeacher}
              </label>
            ) : null}
          </div>
          <label className='block text-left mb-[5px]' htmlFor="location">Manager Name</label>
          <select
            id="managerId"
            name="managerId"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.managerId}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.managerId && formIk.touched.managerId ? (
              <label className="text-sm text-red-600">{formIk.errors.managerId}</label>
            ) : null}
            <option value='' disabled>Select Manager</option>
            {managers.map((managers: any) => (
              <option value={managers?.employeeCode}>{managers?.firstName}{` (${managers?.id})`}</option>
            ))}
          </select> */}
          <label className='block text-left mb-[5px]' htmlFor="name">First Name</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="firstName"
            name="firstName"
            value={formIk.values.firstName}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Name"
          />
          {formIk.errors.firstName && formIk.touched.firstName ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.firstName}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="name">Last Name</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="lastName"
            name="lastName"
            value={formIk.values.lastName}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Name"
          />
          {formIk.errors.lastName && formIk.touched.lastName ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.lastName}</label>
          ) : null}
          <br />
          <div className="text-left">
            <h1 >Select Your Gender ?</h1>
            <div>
              <input
                type="radio"
                id="gender"
                name="gender"
                value="Male"
                checked={formIk.values.gender === "Male"}
                onChange={formIk.handleChange}
                onBlur={formIk.handleBlur}
              />

              <label htmlFor="Male" className="ml-2">
                Male
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="gender"
                name="gender"
                value="Female"
                checked={formIk.values.gender === "Female"}
                onChange={formIk.handleChange}
                onBlur={formIk.handleBlur}
              />
              <label htmlFor="Female" className="ml-2">
                Female
              </label>

            </div>

            <div>
              <input
                type="radio"
                id="gender"
                name="gender"
                value="Other"
                checked={formIk.values.gender === "Other"}
                onChange={formIk.handleChange}
                onBlur={formIk.handleBlur}
              />
              <label htmlFor="other" className="ml-2">
                Other
              </label>
            </div>
            {formIk.errors.gender && formIk.touched.gender ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.gender}</label>
            ) : null}

          </div>
          <label className='block text-left mb-[5px]' htmlFor="email">Email ID-Official</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="email"
            name="email"
            value={formIk.values.email}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            onKeyUp={handleKeyUpEmail}
            placeholder="Email"
          />
          {formIk.errors.email && formIk.touched.email ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.email}</label>
          ) : null}
          <div>
            <label className='block text-left mb-[5px]' htmlFor="mobile">Mobile Number</label>
            {/* <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
           country={'India'}
            id="mobile"
            name="mobile"
            value={formIk.values.mobile}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Mobile"
          /> */}
            <PhoneInput
              country={'in'}
              value={fullPhoneNumber}  // Use only the mobile part without the country code
              onChange={(phone, countryInfo) => {
                if (typeof phone === 'string' && countryInfo && typeof countryInfo === 'object') {
                  // Extract the numeric part of the phone number without the country code
                  const numericPhone = phone.replace(/\D/g, '');

                  // Extract the country code
                  const countryCode = 'dialCode' in countryInfo ? countryInfo.dialCode : '';

                  // Extract the country name (example: 'India')
                  const countryName = 'name' in countryInfo ? countryInfo.name : '';
                  // You can update your form fields as needed
                  formIk.handleChange({
                    target: { name: 'mobile', value: numericPhone.substring(countryCode.length) },
                  });

                  formIk.handleChange({
                    target: { name: 'countryCode', value: countryCode },
                  });

                  formIk.handleChange({
                    target: { name: 'countryName', value: countryName },
                  });
                }
              }}
              inputStyle={{ width: '100%' }}
              dropdownClass="text-left"
            />

            {formIk.errors.mobile && formIk.touched.mobile ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">
                {formIk.errors.mobile}
              </label>
            ) : null}
          </div>
          {/* <div className="text-left">
            <h1 >Is This an Employee or a Contractor?</h1>
            <input
              type="radio"
              id="natureOfEmployment"
              name="natureOfEmployment"
              value="Employee"
              checked={formIk.values.natureOfEmployment === "Employee"}
              onChange={formIk.handleChange}
              onBlur={formIk.handleBlur}
            />
            Employee
            <label
              className="block text-left mb-[5px]"
              htmlFor="natureOfEmployment"
            ></label>
            <input
              type="radio"
              id="natureOfEmployment"
              name="natureOfEmployment"
              value="Contractor"
              checked={formIk.values.natureOfEmployment === "Contractor"}
              onChange={formIk.handleChange}
              onBlur={formIk.handleBlur}
            />
            Contractor
            <label
              className="block text-left mb-[5px]"
              htmlFor="natureOfEmployment"
            ></label>
            {formIk.errors.natureOfEmployment && formIk.touched.natureOfEmployment ? (
              <label className="text-sm text-red-600 block text-left mt-1">
                {formIk.errors.natureOfEmployment}
              </label>
            ) : null}
          </div> */}
          <label className="block text-left mb-[5px]" htmlFor="matrixLevel">Matrix Level</label>
          <select
            id="matrixLevel"
            name="matrixLevel"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.matrixLevel}
            onChange={handleLevelChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.matrixLevel && formIk.touched.matrixLevel ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.matrixLevel}</label>
            ) : null}
            <option value="" disabled>Select Level</option>
            {filteredKeys.map((headItem, index) => (
              <option key={index} value={headItem}>{convertColumnName(headItem)}</option>
            ))}
          </select>

          {/* <label className="block text-left mb-[5px]" htmlFor="monthlySalary">Monthly Salary</label>
          <select
            id="monthlySalary"
            name="monthlySalary"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.monthlySalary}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            disabled={!formIk.values.matrixLevel}
          >
            {formIk.errors.monthlySalary && formIk.touched.monthlySalary ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.monthlySalary}</label>
            ) : null}
            <option value="" disabled>Select Salary</option>
            {salaryLevel.map((salary, index) => (
              <option key={index} value={salary}>{salary}</option>
            ))}
          </select> */}
          <label className="block text-left mb-[5px]" htmlFor="monthlySalary">Monthly Salary</label>
          {!formIk.values.matrixLevel ? (
            // Render input box if matrix level is not selected
            <input
              type="text"
              id="monthlySalary"
              name="monthlySalary"
              className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
              value={formIk.values.monthlySalary}
              onChange={formIk.handleChange}
              onBlur={formIk.handleBlur}
            />
          ) : (
            // Render dropdown if matrix level is selected
            <select
              id="monthlySalary"
              name="monthlySalary"
              className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
              value={formIk.values.monthlySalary}
              onChange={formIk.handleChange}
              onBlur={formIk.handleBlur}
              disabled={!formIk.values.matrixLevel}
            >
              {formIk.errors.monthlySalary && formIk.touched.monthlySalary ? (
                <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.monthlySalary}</label>
              ) : null}
              <option value="" disabled>Select Salary</option>
              {salaryLevel.map((salary, index) => (
                <option key={index} value={salary}>{salary}</option>
              ))}
            </select>
          )}

          {/* <label className='block text-left mb-[5px]' htmlFor="mobile">Monthly Salary</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            placeholder="Enter Monthly Salary"
            id="monthlySalary"
            name="monthlySalary"
            value={formIk.values.monthlySalary === 0 ? "" : formIk.values.monthlySalary}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            required
            pattern="[0-9,]*"
            inputMode="numeric"
          /> */}
          {formIk.errors.monthlySalary && formIk.touched.monthlySalary ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.monthlySalary}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="mobile">Total Working Experience</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            placeholder="Enter Working Experience"
            id="experienceYear"
            name="experienceYear"
            value={formIk.values.experienceYear === 0 ? "" : formIk.values.experienceYear}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            required
            pattern="[0-9,]*"
            inputMode="numeric"
          />
          {formIk.errors.experienceYear && formIk.touched.experienceYear ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.experienceYear}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="date">Date Of Joining</label>
          <input
            className="h-10 border w-full text-[32] p-2 rounded-[3px] border-solid border-[#ccc]"
            type="date"
            max="9999-12-31"
            id="joiningDate"
            name="joiningDate"
            value={formIk.values.joiningDate}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Date"
          />
          {formIk.errors.joiningDate && formIk.touched.joiningDate ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.joiningDate}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="job_title">Designation</label>
          <select
            id="designation"
            name="designation"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.designation}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.designation && formIk.touched.designation ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.designation}</label>
            ) : null}
            <option value='' disabled>Select Designation</option>
            {jobRole.map((jobRole: any) => (
              <option value={jobRole.designation}>{jobRole.designation}</option>
            ))}
          </select>
          <label className='block text-left mb-[5px]' htmlFor="location">Department</label>
          <select
            id="department"
            name="department"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.department}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.department && formIk.touched.department ? (
              <label className="text-sm text-red-600">{formIk.errors.department}</label>
            ) : null}
            <option value='' disabled>Select Department</option>
            {departments.map((departments: any) => (
              <option value={departments.id}>{departments.departmentName}</option>
            ))}
          </select>
          <div>
            <label className='block text-left mb-[5px]' htmlFor="mobile">Personal Contact Number</label>
            {/* <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
           country={'India'}
            id="mobile"
            name="mobile"
            value={formIk.values.mobile}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Mobile"
          /> */}
            <PhoneInput
              country={'in'}
              value={fullPhoneNumber}  // Use only the mobile part without the country code
              onChange={(phone, countryInfo) => {
                if (typeof phone === 'string' && countryInfo && typeof countryInfo === 'object') {
                  // Extract the numeric part of the phone number without the country code
                  const numericPhone = phone.replace(/\D/g, '');

                  // Extract the country code
                  const countryCode = 'dialCode' in countryInfo ? countryInfo.dialCode : '';

                  // Extract the country name (example: 'India')
                  const countryName = 'name' in countryInfo ? countryInfo.name : '';
                  // You can update your form fields as needed
                  formIk.handleChange({
                    target: { name: 'personalContactNumber', value: numericPhone.substring(countryCode.length) },
                  });

                  formIk.handleChange({
                    target: { name: 'countryCode', value: countryCode },
                  });

                  formIk.handleChange({
                    target: { name: 'countryName', value: countryName },
                  });
                }
              }}
              inputStyle={{ width: '100%' }}
              dropdownClass="text-left"
            />

            {formIk.errors.personalContactNumber && formIk.touched.personalContactNumber ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">
                {formIk.errors.personalContactNumber}
              </label>
            ) : null}
          </div>
          {/* <label className='block text-left mb-[5px]' htmlFor="email">Personal Email ID</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="personalContactEmailId"
            name="personalContactEmailId"
            value={formIk.values.personalContactEmailId}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            onKeyUp={handleKeyUpPersonalContactEmailId}
            placeholder="Email"
          /> */}
          {/* {formIk.errors.personalContactEmailId && formIk.touched.personalContactEmailId ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.personalContactEmailId}</label>
          ) : null} */}
          <label className='block text-left mb-[5px]' htmlFor="dateOfBirth">
            Date of birth
          </label>
          <input
            className="h-10 border w-full text-[32] p-2 rounded-[3px] border-solid border-[#ccc]"
            type="date"
            max={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0]}
            onInvalid={(e) => { e.preventDefault(); }}
            id="dateOfBirth"
            name="dateOfBirth"
            value={formIk.values.dateOfBirth}
            onChange={handleDateChange}
            onBlur={formIk.handleBlur}
            placeholder="Date"
          />
          {formIk.errors.dateOfBirth && formIk.touched.dateOfBirth ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.dateOfBirth}</label>
          ) : null}

          <label className='block text-left mb-[5px]' htmlFor="age">
            Enter Your Age
          </label>
          <input
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            placeholder="Enter Age"
            id="age"
            name="age"
            value={formIk.values.age}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            required
            pattern="[0-9]{1,2}"
            inputMode="numeric"
            maxLength={3}
          />
          {/* {formIk.errors.age && formIk.touched.age ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.age}</label>
          ) : null} */}
          {/* <label className='block text-left mb-[5px]' htmlFor="email">Blood Group</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="bloodGroup"
            name="bloodGroup"
            value={formIk.values.bloodGroup}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Blood Group"
          />
          {formIk.errors.bloodGroup && formIk.touched.bloodGroup ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.bloodGroup}</label>
          ) : null} */}
          <label className='block text-left mb-[5px]' htmlFor="permanentAddress">Permanent Address</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="permanentAddress"
            name="permanentAddress"
            value={formIk.values.permanentAddress}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Permanent Address"
          />
          {formIk.errors.permanentAddress && formIk.touched.permanentAddress ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.permanentAddress}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="permanentAddress">Current Address</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="currentAddress"
            name="currentAddress"
            value={formIk.values.currentAddress}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Current Address"
          />
          {formIk.errors.currentAddress && formIk.touched.currentAddress ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.currentAddress}</label>
          ) : null}
          {/* <label className='block text-left mb-[5px]' htmlFor="permanentAddress">Emergency Contact Person Name</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="emergencyContactPerson"
            name="emergencyContactPerson"
            value={formIk.values.emergencyContactPerson}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Emergency Contact Person Name"
          />
          {formIk.errors.emergencyContactPerson && formIk.touched.emergencyContactPerson ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.emergencyContactPerson}</label>
          ) : null}
          <div>
            <label className='block text-left mb-[5px]' htmlFor="mobile">Emergency Contact Number</label> */}
          {/* <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
           country={'India'}
            id="mobile"
            name="mobile"
            value={formIk.values.mobile}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Mobile"
          /> */}
          {/* <PhoneInput
              country={'in'}
              value={fullPhoneNumber}  // Use only the mobile part without the country code
              onChange={(phone, countryInfo) => {
                if (typeof phone === 'string' && countryInfo && typeof countryInfo === 'object') {
                  // Extract the numeric part of the phone number without the country code
                  const numericPhone = phone.replace(/\D/g, '');

                  // Extract the country code
                  const countryCode = 'dialCode' in countryInfo ? countryInfo.dialCode : '';

                  // Extract the country name (example: 'India')
                  const countryName = 'name' in countryInfo ? countryInfo.name : '';
                  // You can update your form fields as needed
                  formIk.handleChange({
                    target: { name: 'emergencyContactNumber', value: numericPhone.substring(countryCode.length) },
                  });

                  formIk.handleChange({
                    target: { name: 'countryCode', value: countryCode },
                  });

                  formIk.handleChange({
                    target: { name: 'countryName', value: countryName },
                  });
                }
              }}
              inputStyle={{ width: '100%' }}
              dropdownClass="text-left"
            />

            {formIk.errors.emergencyContactNumber && formIk.touched.emergencyContactNumber ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">
                {formIk.errors.emergencyContactNumber}
              </label>
            ) : null}
          </div>
          <label className='block text-left mb-[5px]' htmlFor="name">Pan Card Details</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="panCardDetails"
            name="panCardDetails"
            value={formIk.values.panCardDetails}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Pan Card Details"
          />
          {formIk.errors.panCardDetails && formIk.touched.panCardDetails ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.panCardDetails}</label>
          ) : null}
          <label className='block text-left mb-[5px]' htmlFor="name">Aadhar Card Details</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="aadharCardDetails"
            name="aadharCardDetails"
            value={formIk.values.aadharCardDetails}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Aadhar Card Details"
          />
          {formIk.errors.aadharCardDetails && formIk.touched.aadharCardDetails ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.aadharCardDetails}</label>
          ) : null} */}
          {/* <label className='block text-left mb-[5px]' htmlFor="location">Assign Role</label>
          <select
            id="role"
            name="role"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.role}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.role && formIk.touched.role ? (
              <label className="text-sm text-red-600">{formIk.errors.role}</label>
            ) : null}
            <option value='' disabled>Select Role</option>
            {roles.map((role: any) => (
              <option value={role.designation}>{role.designation}</option>
            ))}
          </select>
 */}


          {/* <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="Designation"
            name="Designation"
            value={formIk.values.Designation}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="Designation"
            style={{
              WebkitAppearance: 'none',
              margin: 0,
            }}
          /> */}


          {/* <label className='block text-left mb-[5px]' htmlFor="monthlySalary">Annual Salary</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="number"
            id="monthlySalary"
            name="monthlySalary"
            value={formIk.values.monthlySalary}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="monthlySalary"
          />
          {formIk.errors.monthlySalary && formIk.touched.monthlySalary ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.monthlySalary}</label>
          ) : null} */}
          <label className='block text-left mb-[5px]' htmlFor="location">Location(for Professional tax only)</label>
          <select
            id="location"
            name="location"
            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            value={formIk.values.location}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
          >
            {formIk.errors.location && formIk.touched.location ? (
              <label className="text-sm text-red-600">{formIk.errors.location}</label>
            ) : null}
            <option value='' disabled>Select State</option>
            <option value="Maharashtra">Maharashtra</option>
            {/* <option value="Uttar Pradesh">Uttar Pradesh</option>
            <option value="Madhya Pradesh">Madhya Pradesh</option>
            <option value="Bihar">Bihar</option>
            <option value="Panjab">Panjab</option>
            <option value="Haryana">Haryana</option>
            <option value="Delhi">Delhi</option>
            <option value="Gujrat">Gujrat</option>
            <option value="Tamil Nadu">Tamil Nadu</option> */}
          </select>
          {/* <label className='block text-left mb-[5px]' htmlFor="permanentAddress">Nationality ?</label>
          <input className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
            type="text"
            id="residentIndia"
            name="residentIndia"
            value={formIk.values.residentIndia}
            onChange={formIk.handleChange}
            onBlur={formIk.handleBlur}
            placeholder="India"
          />
          {formIk.errors.residentIndia && formIk.touched.residentIndia ? (
            <label className="text-sm text-red-600 block text-left mb-[5px]">{formIk.errors.residentIndia}</label>
          ) : null}
          <div></div> */}
          {/* <div className="text-left" >
            <input
              type="checkbox"
              id="residentIndia"
              name="residentIndia"
              value={formIk.values.residentIndia}
              onChange={formIk.handleChange}
              onBlur={formIk.handleBlur}
            />
            Nationality ?
            <label className="block text-left mb-[5px]"></label>
            {formIk.errors.residentIndia && formIk.touched.residentIndia ? (
              <label className="text-sm text-red-600 block text-left mb-[5px]">
                {formIk.errors.residentIndia}
              </label>
            ) : null}
          </div> */}
          <button type="submit" className="bg-[#007bff] text-white cursor-pointer text-[10px] px-5 py-2.5 rounded-[5px] border-[none] hover:bg-[#0056b3]">
            SUBMIT
          </button>
          <button
            type="button"
            className="bg-[hsl(0,100%,50%)] text-white cursor-pointer text-[10px] px-5 py-2.5 rounded-[5px] border-[none]"
            onClick={() => {
              props.close();
            }}
          >
            CLOSE
          </button>
        </div>
      </form>
    </div>
  );
}

export default PeopleUser;
