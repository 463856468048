import { SetStateAction, useEffect, useState } from "react";
import { ProfileApi } from "../../../services/Profile";
import { toast } from "react-toastify";
import { UsersApi } from "../../../services/users";
import * as Yup from 'yup';


interface resignationData {
    employeeCode: string;
    managerFName: string;
    separationdate: string;
    firstName: string;
    lastName: string;
    reasonforleaving: string;
    workingagain: string;
    liketheorganization: string;
    organizationstaff: string;
    sharewithus: string;
    vehiclehanded: string;
    equipmentshanded: string;
    librarybooks: string;
    security: string;
    exitinterviewconducted: string;
    noticeperiod: string;
    resignationlettersubmitted: string;
    managerclearance: string;
    reasonforreject: string;
}

interface errDocFormate {
    separationdate: string;
    reasonforleaving: string;
    workingagain: string;
    liketheorganization: string;
    organizationstaff: string;
    sharewithus: string;
    vehiclehanded: string;
    equipmentshanded: string;
    librarybooks: string;
    security: string;
    exitinterviewconducted: string;
    noticeperiod: string;
    resignationlettersubmitted: string;
    managerclearance: string;
    reasonforreject: string
}

interface props {
    userRole: unknown;
    id: number;
    userId: number;
    // formId: number;
    setUserResignationTable: (arg0: boolean) => void;
    setOpenResignation: (arg0: boolean) => void;
    close: () => void;
}

const initial: errDocFormate = { separationdate: "", reasonforleaving: '', workingagain: '', liketheorganization: '', organizationstaff: '', sharewithus: '', vehiclehanded: '', equipmentshanded: '', librarybooks: '', security: '', exitinterviewconducted: "", noticeperiod: "", resignationlettersubmitted: "", managerclearance: "", reasonforreject: "" }



export default function ResignationForm(props: props) {
    const { postResignation, getResignationById, updateResignation, getResignation } = ProfileApi();
    const { getUserId } = UsersApi();
    const [submitting, setSubmitting] = useState(false);
    const [isModal, setModal] = useState(false);
    const [isAproveModal, setApoveModal] = useState(false);
    const [reasonforreject, setInputValue] = useState('');
    const [formData, setFormData] = useState<resignationData>({ employeeCode: "", managerFName: "", separationdate: "", firstName: "", lastName: "", reasonforleaving: "", workingagain: "", liketheorganization: "", organizationstaff: "", sharewithus: "", vehiclehanded: "", equipmentshanded: "", librarybooks: "", security: "", exitinterviewconducted: "", noticeperiod: "", resignationlettersubmitted: "", managerclearance: "", reasonforreject: "" });
    const [err, setErr] = useState<errDocFormate>(initial);
    const [validation, setValidation] = useState(false);

    const validationSchema = Yup.object().shape({
        vehiclehanded: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Vehicle handed field is required') : Yup.string().notRequired(),
        equipmentshanded: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Equipments handed field is required') : Yup.string().notRequired(),
        librarybooks: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Library field is required') : Yup.string().notRequired(),
        security: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Security field is required') : Yup.string().notRequired(),
        exitinterviewconducted: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Exit interview conducted field is required') : Yup.string().notRequired(),
        resignationlettersubmitted: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Resignation Submitted Field is required') : Yup.string().notRequired(),
        noticeperiod: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Notice Period Field is required') : Yup.string().notRequired(),
        managerclearance: props.userRole === 'SuperAdmin' ? Yup.string().matches(/^[A-Za-z\s]+$/, 'Only letters are allowed').required('Manager clearance field is required') : Yup.string().notRequired(),
        separationdate: props.userRole !== 'SuperAdmin' ?
            Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format').required('Separation date is required') :
            Yup.string().notRequired(), // Not required if userRole is SuperAdmin
        reasonforleaving: props.userRole !== 'SuperAdmin' ?
            Yup.string().matches(/^[A-Za-z\s]+$/, 'Select the required field').required('Select the required field !!') :
            Yup.string().notRequired(), // Not required if userRole is SuperAdmin
    });


    useEffect(() => {
        if (props.userRole !== 'SuperAdmin') {
            users();
            if (!Number.isNaN(props.id)) {
                resignationById();
            }
        }

        if (props.userRole === 'SuperAdmin') {
            pendingResignaton();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userRole])

    const users = async () => {
        const userData = await getUserId(props.userId);
        if (userData) {
            const { employeeCode, managerFName, firstName, lastName } = userData;
            setFormData(prevState => ({
                ...prevState,
                employeeCode,
                firstName,
                lastName,
                managerFName
            }));
        } else {
            // Handle case where getUserId returns undefined
            console.error("User data is undefined");
        }
    }

    const pendingResignaton = async () => {
        const resignationData = await getResignation(props.id);
        if (resignationData !== null) {
            const { employeeCode, managerFName, firstName, lastName, separationdate, reasonforleaving, workingagain, liketheorganization, sharewithus, organizationstaff } = resignationData;
            setFormData(prevState => ({
                ...prevState,
                employeeCode,
                firstName,
                lastName,
                managerFName,
                separationdate,
                reasonforleaving,
                workingagain,
                liketheorganization,
                sharewithus,
                organizationstaff
            }));
        }
    }

    const resignationById = async () => {
        const resignationData = await getResignationById(props.id);
        if (resignationData) {
            const { separationdate, reasonforleaving, workingagain, liketheorganization, sharewithus, organizationstaff } = resignationData;
            setFormData(prevState => ({
                ...prevState,
                separationdate,
                reasonforleaving,
                workingagain,
                liketheorganization,
                sharewithus,
                organizationstaff
            }));
        } else {
            console.error('Resignation data is undefined');
        }
    }

    const handleSubmit = async (event: React.FormEvent) => {
        if (!submitting) {
            setSubmitting(true); // Set submitting state to true to prevent multiple submissions
            event.preventDefault(); // Prevent default form submission
            try {
                await validationSchema.validate(formData, { abortEarly: false });
                const response = await postResignation(formData);
                if (response.status === 200) {
                    toast.success('Submited successfully !');
                    props.setUserResignationTable(true);
                    props.setOpenResignation(false);
                } else {
                    toast.error('Unexpected error occurred. Please try again later.');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    // Handle validation errors
                    const errors = error.inner.reduce((acc, { path, message }) => ({
                        ...acc,
                        [path as keyof errDocFormate]: message,
                    }), {} as Partial<errDocFormate>);
                    // Update errors in the error state
                    setErr(prevErrors => ({ ...prevErrors, ...errors }));
                    // Set validation state to true
                    setValidation(true);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error('Error occurred:', error);
                    toast.error('Unexpected error occurred. Please try again later.');
                }
            } finally {
                setSubmitting(false); // Reset submitting state to false after form submission
            }
        }
    };


    const handleApprove = async () => {
        try{
            await validationSchema.validate(formData, { abortEarly: false });
            setApoveModal(true)
        }catch (error) {
            if (error instanceof Yup.ValidationError) {
                // Handle validation errors
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);
                // Update errors in the error state
                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                // Set validation state to true
                setValidation(true);
            } else {
                // Handle other types of errors
                console.error('Error occurred:', error);
                // Display an error message or handle the error as needed
            }
        }
        
    };

    const aprovalYes = async () => {
        await updateResignationData("Approved", props.id);
        setApoveModal(false);
        props.close();
    }

    const aprovalNo = () => {
        setApoveModal(false);
        // close();
    }

    const handleReject = async () => {
        try{
            await validationSchema.validate(formData, { abortEarly: false });
            setModal(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                // Handle validation errors
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);
                // Update errors in the error state
                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                // Set validation state to true
                setValidation(true);
            } else {
                // Handle other types of errors
                console.error('Error occurred:', error);
                // Display an error message or handle the error as needed
            }
        }
        
    };

    const closeModel = () => {
        setModal(false)
    }


    const Confirmation = () => {
        updateResignationData("Rejected", props.id, reasonforreject);
        closeModel();

    };

    const CloseForm = () => {
        props.setUserResignationTable(true)
        props.setOpenResignation(false)
    }

    const handleInputChange = async (name: string, value: string) => {
        try {
            // Set error state to empty initially
            setErr((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));

            // Update formData state
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));

            // Validate the updated formData
            await validationSchema.validate({ ...formData, [name]: value }, { abortEarly: false });

            // If validation succeeds, update validation state to true
            setValidation(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errDocFormate]: message,
                }), {} as Partial<errDocFormate>);
                if (validation === true) {
                    setErr(prevErrors => ({ ...prevErrors, ...errors }));
                }
            }
        }
    };

    const updateResignationData = async (status: any, id: number, reasonforreject?: string) => { // Make inputValue optional
        try {
            setSubmitting(true); // Set submitting to true when the request starts
            const updated = { ...formData, status: status, id }
            if (reasonforreject) {
                updated.reasonforreject = reasonforreject; // Add inputValue only if it's provided
            }
            const data = await updateResignation(updated);
            if (data.status === 200) {
                toast.success('Resignation Updated successfully!');
                props.close();
            } else {
                toast.error('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle errors if any
        } finally {
            setSubmitting(false); // Reset submitting when the request completes (success or failure)
        }
    }


    const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setInputValue(event.target.value);
    };



    return (
        <>
            <div className="w-11/12 p-5 mt-4 mb-4 min-h-svh bg-white rounded-lg shadow-lg">
                <form onSubmit={handleSubmit}>
                    <div>
                        {props.userRole === 'SuperAdmin' && (
                            <div className="text-right mr-3">
                                <button className=" text-4xl text-red-600" onClick={props.close}>x</button>
                            </div>

                        )}
                        {props.userRole !== 'SuperAdmin' && (
                            <div className="text-right mr-3">
                                <button className=" text-4xl text-red-600" onClick={CloseForm}>x</button>
                            </div>
                        )}
                        <div className="flex justify-between">
                            <div className="text-left text-2xl">
                                Separation
                            </div>
                            <div className="text-blue-500 font-bold text-4xl mr-20">
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="employeeCode" className="block text-base font-medium leading-6 text-gray-900 text-left">Employee ID <span style={{ color: 'red' }}>*</span></label>
                                <div className="mt-2">
                                    <input type="text" name="employeeCode" id="employeeCode" value={formData.employeeCode} disabled onChange={(e) => { handleInputChange('employeeCode', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="separationdate" className="block text-base font-medium leading-6 text-gray-900 text-left">
                                    Separation date <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="mt-2">
                                    <input type="date" value={formData.separationdate} onChange={(e) => { handleInputChange('separationdate', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                    {err.separationdate && <b className='text-red-500 text-xs mt-1 text-left block'>{err.separationdate}</b>}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="firstName" className="block text-base font-medium leading-6 text-gray-900 text-left">Employee Name</label>
                                <div className="mt-2">
                                    <input type="text" name="firstName" id="firstName" value={formData.firstName} disabled onChange={(e) => { handleInputChange('firstName', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="lastName" className="block text-base font-medium leading-6 text-gray-900 text-left">Employee Last Name</label>
                                <div className="mt-2">
                                    <input type="text" name="lastName" id="lastName" value={formData.lastName} disabled onChange={(e) => { handleInputChange('lastName', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>


                        </div>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="managerFName" className="block text-base font-medium leading-6 text-gray-900 text-left">Manager Name<span style={{ color: 'red' }}>*</span></label>
                                <div className="mt-2">
                                    <input type="text" name="managerFName" id="managerFName" value={formData.managerFName} disabled onChange={(e) => { handleInputChange('managerFName', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="reasonforleaving" className="block text-base font-medium leading-6 text-gray-900 text-left">Reason for leaving <span style={{ color: 'red' }}>*</span></label>
                                <div className="mt-2">
                                    <select name="reasonforleaving" id="reasonforleaving" value={formData.reasonforleaving} onChange={(e) => { handleInputChange('reasonforleaving', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" >
                                        <option value='' disabled>Select</option>
                                        <option value="higherpay">Higher Pay</option>
                                        <option value="retirement">Retirement</option>
                                        <option value="careerprospect">Career Prospect</option>
                                        <option value="betteremployementcondition">Better Employement Condition</option>
                                        <option value="dissatisfactionjob">Dissatisfaction With The Job</option>
                                        <option value="emigrating">Emigrating</option>
                                    </select>
                                    {err.reasonforleaving && <b className='text-red-500 text-xs mt-1 text-left block'>{err.reasonforleaving}</b>}
                                </div>
                            </div>
                        </div>
                        <br />
                        <h1 className="text-left text-2xl">Questionairre</h1>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="workingagain" className="block text-base font-medium leading-6 text-gray-900 text-left">Would you work for this organization again</label>
                                <div className="mt-2">
                                    <select name="workingagain" id="workingagain" value={formData.workingagain} onChange={(e) => { handleInputChange('workingagain', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" >
                                        <option value='' disabled>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>

                                    </select>
                                    {err.workingagain && <b className='text-red-500 text-xs mt-1 text-left block'>{err.workingagain}</b>}
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="liketheorganization" className="block text-base font-medium leading-6 text-gray-900 text-left">What did you like the most of the organization</label>
                                <div className="mt-2">
                                    <input type="text" name="liketheorganization" id="liketheorganization" value={formData.liketheorganization} onChange={(e) => { handleInputChange('liketheorganization', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                    {err.liketheorganization && <b className='text-red-500 text-xs mt-1 text-left block'>{err.liketheorganization}</b>}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="organizationstaff" className="block text-base font-medium leading-6 text-gray-900 text-left">Think the organization do to improve staff welfare</label>
                                <div className="mt-2">
                                    <input type="text" name="organizationstaff" id="organizationstaff" value={formData.organizationstaff} onChange={(e) => { handleInputChange('organizationstaff', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                    {err.organizationstaff && <b className='text-red-500 text-xs mt-1 text-left block'>{err.organizationstaff}</b>}
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="sharewithus" className="block text-base font-medium leading-6 text-gray-900 text-left">Anything you wish to share with us</label>
                                <div className="mt-2">
                                    <input type="text" name="sharewithus" id="sharewithus" value={formData.sharewithus} onChange={(e) => { handleInputChange('sharewithus', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                    {err.sharewithus && <b className='text-red-500 text-xs mt-1 text-left block'>{err.sharewithus}</b>}
                                </div>
                            </div>
                        </div>
                        <br />

                        {props.userRole === 'SuperAdmin' && (
                            <div>
                                <h1 className="text-left text-2xl">Checklist for Exit Interview</h1>
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="vehiclehanded" className="block text-base font-medium leading-6 text-gray-900 text-left">Company Vehicle handed in</label>
                                        <div className="mt-2">
                                            <input type="text" name="vehiclehanded" id="vehiclehanded" value={formData.vehiclehanded} onChange={(e) => { handleInputChange('vehiclehanded', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.vehiclehanded && <b className='text-red-500 text-xs mt-1 text-left block'>{err.vehiclehanded}</b>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="equipmentshanded" className="block text-base font-medium leading-6 text-gray-900 text-left">All equipments handed in</label>
                                        <div className="mt-2">
                                            <input type="text" name="equipmentshanded" id="equipmentshanded" value={formData.equipmentshanded} onChange={(e) => { handleInputChange('equipmentshanded', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.equipmentshanded && <b className='text-red-500 text-xs mt-1 text-left block'>{err.equipmentshanded}</b>}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="librarybooks" className="block text-base font-medium leading-6 text-gray-900 text-left">All library books submitted</label>
                                        <div className="mt-2">
                                            <input type="text" name="librarybooks" id="librarybooks" value={formData.librarybooks} onChange={(e) => { handleInputChange('librarybooks', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.librarybooks && <b className='text-red-500 text-xs mt-1 text-left block'>{err.librarybooks}</b>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="security" className="block text-base font-medium leading-6 text-gray-900 text-left">Security</label>
                                        <div className="mt-2">
                                            <input type="text" name="security" id="security" value={formData.security} onChange={(e) => { handleInputChange('security', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.security && <b className='text-red-500 text-xs mt-1 text-left block'>{err.security}</b>}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="exitinterviewconducted" className="block text-base font-medium leading-6 text-gray-900 text-left">Exit interview conducted</label>
                                        <div className="mt-2">
                                            <input type="text" name="exitinterviewconducted" id="exitinterviewconducted" value={formData.exitinterviewconducted} onChange={(e) => { handleInputChange('exitinterviewconducted', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.exitinterviewconducted && <b className='text-red-500 text-xs mt-1 text-left block'>{err.exitinterviewconducted}</b>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="noticeperiod" className="block text-base font-medium leading-6 text-gray-900 text-left">Notice period followed</label>
                                        <div className="mt-2">
                                            <input type="text" name="noticeperiod" id="noticeperiod" value={formData.noticeperiod} onChange={(e) => { handleInputChange('noticeperiod', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.noticeperiod && <b className='text-red-500 text-xs mt-1 text-left block'>{err.noticeperiod}</b>}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="resignationlettersubmitted" className="block text-base font-medium leading-6 text-gray-900 text-left">Resignation letter submitted</label>
                                        <div className="mt-2">
                                            <input type="text" name="resignationlettersubmitted" id="resignationlettersubmitted" value={formData.resignationlettersubmitted} onChange={(e) => { handleInputChange('resignationlettersubmitted', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.resignationlettersubmitted && <b className='text-red-500 text-xs mt-1 text-left block'>{err.resignationlettersubmitted}</b>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="managerclearance" className="block text-base font-medium leading-6 text-gray-900 text-left">Manager/Supervisor clearance</label>
                                        <div className="mt-2">
                                            <input type="text" name="managerclearance" id="managerclearance" value={formData.managerclearance} onChange={(e) => { handleInputChange('managerclearance', e.target.value) }} className="block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6" />
                                            {err.managerclearance && <b className='text-red-500 text-xs mt-1 text-left block'>{err.managerclearance}</b>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <br />

                    <div>
                        {props.userRole !== 'SuperAdmin' && isNaN(props.id) && (
                            <button
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-4 mt-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                type="submit"
                            >
                                Submit
                            </button>
                        )}
                        {props.userRole === 'SuperAdmin' && (
                            <>
                                <button
                                    type="button"
                                    onClick={handleApprove}
                                    className="text-white bg-blue-500 hover:bg-sky-700 font-medium rounded-md text-sm px-3 py-2 text-center mt-2 mb-2 me-2"
                                >
                                    Approve
                                </button>
                                <button
                                    type="button"
                                    onClick={handleReject}
                                    className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-md text-sm px-3 py-2 text-center mt-2 mb-2 me-2"
                                >
                                    Reject
                                </button>

                                {isModal && (
                                    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                                        <div className="fixed inset-0 bg-white bg-opacity-75 transition-opacity"></div>
                                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                            <div className="flex items-center justify-center min-h-screen">
                                                <div className="w-full sm:max-w-lg">
                                                    <div className="bg-white shadow-xl rounded-lg">
                                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                            <h3 className="text-xl font-semibold text-gray-800" id="modal-title">Reason for Reject Application</h3>
                                                            <hr className="my-4 border-gray-400" />
                                                            <div className="mt-2">
                                                                <textarea
                                                                    id="message"
                                                                    className="block w-full p-2.5 md:w-full h-25 md:h-28  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                                    value={reasonforreject}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center justify-end px-6 py-4 bg-gray-100">
                                                            <button type="button" onClick={Confirmation} className="px-4 py-2 text-sm font-semibold text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700">Submit</button>
                                                            <button type="button" onClick={closeModel} className="ml-2 px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {isAproveModal && (
                                    <div id="popup-modal" className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full">
                                        <div className="absolute bg-gray-800 bg-opacity-50 inset-0"></div>
                                        <div className="relative p-4 w-full max-w-md">
                                            <div className="relative bg-white rounded-lg shadow">
                                                <div className="p-4 text-center">
                                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                    <h3 className="mb-5 text-lg font-normal text-gray-500">Are you sure you want to approve this application?</h3>
                                                    <button type="button" className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 mr-3" onClick={aprovalYes}>
                                                        Yes
                                                    </button>
                                                    <button type="button" className="py-2.5 px-5 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-800 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100" onClick={aprovalNo}>No</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                    </div>

                </form>
            </div>

        </>
    )

}