/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { toast } from 'react-toastify';

interface AttendancePageProps {
  checkData: any,
  calendarDates: any,
  userAttendanceData: any,
  setUserAttendanceData: (data: any) => void,
  setCheckInData: (data: any) => void,
  checkInData: any,
  userData: any,
  userRole: string,
  userid: string
}

interface AttendanceEntryWithoutId {
  checkIn: string;
  checkOut: string;
  duration: string;
  date: any;
  userId: any;
  userRole: string;
  userid: string;
}

const AttendancePage = ({ checkData, setCheckInData, checkInData, calendarDates, userAttendanceData, setUserAttendanceData, userData, userRole, userid }: AttendancePageProps) => {
  const storedDate = localStorage.getItem('lastCheckedDate');
  const initialDate = storedDate ? new Date(storedDate) : null;
  const currentDate = new Date();
  const [lastCheckedDate, setLastCheckedDate] = useState<Date | null>(initialDate);
  const { checkIn, checkOut, currentUserData, weekendDataSave } = LeaveAttendanceApi();
  // const [successMessage, setsuccessMessage] = useState('');
  // const [failureMessage, setfailureMessage] = useState('');

  useEffect(() => {
    checkWeekendAndHoliday();
    checkAndUpdateLastCheckedDate();
    if (checkData.length > 0) {
      const { checkIn, checkOut } = checkData[0] as AttendanceEntryWithoutId;
      setUserAttendanceData({
        checkInTime: checkIn || null,
        checkOutTime: checkOut || null,
      });
    }
  }, [checkData, checkInData]);


  const checkAndUpdateLastCheckedDate = () => {
    const lastCheckedDateObj = lastCheckedDate ? new Date(lastCheckedDate) : null;
    if (
      lastCheckedDateObj &&
      lastCheckedDateObj.getFullYear() === currentDate.getFullYear() &&
      lastCheckedDateObj.getMonth() === currentDate.getMonth() &&
      lastCheckedDateObj.getDate() === currentDate.getDate()
    ) {
    } else {
      localStorage.setItem('lastCheckedDate', currentDate.toDateString());
      setLastCheckedDate(currentDate);
    }
  };

  const handleCheckIn = async () => {
    const currentMoment = moment();
    const checkInTime = currentMoment.format('hh:mm A');
    const checkInDate = currentMoment.format('YYYY-MM-DD');
    setUserAttendanceData((prevState: any) => ({
      ...prevState,
      checkInTime: checkInTime,
    }));

    const checkInData: AttendanceEntryWithoutId = {
      checkIn: checkInTime,
      date: checkInDate,
      userId: userData.id,
      checkOut: '',
      duration: '',
      userRole: userRole,
      userid: userid
    };

    const checkInResponse = await checkIn(checkInData);
    if (checkInResponse?.data.status === 200) {
      toast.success(checkInResponse?.data.message);
      // setsuccessMessage(checkInResponse?.data.message);
      // setTimeout(() => {
      //   setsuccessMessage('');
      // }, 2000);
    } else {
      toast.error(checkInResponse?.data.message);
      // setfailureMessage(checkInResponse?.data.message);
      // setTimeout(() => {
      //   setfailureMessage('');
      // }, 2000);
    }

    const responseData = await currentUserData(userData.id, checkInDate);
    const updatedAttendanceData = responseData.data.data[0];

    setCheckInData((prevData: any) => {
      // Find and update the specific date's data in the attendance data
      const updatedData = prevData.map((item: any) => {
        if (item.date === checkInDate) {
          item.checkIn = checkInTime;
          item.duration = updatedAttendanceData ? updatedAttendanceData.duration : '-NA-';
          item.status = updatedAttendanceData ? updatedAttendanceData.status : '-NA-';
          item.remarks = updatedAttendanceData ? updatedAttendanceData.remarks : '-NA-';
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleCheckOut = async () => {
    const currentMoment = moment();
    const checkOutTime = currentMoment.format('hh:mm A');
    const checkOutDate = currentMoment.format('YYYY-MM-DD');
    // Fetch existing attendance data for the user on the current date
    setUserAttendanceData((prevState: any) => ({
      ...prevState,
      checkOutTime: checkOutTime,
    }));

    const checkOutData: AttendanceEntryWithoutId = {
      checkOut: checkOutTime,
      date: checkOutDate,
      userId: userData.id,
      duration: '',
      checkIn: '',
      userRole: userRole,
      userid: userid
    };

    const checkOutResponse = await checkOut(checkOutData);

    if (checkOutResponse?.data.status === 200) {
      toast.success(checkOutResponse?.data.message);
      // setsuccessMessage(checkOutResponse?.data.message);
      // setTimeout(() => {
      //   setsuccessMessage('');
      // }, 2000);
    } else {
      toast.error(checkOutResponse?.data.message);
      // setfailureMessage(checkOutResponse?.data.message);
      // setTimeout(() => {
      //   setfailureMessage('');
      // }, 2000);
    }

    const updateResponse = await currentUserData(userData.id, checkOutDate);
    const updatedAttendanceData = updateResponse.data.data[0];

    setCheckInData((prevData: any) => {
      // Finding and updating the specific date's data in the attendance data
      const updatedData = prevData.map((item: any) => {
        if (item.date === checkOutDate) {
          item.checkOut = checkOutTime;
          item.duration = updatedAttendanceData ? updatedAttendanceData.duration : '-NA-';
          item.status = updatedAttendanceData ? updatedAttendanceData.status : '-NA-';
          item.remarks = updatedAttendanceData ? updatedAttendanceData.remarks : '-NA-';
        }
        return item;
      });
      return updatedData;
    });
  };

  const isWeekendDay = () => {
    const dayOfWeek = currentDate.getDay();
    return dayOfWeek === 0;
  };

  const isHoliday = () => {
    const date = moment(currentDate);
    const formattedDate = date.format('ddd MMM DD YYYY');
    for (let i = 0; i < calendarDates.length; i++) {
      const calDate = moment(calendarDates[i].date)
      const formattedCalDate = calDate.format('ddd MMM DD YYYY');
      if (formattedCalDate === formattedDate && calendarDates[i].holidaydetails !== '' && calendarDates[i].holidaydate === formattedDate) {
        return { status: true, holidayName: calendarDates[i].holidaydetails };
      }
    }
    return { status: false, holidayName: '' };
  }

  const checkWeekendAndHoliday = () => {
    const isTodayHoliday = isHoliday();
    const isWeekend = isWeekendDay();
    if (isWeekend === true && isTodayHoliday.status === true) {
      const weekendData = {
        status: isTodayHoliday.holidayName,
        checkIn: null,
        checkOut: null,
        duration: null,
        remarks: null,
        userId: userData.id,
        date: moment(currentDate).format('YYYY-MM-DD'),
      };
      weekendDataSave(weekendData);
    } else if (isWeekend === true && isTodayHoliday.status !== true) {
      const weekendData = {
        status: 'Weekend',
        checkIn: null,
        checkOut: null,
        duration: null,
        remarks: null,
        userId: userData.id,
        date: moment(currentDate).format('YYYY-MM-DD'),
      };
      weekendDataSave(weekendData);
    }
  }

  return (
    <>
      {/* Attendance CheckIn Checkout */}
      <div className=" w-full p-4 bg-white mt-5 rounded-xl shadow-lg">
        {/* Mark Attendance part */}
        <div className="flex flex-col md:flex-row items-center justify-between px-4 py-8 bg-green-800 dark:bg-gray-900 shadow-xl rounded-lg hover:shadow-xl transition duration-300 ease-in-out">
          {/* Left Part */}
          <div className="w-full md:w-2/3 pr-4">
            <h3 className="text-lg text-left ml-4 font-semibold text-green-100 text dark:text-gray-300">
              Hello {userData.firstName + " " + userData.lastName}, Mark attendance for today ({currentDate.toDateString()})
            </h3>
            <h5 className="text-sm text-left ml-4 text-green-100 dark:text-gray-300">
              You can mark your attendance for today. For any other day, please use the edit option below.
            </h5>
          </div>

          {/* Right Part */}
          <div className="w-full md:w-1/3 mt-4 md:mt-0 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-2">
            <button
              className={`w-full md:w-auto px-4 py-2 text-white rounded-md transition duration-300 ease-in-out ${userAttendanceData.checkInTime !== null || !userRole ? 'dark:bg-gray-700 cursor-not-allowed' : 'bg-blue-700 hover:bg-blue-800'
                }`}
              onClick={handleCheckIn}
              disabled={userAttendanceData.checkInTime !== null || !userRole}
            >
              Check In
            </button>
            <button
              className={`w-full md:w-auto px-4 py-2 text-white rounded-md transition duration-300 ease-in-out ${userAttendanceData.checkInTime == null || !userRole ? 'dark:bg-gray-700 cursor-not-allowed' : 'bg-blue-700 hover:bg-blue-800'
                }`}
              onClick={() => {
                if (userAttendanceData.checkInTime !== null) {
                  handleCheckOut();
                }
              }}
              disabled={!userRole}
            >
              Check Out
            </button>
          </div>

        </div>
        {/* Alert Message Container */}
        {/* {successMessage && (
          <div className="bg-green-500 text-white px-4 py-4 mt-4 rounded-md">{successMessage}</div>
        )}
        {failureMessage && (
          <div className="bg-red-500 text-white px-4 py-4 mt-4 rounded-md">{failureMessage}</div>
        )} */}
      </div>
    </>
  )
}

export default AttendancePage