import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { SidebarData } from './SidebarData';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { AuthService } from '../services/AuthService'
import { Tooltip } from 'react-tooltip'

const Navbar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
height: 7.5vh;
background-color: rgb(16,99,58);
width:100vw;
z-index: 1;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`


const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.5rem;
    margin-top: 0rem;
    height: 7.5vh;
    color: #d1d5db;
    padding-right: 25px;
    background-color:rgb(16 82 31);

    @media only screen and (max-width: 530px) {
        margin-left: 25px;
    }
`

const ProfileDropdown = styled.div`
position: fixed;
top: 0;
right: 0;
display: inline-block;
cursor: pointer;
`;

const DropdownContent = styled.div<{ isDropdown: boolean }>`
    display: ${({ isDropdown }) => (isDropdown ? 'block' : 'none')};
    position: absolute;
    background-color: rgb(30,99,58);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;

    & a {
        color: #d1d5db;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: center;

        &:hover {
            background-color: rgb(28 97 55);
        }
    }
`;

const ProfileIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    height: 7.5vh;
    color: #d1d5db;
    padding-right: 25px;
    background-color: rgb(30,99,58);
    cursor: pointer;

    @media only screen and (max-width: 530px) {
        margin-left: 25px;
    }
`;

const MenuIconClose = styled(Link)`
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.5rem;
    height: 7.5vh;
    padding-right: 15px;
    background-color:rgb(16 82 31);
    color: #d1d5db;
    transform: translateX(0);
    transition: transform 0.5s ease;
`

const SidebarMenu = styled.div<{ close: boolean }>`
    width: ${({ close }) => (close ? '250px' : '80px')};
    height: 100vh;
    background-color: rgb(16 89 44);
    position: fixed;
    top: 0;
    z-index: 2;
    left: 0;
    transition: width 0.6s ease-in-out;
    -webkit-box-shadow: 3px 0px 44px -15px rgba(0,0,0,0.47);
    -moz-box-shadow: 3px 0px 44px -15px rgba(0,0,0,0.47);
    box-shadow: 3px 0px 44px -15px rgba(0,0,0,0.47);

    @media only screen and (max-width: 530px) {
        visibility: ${({ close }) => (close ? 'visible' : 'hidden')};
        width: ${({ close }) => (close ? '80px' : '0')};
    }
    
`

const MenuItems = styled.li`
    list-style: none;
    display: flex;
    align-items: left;
    background-color:rgb(16 89 44);
    justify-content: start;
    width: 100%;
    height: 60px;
    padding: 1rem 0 1.25rem;
`

const MenuItemLinks = styled(Link) <{ close?: boolean, isSelected?: boolean }>`
    display: flex;
    align-items: center;
    padding: 28px 2rem;
    font-size: 16px;
    text-decoration: none;
    color:${({ isSelected }) => (isSelected ? 'rgb(226 235 229)' : 'rgb(159 189 171)')};
    width: 100%;
    background-color: ${({ isSelected }) => (isSelected ? 'rgb(51 119 77)' : 'transparent')};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : '')};;
    &:hover {
        background-color: rgb(51 119 77);
        color: rgb(226 235 229);
        width: 100%;
        height: 20px;
        text-align: center;
        border-radius: 5px;
        margin: 0;
        font-weight: bold;
    }

    @media only screen and (max-width: 530px) {
        span {
            display: ${({ close }) => close ? 'flex' : 'none'};
        }
    }

    @media only screen and (min-width: 530px) {
        span {
            display: ${({ close }) => close ? 'none' : 'flex'};
        }
    }
`
const Main = styled.div<{ close?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  width: ${({ close }) => close ? '60%' : '70%'};
  transition: width .6s ease-in-out;
  @media only screen and (max-width: 768px) {
    width: ${({ close }) => (close ? '60%' : '65%')};
  }
  @media only screen and (max-width: 530px) {
    width: ${({ close }) => (close ? '50%' : '60%')};
  }
`;

const Fullpage = styled.div<{ close?: boolean }>`
display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: ${({ close }) => close ? '75%' : '85%'};
  transition: width .6s ease-in-out;
  @media only screen and (max-width: 768px) {
    width: ${({ close }) => (close ? '60%' : '65%')};
  }
  @media only screen and (max-width: 530px) {
    width: ${({ close }) => (close ? '50%' : '60%')};
  }
`;

const RightBar = styled.div`
width: 20%;
display: flex;
justify-content: center;
background-color: rgb(235 237 239);
min-height: 100vh;
padding:4rem
`;

const Page = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-height: 92.5vh;
  overflow-y: scroll;
`;

interface RoleAccess {
    access: string;
}
interface sidebarSet {
    isOpen: (data: any) => void;
    sidebarAccess: RoleAccess[];
}
const Sidebar = ({ isOpen, sidebarAccess }: sidebarSet) => {
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!profileDropdownOpen);
    };
    const { keycloak } = useKeycloak();
    const [close, setClose] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string>();
    const showSidebar = () => { setClose(!close); isOpen(!close) }
    const { resetTokenAndPayload } = AuthService();
    const logout = () => {
        keycloak.logout();
        resetTokenAndPayload();
        // window.location.href = '/';
    }
    return (
        <div>
            <Navbar>
                {!close && (
                    <MenuIconOpen to="#" onClick={showSidebar}>
                        <FaIcons.FaBars />
                    </MenuIconOpen>
                )}

                {!!keycloak?.authenticated && (
                    <ProfileDropdown>
                        <ProfileIcon onClick={toggleProfileDropdown}>
                            <FaIcons.FaUserCircle />
                        </ProfileIcon>
                        <DropdownContent isDropdown={profileDropdownOpen}>
                            <Link to="/profile" onClick={toggleProfileDropdown}>Profile</Link>
                            <Link to="#" onClick={() => { logout(); toggleProfileDropdown() }}>Logout</Link>
                        </DropdownContent>
                    </ProfileDropdown>
                )}
            </Navbar>

            <SidebarMenu close={close}>
                {!close ? (
                    <MenuIconOpen to="#" onClick={showSidebar}>
                        <FaIcons.FaBars />
                    </MenuIconOpen>
                ) : (
                    <MenuIconClose to="#" onClick={showSidebar}>
                        <FaIcons.FaTimes />
                    </MenuIconClose>
                )}

                {SidebarData.filter((filteredItem, index) => {
                    return sidebarAccess.some(menu => menu.access === filteredItem.text);
                }).map((item, index) => {
                    const isSelected = selectedItem === item.path;
                    return (
                        <MenuItems key={index}>
                            <MenuItemLinks isSelected={isSelected} to={item.path} data-tooltip-id={!close ? 'my-tooltip' : ''} data-tooltip-content={item.title} onClick={() => setSelectedItem(item.path)}>
                                {item.icon}
                                {close && (
                                    <span style={{ marginLeft: '16px' }}>{item.title}</span>
                                )}
                                {/* {!close && <span style={{ marginLeft: '16px' }}>{item.title}</span>} */}
                            </MenuItemLinks>
                            {(!close || window.innerWidth <= 530) && <Tooltip id="my-tooltip" place="top-start" />}
                        </MenuItems>
                    )
                })}
            </SidebarMenu>
        </div>
    )
}

export default Sidebar
export { Main, RightBar, Page, Fullpage }


