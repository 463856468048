import { useAxios } from "../utils/hooks";

interface CompanyData {
    id: number,
    companyName: string,
    companyEmail: string,
    companyAddress: string,
    companyContactNumber: string,
    companyLogo: string,
}

interface Department {
    id: number;
    departmentName: string;
    shift: string;
    startTime: string;
    endTime: string;
}

interface DepartmentData {
    departmentName: string,
    // shift: string,
    // startTime: string,
    // endTime: string

}

interface TaxFormState {
    panCardNo: string;
    accountNo: string;
    confirmBankAccountNumber: string;
    ifscCode: string;
    beneficiaryName: string;
    paymentMode: string;
    // error: string | null;
}

export const CompanyApi = () => {

    const AxiosInstance = useAxios();


    const getCompanyDetails = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/Company/getCompanyDetails`);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const saveCompanyDetails = async (companyData: CompanyData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/editCompanyDetails`, companyData);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    // const getDepartmentDetails = async () => {
    //     try {
    //         const res: any = await AxiosInstance.current?.get(`/route/Company/getDepartmentDetails`);
    //         return res;
    //     } catch (error) {
    //         console.error("error >> ", error);
    //     }
    // }

    const saveDepartmentDetails = async (deptData: Department) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/editDepartmentDetails`, deptData);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const addDepartmentDetails = async (departData: DepartmentData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/addDepartmentDetails`, departData);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const addRole = async (button: any, Tab:any, designation: string) => {
        const data = {designation:designation, button:button, Tab:Tab}
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/addRoles`, data);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const checkRole = async (key:string) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/checkRole`, key);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const addRoleData = async (role: any, designationId:number) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/addRoleMinus?designationId=${designationId}`,role);
            return res;
        } catch (error) {
            console.error(error);
        }
    }


    const getButton = async () => {
        try {
            const res:any = await AxiosInstance.current?.get(`/route/users/getAllButton`); 
            return res;
        }catch (error) {
            console.error(error);
        }
    }

    const getRoleMenus = async (designationId:any) =>{
        try{
            const res:any = await AxiosInstance.current?.get(`/route/Company/getRoleMenus?designationId=${designationId}`);
            return res;
        } catch (error) {
            console.error(error);
        }
    }


    const addRoletab = async (role: any, designationId:number) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/addRoleTab?designationId=${designationId}`,role);
            return res;
        } catch (error) {
            console.error(error);
        }
    }
    // addDepartmentDetails

   


    const postBankData = async (banlData: TaxFormState) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/bankDetails/postBankData`, banlData);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const getBankDetails = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/bankDetails/getBankDetailsData`);
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const getProfileDoc = async () => {

        try {
            const res: any = await AxiosInstance.current?.get(`/route/users/getProfileDoc`);
            return res;
        } catch (error) {
            console.error(error);
        }
    }
    const getCompanyDataValue = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/Company/getCompanyDataValue`);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const getDwonloadExcel = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/Company/getDwonloadExcel`, {
                responseType: 'blob',
              });
            return res.data;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const postUploadExcel = async (formData: any) => { 
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/postDeptExcel`, formData);
            return res;
        } catch (error) {
            console.error(error);
        }
    }
    return { getCompanyDetails, getButton, getRoleMenus, checkRole, addRoletab, addRoleData, addRole, addDepartmentDetails, saveCompanyDetails, saveDepartmentDetails,  postBankData, getBankDetails, getProfileDoc, getCompanyDataValue, getDwonloadExcel, postUploadExcel }
}