import React, { useState, useEffect } from "react";

interface ConfirmationModalProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    message,
    onConfirm,
    onCancel
}) => {
    const [lines, setLines] = useState<string[]>([]); // State to hold the lines of the message

    // Function to split message into lines based on number of words
    const splitMessageIntoLines = () => {
        const words = message.split(' '); // Split message into words
        const linesArray: string[] = [];
        let line = '';
        const wordsPerLine = 10; // Number of words per line

        words.forEach((word, index) => {
            if (index > 0 && index % wordsPerLine === 0) {
                linesArray.push(line); // Push the completed line
                line = ''; // Reset line
            }
            line += word + ' '; // Add word to line with space
        });

        if (line.trim() !== '') {
            linesArray.push(line.trim()); // Push the last line
        }

        setLines(linesArray);
    };

    // Call splitMessageIntoLines when component mounts or message changes
    useEffect(() => {
        splitMessageIntoLines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div className="fixed top-0 left-0 min-w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-sm">
                {/* Render lines */}
                {lines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
                <div className="mt-4 flex justify-end">
                    <button className="mr-2 bg-blue-500 text-white px-4 py-2 rounded" onClick={onConfirm}>Confirm</button>
                    <button className="bg-gray-300 px-4 py-2 rounded" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

interface ConfirmButtonProps {
    children: React.ReactNode;
    onConfirm: () => void;
    message: string;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({ children, onConfirm, message }) => {
    const [showModal, setShowModal] = useState(false);

    const handleConfirm = () => {
        setShowModal(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <button className="bg-[#321FDB] text-white px-4 py-5 rounded-lg" onClick={() => setShowModal(true)}>{children}</button>
            {showModal && (
                <ConfirmationModal
                    message={message}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default ConfirmButton;
