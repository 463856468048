import React, { useEffect, useState } from "react";
import { Fullpage, Page } from "../../components/Sidebar";
import { PayableSalaryApi } from "../../services/payableSalary";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import wordsToNumbers from "words-to-numbers";
import { UsersApi } from "../../services/users";

export interface EmployeeHistory {
    id: number;
    employeeCode: string;
    employeeName: string;
    currentSalary: number;
    currentExperience: number;
    currentPayLevel: string;
}

interface MatrixDataItem {
    [key: string]: any;
}
export default function PayableMatrix({ sidebar }: any) {
    const { getEmployeeHistoryDetails, postEmployeeHistory, getMatrixData, getExcelEmployeeData, uploadEmployeeFile } = PayableSalaryApi();
    const { getAlllevel } = UsersApi()
    const [employeeHistory, getEmployeeHistory] = useState<EmployeeHistory[]>([]);
    const [currentItems, setCurrentItems] = useState<EmployeeHistory[]>([]);
    const [isEditing, setEditing] = useState(false);
    const [matrixAllData, setMatrixData] = useState<MatrixDataItem[]>([]);
    const [salaryLevel, setSalaryLevel] = useState([]);
    const [editedData, setEditedData] = useState<EmployeeHistory>({
        id: NaN,
        employeeName: '',
        employeeCode: '',
        currentPayLevel: '',
        currentExperience: 0,
        currentSalary: 0
    });
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        const response = await getEmployeeHistoryDetails();
        if (response.data.status === 200) {
            getEmployeeHistory(response.data.data);
        }
    }
    const fetchMatrixData = async () => {
        try {
            const response = await getMatrixData();
            setMatrixData(response.data.data);
        } catch (error) {
            console.error('Error fetching matrix data:', error);
            // Handle error here, such as displaying an error message to the user
        }
    };
    useEffect(() => {
        getData();
        fetchMatrixData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const convertColumnName = (name: string) => {
        if (name === 'experienceYear') return "Experience In Year";
        if (name === 'levelThirteenk') return "Level 13(C)";
        const match = name.match(/level(\w+)/);
        if (match) {
            const numericPart = wordsToNumbers(match[1]);
            return `Level ${numericPart}`;
        }

        return name;
    };

    const columnsToSkip = ['id', 'experienceYear', 'updatedAt', 'createdAt'];
    const filteredKeys = Object.keys(matrixAllData[0] || {}).filter(
        (key) => !columnsToSkip.includes(key)
    );
    const handleEditClick = (historyData: EmployeeHistory) => {
        setEditing(true);
        setEditedData(historyData);
    };

    const handleSaveClick = async () => {
        setEditing(false);
        const response = await postEmployeeHistory(editedData);
        if (response.data.status === 200) {
            toast.success(response.data.message);
            getData();
        }
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setEditedData({
            ...editedData,
            [name]: value
        });
    };

    const handleLevelTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setEditedData({
            ...editedData,
            [name]: value,
            currentSalary: 0
        });
        try {
            const salaryLevelData = await getAlllevel(value);
            const formattedSalaryLevels = salaryLevelData.data.map((salary: { [x: string]: any; }) => {
                const key = Object.keys(salary)[0]; // Get the first key (e.g., 'levelOne')
                return salary[key]; // Extract the value corresponding to the key
            });
            setSalaryLevel(formattedSalaryLevels);
        } catch (error) {
            console.error('Error fetching salary level data:', error);
        }
    };
    const handleSalaryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setEditedData({
            ...editedData,
            currentSalary: Number(value)
        });
    };

    const historyData = async () => {
        try {
            const blobData = await getExcelEmployeeData(); // Ensure this function is defined and returns the correct data
            const blob = new Blob([blobData], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Employee History.xlsx';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // Remove the <a> element after download
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading employee history data: ", error);
        }
    };

    const excelFileUpload = async (event: any) => {
        const file = event.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setIsLoading(true);
            try {
                const response = await uploadEmployeeFile(formData);
                if (response && response.data.status === 200) {
                    toast.success(response.data.message);
                } else if (response && response.data.status === 400) {
                    response.message.forEach((errorObj: any) => {
                        toast.error(errorObj.message);
                    });
                } else {
                    toast.error('An error occurred while submitting the form.');
                }
            } catch (error) {
                toast.error('An error occurred while submitting the form.');
                console.error('Error:', error);
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                    getData();
                }, 3000);

            }
        }
    };

    return (
        <Page>
            <Fullpage>
                <div className='overflow-auto px-5 py-3 relative'>
                    <div>
                        <p className='text-center text-slate-800 p-3 font-bold text-3xl'>Employee History</p>
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button onClick={historyData} className="block px-4 py-2 rounded-lg bg-green-500 border-green-700 text-white">
                            Download Employee Data
                        </button>
                        <label htmlFor="file" className="block px-4 py-2 rounded-lg bg-green-500 border-green-700 text-white">
                            Upload Excel
                            <input type="file" id="file" name="file" accept=".xlsx" className="hidden" onChange={excelFileUpload} />
                        </label>
                    </div>
                    <br />
                    <div className="relative">
                        {isLoading && (
                            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                                <div className="loader border-t-4 border-blue-500 rounded-full w-16 h-16 animate-spin"></div>
                            </div>
                        )}
                        <table className="table-fixed w-full rounded-md shadow-md bg-gradient-to-r from-green-800 to-green-900 overflow-hidden">
                            <thead className="dark:border-gray-400">
                                <tr>
                                    <th className="text-center px-4 sm:px-6 py-3 text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Employee Name</th>
                                    <th className="text-center px-4 sm:px-6 py-3 text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">7th Pay Level</th>
                                    <th className="text-center px-4 sm:px-6 py-3 text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Current Experience</th>
                                    <th className="text-center px-4 sm:px-6 py-3 text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Current Salary</th>
                                    <th className="text-center px-4 sm:px-6 py-3 text-xs sm:text-sm leading-4 font-bold text-green-100 dark:text-gray-100 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
                                {currentItems.length > 0 ? (
                                    currentItems.map((data) => (
                                        <tr key={data.id}>
                                            {isEditing && data.id === editedData.id ? (
                                                <>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <span className="font-bold text-black">{editedData.employeeName}</span>
                                                    </td>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <select
                                                            id="matrixLevel"
                                                            name="currentPayLevel"
                                                            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
                                                            value={editedData.currentPayLevel}
                                                            onChange={handleLevelTypeChange}
                                                        >
                                                            <option value="" disabled>Select Level</option>
                                                            {filteredKeys.map((headItem, index) => (
                                                                <option key={index} value={headItem}>
                                                                    {convertColumnName(headItem)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <input
                                                            type="text"
                                                            name="currentExperience"
                                                            value={editedData.currentExperience}
                                                            onChange={handleInputChange}
                                                            className="w-full px-2 py-3 border rounded-md text-black font-bold"
                                                        />
                                                    </td>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <select
                                                            id="monthlySalary"
                                                            name="currentSalary"
                                                            className="w-full border mb-2.5 p-2 rounded-[3px] border-solid border-[#ccc]"
                                                            value={editedData.currentSalary}
                                                            onChange={handleSalaryChange}
                                                        >
                                                            <option value="" disabled>Select Salary</option>
                                                            {salaryLevel.map((salary, index) => (
                                                                <option key={index} value={salary}>{salary}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <button
                                                            onClick={() => handleSaveClick()}
                                                            className="bg-blue-500 text-white px-3 py-1 rounded-md"
                                                        >
                                                            Save
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="text-center p-2 bg-green-100 text-black font-bold">{data.employeeName}</td>
                                                    <td className="text-center p-2 bg-green-100 text-black font-bold">{data.currentPayLevel}</td>
                                                    <td className="text-center p-2 bg-green-100 text-black font-bold">{data.currentExperience}</td>
                                                    <td className="text-center p-2 bg-green-100 text-black font-bold">{data.currentSalary}</td>
                                                    <td className="text-center p-2 bg-green-100">
                                                        <button
                                                            onClick={() => handleEditClick(data)}
                                                            className="bg-green-500 text-white px-3 py-1 rounded-md"
                                                        >
                                                            Edit
                                                        </button>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="text-center p-2">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Pagination isValue={employeeHistory} setCurrentItems={setCurrentItems} />
                    </div>
                </div>
            </Fullpage>
        </Page>
    );
}