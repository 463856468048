import React from "react";
import { Fullpage, Page } from '../../components/Sidebar';
import { Link } from "react-router-dom";


export default function ReportsPage({ sidebar }: any) {
    return (
        <Page>
            <Fullpage close={sidebar} >
                <div className="w-4/5 mt-10 mb-5 bg-green-800 rounded-xl shadow-lg">
                    <div className="m-4">
                        <p className="text-left font-bold text-gray-100 text-2xl">REPORTS</p>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/salaryRegister`} className="">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Salary Register</span><br />
                                <span className="text-green-800">View details of past and upcoming payroll</span>
                            </div>
                        </Link>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/CTCReport`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Master CTC Report</span><br />
                                <span className="text-green-800" >View CTC with breakup for employees</span>
                            </div>
                        </Link>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/HREmpolyeeView`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800 ">HR- Employee View</span><br />
                                <span className="text-green-800">View past and current employees and contractors</span>
                            </div>
                        </Link>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/varianceReport`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Variance Report</span><br />
                                <span className="text-green-800">View and compare payroll, compliance and employee count trends</span>
                            </div>
                        </Link>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/documents`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Documents</span><br />
                                <span className="text-green-800">View all documents for current employee</span>
                            </div>
                        </Link>
                    </div>
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/attendance`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Attendance</span><br />
                                <span className="text-green-800">View employee's attendance</span>
                            </div>
                        </Link>
                    </div>
                    {/* <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg' style={{alignSelf:"self-start"}}>
                    <Link to={`/reports/taxDeductions`} className="link-style">
                        <div className="text-left">
                            <span style={{fontWeight:"bold"}}>Tax Deductions</span><br/>
                            <span >View all the tax deductions that your employees have declared</span>
                        </div>
                    </Link>
                </div>
                 */}
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/missingInformation`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Missing Information</span><br />
                                <span className="text-green-800">View a list of employees with missing information</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/auditReport`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Employee Activity Report</span><br />
                                <span className="text-green-800">View all the changes made by your employees</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/reimbursement`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Reimbursement</span><br />
                                <span className="text-green-800">View reimbursements that are pending authorization, or have been approved for the current payroll</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/ledger`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Ledger</span><br />
                                <span className="text-green-800">View account transaction</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/loanReport`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Loan Summary Report</span><br />
                                <span className="text-green-800">View active and closed loans</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/bonusReport`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Bonus Report</span><br />
                                <span className="text-green-800">View bonus for all employees</span>
                            </div>
                        </Link>
                    </div>

                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/upcomingContractorPayments`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Upcoming Contractor Payments</span><br />
                                <span className="text-green-800">View future contractor payments</span>
                            </div>
                        </Link>
                    </div>

                    {/* <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg' style={{alignSelf:"self-start"}}>
                    <Link to={`/reports/PFChallans`} className="link-style">
                        <div className="text-left text-lg">
                            <span style={{fontWeight:"bold"}}>Provident Fund, ESI & Professional Tax</span><br/>
                            <span >View PF, ESI & PT challans/receipts</span>
                        </div>
                    </Link>
                </div>
                
                <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg' style={{alignSelf:"self-start"}}>
                    <Link to={`/reports/tdsChallans`} className="link-style">
                        <div className="text-left text-lg">
                            <span style={{fontWeight:"bold"}}>TDS</span><br/>
                            <span >View TDS challans, filling acknowledgements and calculations</span>
                        </div>
                    </Link>
                </div>
                 */}
                    <div className='shadow-xl m-4 p-3 bg-gray-100 sm:rounded-lg'>
                        <Link to={`/reports/resignations`} className="link-style">
                            <div className="text-left text-lg">
                                <span className="font-bold text-green-800">Resignations</span><br />
                                <span className="text-green-800">View resignation applications, approve or reject them and also view past applications</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </Fullpage>
        </Page>
    );
}
