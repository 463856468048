import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import Sidebar from '../components/Sidebar'
import PeoplePage from '../pages/People/PeoplePage'
import LeaveAttendencePage from '../pages/LeaveAttendence/LeaveAttendencePage'
// import SalaryStructures from '../pages/Setting/component/Payroll';
import '../App.css';
import RunPayroll from '../pages/payrollComponent/runPayrol'
import { AuthService } from '../services/AuthService'
import PeopleDetails from '../pages/People/Components/PeopleDetails'
import PeopleUser from '../pages/People/Components/PeopleUser'
import DashboardPage from '../pages/Dashboard/Dashboard'
import SettingPage from '../pages/Setting/Setting'
import { UsersApi } from '../services/users'
import { ToastContainer } from 'react-toastify'
import PaymentSlipPage from '../pages/payrollComponent/paymentSlip'
import ReimbursementPage from '../pages/payrollComponent/reimbursement'
import ReportsPage from '../pages/Reports/Reports'
import SalaryRegisterPage from '../pages/Reports/Components/SalaryRegister'
import AttendanceReportPage from '../pages/Reports/Components/attendance'
import AuditReportPage from '../pages/Reports/Components/auditReport'
import BonusReportPage from '../pages/Reports/Components/BonusReport'
import DocumentsReportsPage from '../pages/Reports/Components/Documents'
import HREmpolyeeView from '../pages/Reports/Components/HREmpolyeeView'
import LedgerPage from '../pages/Reports/Components/Ledger'
import LoanSummaryPage from '../pages/Reports/Components/LoanSummary'
import MasterCTCReportPage from '../pages/Reports/Components/MasterCTCReport'
import MissingInformationPage from '../pages/Reports/Components/MissingInformation'
import PFAndPTChallanPage from '../pages/Reports/Components/PFAndPTChallan'
import ReimbursementReportPage from '../pages/Reports/Components/Reimbursement'
import ResignationReportPage from '../pages/Reports/Components/Resignation'
import TaxDeductionPage from '../pages/Reports/Components/TaxDeduction'
import TDSChallanPage from '../pages/Reports/Components/TDSChallan'
import UpcomingContractorsPaymentPage from '../pages/Reports/Components/UpcomingContractorsPayments'
import VarianceReportPage from '../pages/Reports/Components/varianceReport'
import DocumentPage from '../pages/People/Components/DocumentPage'
import FnfSettlement from '../pages/Reports/Components/FnfSettlement'
import Designation from '../pages/Designation/Designation'
import Resignation from '../pages/Reignation/Resignation'
import CustomLoader from '../components/loader'
import { Child, Prompt } from '../components/Idletimer'
import Matrix from "../pages/payrollComponent/paymatrix"
import PayableMatrix from '../pages/payrollComponent/payableMatrix'
// import ResignationEmp from '../pages/People/Components/ResignationEmp'
// import PeopProfPhoto from '../pages/People/Components/PeopProfPhoto'

interface RoleAccess {
  access: string;
}

export const AppRouter = () => {

  const { initialized, keycloak } = useKeycloak()
  const { getUserPayload } = AuthService();
  let [user, setUser] = useState<any>();
  let [sidebar, setSidebar] = useState<any>(false);
  const { getRoleMenus } = UsersApi();
  const [sidebarData, setSidebarData] = useState<RoleAccess[]>([]);
  const [buttons, setButtons] = useState<RoleAccess[]>([]);
  const open = useRef<boolean>(false);
  const shouldRedirect = window.location.pathname === '/';
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getUser() {
    setTimeout(async () => {
      if (keycloak.authenticated) {
        setUser(await getUserPayload());
        try {
          const response = await getRoleMenus();
          setSidebarData(response.data.tabs);
          setButtons(response.data.buttons)
        } catch (error) {
          setSidebarData([{ access: "No Tabs" }]);
        }

      }
    }, 2000)
  }

  if (sidebarData.length === 1 && sidebarData[0].access === "No Tabs") {
    return <div>NO Tabs</div>
  }

  if (!initialized || sidebarData.length === 0) {
    // if (!initialized) {
    // if (true) {
    return (
      <Router>
        <Sidebar isOpen={setSidebar} sidebarAccess={sidebarData} />
        <CustomLoader size={200} colors={'green'} styling={"fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 z-[2]"} />
      </Router>
    )
  }

  return (
    <div>
      <Child open={open} />
      <Prompt open={open} keycloak={keycloak} />
      <Router>
        {/* {sidebarData.length === 0 && <CustomLoader size={200} colors={'green'} styling={"fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 z-[2]"} />} */}
        <Sidebar isOpen={setSidebar} sidebarAccess={sidebarData} />
        <ToastContainer autoClose={5000} />
        {shouldRedirect && <Redirect exact from='/' to='/home' />}
        <Route path='/home' ><DashboardPage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /> </Route>
        <Route path='/profile' ><PeopleDetails userRole={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/people' exact ><PeoplePage sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/salarySlip' exact ><PaymentSlipPage sidebar={sidebar} /></Route>
        <Route path='/documents' exact ><DocumentPage sidebar={sidebar} /></Route>
        <Route path='/matrix' exact ><Matrix sidebar={sidebar} /></Route>
        <Route path='/payablematrix' exact ><PayableMatrix sidebar={sidebar} /></Route>
        <Route path='/designation' exact ><Designation sidebar={sidebar} /></Route>
        <Route path='/exit' exact ><Resignation user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/reimbursement' exact ><ReimbursementPage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/reimbursement/:userid'><ReimbursementPage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/reports' exact ><ReportsPage sidebar={sidebar} /></Route>
        <Route path='/peopleUser'><PeopleUser /></Route>
        <Route path='/people/PeopleDetails/:userId' ><PeopleDetails userRole={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/leaveAttendance/' exact><LeaveAttendencePage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /> </Route>
        <Route path='/leaveAttendance/:userid' ><LeaveAttendencePage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /> </Route>
        {/* <Route path='/payroll' ><SalaryStructures sidebar={sidebar} sidebarAccess={sidebarData} user={user?.role} /></Route> */}
        <Route path='/runpayroll'> <RunPayroll sidebar={sidebar} user={user?.role} sidebarAccess={buttons} /></Route>
        <Route path='/setting'><SettingPage user={user?.role} sidebar={sidebar} sidebarAccess={buttons} /></Route>
        <Route path='/reports/salaryRegister'> <SalaryRegisterPage sidebar={sidebar} /></Route>
        <Route path='/reports/attendance'> <AttendanceReportPage sidebar={sidebar} /></Route>
        <Route path='/reports/auditReport'> <AuditReportPage sidebar={sidebar} /></Route>
        <Route path='/reports/bonusReport'> <BonusReportPage sidebar={sidebar} /></Route>
        <Route path='/reports/documents'> <DocumentsReportsPage sidebar={sidebar} /></Route>
        <Route path='/reports/HREmpolyeeView'> <HREmpolyeeView sidebar={sidebar} /></Route>
        <Route path='/reports/ledger'> <LedgerPage sidebar={sidebar} /></Route>
        <Route path='/reports/loanReport'> <LoanSummaryPage sidebar={sidebar} /></Route>
        <Route path='/reports/CTCReport'> <MasterCTCReportPage sidebar={sidebar} /></Route>
        <Route path='/reports/missingInformation'> <MissingInformationPage sidebar={sidebar} /></Route>
        <Route path='/reports/PFChallans'> <PFAndPTChallanPage sidebar={sidebar} /></Route>
        <Route path='/reports/reimbursement'> <ReimbursementReportPage sidebar={sidebar} /></Route>
        <Route path='/reports/resignations' exact> <ResignationReportPage sidebar={sidebar} /></Route>
        <Route path='/resignations/fnfsettlement'> <FnfSettlement sidebar={sidebar} /></Route>
        <Route path='/reports/taxDeductions'> <TaxDeductionPage sidebar={sidebar} /></Route>
        <Route path='/reports/tdsChallans'> <TDSChallanPage sidebar={sidebar} /></Route>
        <Route path='/reports/upcomingContractorPayments'> <UpcomingContractorsPaymentPage sidebar={sidebar} /></Route>
        <Route path='/reports/varianceReport'> <VarianceReportPage sidebar={sidebar} /></Route>
      </Router>


    </div>

  )
}
