import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
// import env from "react-dotenv";
import keycloak from "../../utils/keycloak";
import { AiOutlineEye, AiOutlineDelete } from 'react-icons/ai';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

// FieldArray,
// ErrorMessage


const schema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required"),
  companyEmail: Yup.string().required("Enter Your Email !"),
  companyAddress: Yup.string().required("Company Address is required"),
  countryCode: Yup.mixed(),
  companyContactNumber: Yup.string()
    .required('Mobile number is required')
    .test('is-ten-digits', 'Mobile number must have exactly 10 digits', (value) => {
      if (!value) return false;
      return /^\d{10}$/.test(value);
    }),
  file: Yup.string().required("File is required"),
  // departments: Yup.array().of(Yup.object().shape({
  //   departmentName: Yup.string().required("Department Name is required"),
  //   shift: Yup.string().required("Select a Shift"),
  //   startTime: Yup.string().required("Start Time is required"),
  //   endTime: Yup.string().required("End Time is required")
  // })),
  // roleName: Yup.array().of(Yup.string().required("Role Name is required")),
});

function HomePage() {
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  // const [step, setStep] = useState(1);
  const initialValues = {
    companyName: "",
    companyEmail: "",
    file: "",
    companyAddress: "",
    companyContactNumber: "",
    countryCode: "",
    // departments: [
    //   {
    //     departmentName: '',
    //     shift: "",
    //     startTime: "",
    //     endTime: "",
    //   }],
    // roleName: [],
  };
  const handleSubmit = async (values: any) => {
    // const roleData = values.roleName.map((role: string) => ({ role: role }));
    const formData = new FormData();

    formData.append("companyName", values.companyName);
    formData.append("companyEmail", values.companyEmail);
    formData.append("file", values.file);
    formData.append("companyAddress", values.companyAddress);
    formData.append("companyContactNumber", String(values.companyContactNumber));
    formData.append("countryCode", values.countryCode);

    // values.departments.forEach((department: any, index: number) => {
    //   const departmentData = {
    //     departmentName: department.departmentName,
    //     shift: department.shift,
    //     startTime: department.startTime,
    //     endTime: department.endTime,
    //   };

    //   formData.append(`departments[${index}]`, JSON.stringify(departmentData));
    // });

    // formData.append("roleName", JSON.stringify(roleData));

    await axios
      .post(
        process.env.REACT_APP_BACKEND_SERVER_URL + "/route/Company/postCompanyDetails",
        formData
      )
      .then((response) => {
        keycloak.login();
      });



  };

  // const handleNext = () => {
  //   setStep(step + 1);
  // };
  // const handlePrevious = () => {
  //   setStep(step - 1);
  // };

  const handleView = async () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleDelete = () => {
    // Close the modal if it's open
    closeModal(); // You need to define this function to handle modal closure
    // Reset the file values and URL
    if (url) {
      URL.revokeObjectURL(url);
    }
    // Reset URL state after revoking URL
    setUrl(''); // This will trigger a re-render after the URL is revoked
  };

  return (

    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >

      {(formik) => (
        <Form >
          <div className="grid grid-cols-2 place-content-center w-full h-screen bg-[#669799]">
            <div className="flex justify-center items-center">
              <div className="bg-[#669799] col-auto" > {/* Image container on right side */}
                <h1 className="text-gray-800 font-semibold text-3xl ">Powered By</h1>
                <div className="image-container">
                  {url && <img src={url} alt="Uploaded" className="max-w-full max-h-full" />}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="bg-[#669799]">
                <div className="text-gray-800 font-semibold text-3xl mb-6">
                  <h1 >SuperAdmin Registration</h1>
                </div>
                <label className="block text-left-gray-800 font-semibold">University Name</label>
                <Field
                  className="w-full max-w-md rounded py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-[#45885c] focus:border-[#45885c] sm:text-sm sm:leading-6"
                  type="text"
                  id="companyName"
                  name="companyName"
                  onBlur={formik.handleBlur}
                  placeholder="Company Name"
                />
                {formik.touched.companyName &&
                  formik.errors.companyName && (
                    <div className="text-sm text-red-600 block text-left mb-[5px]">
                      {formik.errors.companyName}
                    </div>
                  )}
                <label className="block text-left-gray-800 font-semibold ">University Official Email</label>
                <Field
                  className="w-full max-w-md rounded py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-[#45885c] focus:border-[#45885c] sm:text-sm sm:leading-6"
                  type="text"
                  id="companyEmail"
                  name="companyEmail"
                  onBlur={formik.handleBlur}
                  placeholder="Email"
                />
                {formik.touched.companyEmail &&
                  formik.errors.companyEmail && (
                    <div className="text-sm text-red-600 block text-left mb-[5px]">
                      {formik.errors.companyEmail}
                    </div>
                  )}

                <label className="block text-left-gray-800 font-semibold">University Address</label>
                <Field
                  className="w-full max-w-md rounded py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-[#45885c] focus:border-[#45885c] sm:text-sm sm:leading-6"
                  type="text"
                  id="companyAddress"
                  name="companyAddress"
                  onBlur={formik.handleBlur}
                  placeholder="Company Address"
                />
                {formik.touched.companyAddress &&
                  formik.errors.companyAddress && (
                    <div className="text-sm text-red-600 block text-left mb-[5px]">
                      {formik.errors.companyAddress}
                    </div>
                  )}
                <label className="block text-left-gray-800 font-semibold ">University Contact Details</label>
                <PhoneInput
                  onChange={(phone, countryInfo) => {
                    if (typeof phone === 'string' && countryInfo && typeof countryInfo === 'object') {
                      // Extract the numeric part of the phone number without the country code
                      const numericPhone = phone.replace(/\D/g, '');

                      // Extract the country code
                      const countryCode = 'dialCode' in countryInfo ? countryInfo.dialCode : '';

                      // Extract the country name (example: 'India')
                      // const countryName = 'name' in countryInfo ? countryInfo.name : '';

                      // Calculate the contact number without the country code
                      const contactNumberWithoutCode = numericPhone.substring(countryCode.length);

                      // Update formik state
                      formik.setValues({
                        ...formik.values,
                        companyContactNumber: contactNumberWithoutCode,
                        countryCode: countryCode,
                      });
                    }
                  }}
                  inputProps={{
                    id: "companyContactNumber",
                    name: "companyContactNumber",
                    className: "w-full max-w-md rounded py-1.5 px-2 pl-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-none focus:ring-inset focus:ring-[#45885c] focus:border-[#45885c] sm:text-sm sm:leading-6",
                  }}
                  country={'in'}
                  dropdownClass="phone-input-dropdown"
                  buttonStyle={{
                    height: '88%',
                    borderRadius: '5px 0px 0px 5px',
                    margin: '2px 0px 0px 2px',
                    border: 'none',
                    background: 'white',
                  }}
                />

                {formik.touched.companyContactNumber &&
                  formik.errors.companyContactNumber && (
                    <div className="text-sm text-red-600 block text-left mb-[5px]">
                      {formik.errors.companyContactNumber}
                    </div>
                  )}
                <br />
                <div>
                  {/* Conditionally render the upload button based on the absence of the URL */}
                  {!url && (
                    <div className="relative overflow-hidden inline-block bg-[#337ab7] text-white cursor-pointer px-[15px] py-[5px] rounded-[5px] border-2 border-solid border-[#2e6da4] ">
                      <label htmlFor="logo" className="block text-left mb-[5px]">
                        Upload University logo
                      </label>

                      <Field
                        className='absolute text-[100px] opacity-0 top-0'
                        type="file"
                        accept="image/*"
                        id="logo"
                        name="logo"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                          let value = URL.createObjectURL(e.target.files[0]);
                          setUrl(value);
                          formik.setFieldValue("file", e.target.files?.[0]);
                        }}
                      />
                      {/* {url && <img src={url} alt="Uploaded" />} */}
                    </div>
                  )}


                  {/* Conditionally render icons based on the presence of the file */}
                  {formik.values.file && url && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span onClick={handleView} style={{ marginRight: '5px' }}>
                        <AiOutlineEye />
                      </span>
                      <span onClick={handleDelete}>
                        <AiOutlineDelete />
                      </span>
                    </div>
                  )}
                  {showModal && (
                    <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                      <div className="relative z-50  bg-transparent">
                        <div className="relative flex flex-col items-center mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-xl">
                          <img src={url} alt="Uploaded File" className="max-w-lg" />
                          <div className="flex justify-center mt-6">
                            <button type="button" onClick={closeModal} className="px-4 py-2 bg-gray-200 text-gray-800 font-semibold rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <span style={{ fontWeight: "bold" }}>Upload University Logo in image format only. </span>
                <br />
                {formik.touched.file && formik.errors.file && (
                  <div className="text-sm text-red-600 block text-left mb-[5px]">
                    {formik.errors.file}
                  </div>
                )}
                <br />

                <button
                  // onClick={loginpage}
                  className="middle none center mr-3 rounded-full bg-cyan-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-lg shadow-cyan-500/50 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  data-ripple-light="true"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default HomePage;
