import { AdvanceFormData } from "../pages/payrollComponent/reimbursement";
import { useAxios } from "../utils/hooks"

interface ReimbursementFormData {
    type: string;
    expenseDate: string;
    description: string;
    amount: number;
    file: File | null;
}

export const ReimbursementApi = () => {
    const AxiosInstance = useAxios();

    const addReimbursementData = async (formData: FormData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/reimbursement/postReimbursementAmount`, formData);
            return res;
        }
        catch (err) {
            console.error(err)
        }
    }
    const getReimbursementData = async (userId: number) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/reimbursement/getReimbursementAmount?userId=${userId}`);
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const deleteReimbursementData = async (id: number) => {

        try {
            const res: any = await AxiosInstance.current?.post(`/route/reimbursement/deleteReimbursementData`, { id: id });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const updateReimbursementData = async (id: number, formData: ReimbursementFormData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/reimbursement/updateReimbursementAmount`, { id, formData });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const approvedReimursementData = async (selectedData: any, type: string) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/reimbursement/approvedReimursementData`, { selectedData, type });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const userLoanData = async (userLoanData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/loanDetails/userLoanData`, userLoanData);
            return res;
        }
        catch (err) {
            console.error(err)
        }
    }
    const getLoanData = async (userId: number) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/loanDetails/getLoanData?userId=${userId}`);
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const deleteLoansData = async (id: number) => {

        try {
            const res: any = await AxiosInstance.current?.post(`/route/loanDetails/deleteLoansData`, { id: id });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const updateLoanData = async (id: number, formData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/loanDetails/updateLoanData`, { id, formData });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const approvedLoanData = async (selectedData: any, type: string) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/loanDetails/approvedLoanData`, { selectedData, type });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const userAdSalData = async (userAdSalData: AdvanceFormData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/advanceSalary/userAdSalData`, userAdSalData);
            return res;
        }
        catch (err) {
            console.error(err)
        }
    }
    const getadvanceSalaryData = async (userId: number) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/advanceSalary/getadvanceSalaryData?userId=${userId}`);
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const deleteAdvanceSalData = async (id: number) => {

        try {
            const res: any = await AxiosInstance.current?.post(`/route/advanceSalary/deleteAdvanceSalData`, { id: id });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const updateAdSalData = async (id: number, formData: AdvanceFormData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/advanceSalary/updateAdSalData`, { id, formData });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const approvedAdSalData = async (selectedData: any, type: string) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/advanceSalary/approvedAdSalData`, { selectedData, type });
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    const addChequeData = async (formData: any) => {        
        try {
            const res: any = await AxiosInstance.current?.post(`/route/users/getChequeData`,formData);
            return res;
        }
        catch (err) {
            console.error(err);
        }
    }
    return { addReimbursementData, getReimbursementData, deleteReimbursementData, updateReimbursementData, approvedReimursementData, userLoanData, getLoanData, deleteLoansData, updateLoanData, approvedLoanData, userAdSalData, getadvanceSalaryData, deleteAdvanceSalData, updateAdSalData, approvedAdSalData, addChequeData }
}