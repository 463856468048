import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Main, Page, RightBar } from '../../components/Sidebar';
import { ReimbursementApi } from "../../services/Reimbursement";
import Button from "../../components/Button";
import Modal from '../../components/modal';
import { FaPenAlt, FaTrashAlt } from "react-icons/fa";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ReimbursementFormProps {
  onSubmit: (formData: ReimbursementFormData) => void;
}
interface RoleAccess {
  access: string;
}
interface ReimbursementFormData {
  id: number
  type: string;
  expenseDate: string;
  description: string;
  amount: number;
  file: any;
}
export interface IReimbirsementData {
  id: number;
  userId: number;
  expenseDate: string;
  type: string;
  amount: number;
}
export interface FormLoanData {
  id: number;
  loanType: string;
  loanAmount: number;
  emiMonth: number;
  interestRate: number;
  // interestType: string;
  file: any;
}
export interface AdvanceFormData {
  id: number;
  advanceAmount: number;
  emiAmount: number;
  reason: string;
  remainigAmount: number;
  emiMonth: number;
}
interface ReimbursementProps {
  sidebarAccess: RoleAccess[];
  user: string,
  sidebar: boolean
}

export default function ReimbursementPage(props: ReimbursementProps) {
  const { addReimbursementData, getReimbursementData, deleteReimbursementData, updateReimbursementData, approvedReimursementData, userLoanData, getLoanData, deleteLoansData, updateLoanData, approvedLoanData, userAdSalData, getadvanceSalaryData, deleteAdvanceSalData, updateAdSalData, approvedAdSalData } = ReimbursementApi();
  const [btnDisable, setBtnDisable] = useState(false);
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenLoanModal, setOpenLoanModal] = useState(false);
  const [OpenadSalModal, setOpenadSalModal] = useState(false);
  const [OpenadSalUserModa, setOpenadSalUserModal] = useState(false);
  const [getData, setgetData] = useState<any>([])
  const [departmentEdit, setDepartmentEdit] = useState(false);
  const [LoanDepartmentEdit, setLoanDepartmentEdit] = useState(false);
  const [AdSalDepartmentEdit, setAdSalDepartmentEdit] = useState(false);
  const [AdSalDepartmentEditUser, sethandleEditAdSalUser] = useState(false);

  const [selectedItems, setSelectedItems] = useState<IReimbirsementData[]>([]);
  const [selectedData, setSelectedData] = useState<{ id: number, userId: number, emiAmount: number, emiMonth: number, interestRate: number, interestType: string, loanAmount: number }[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const { userid } = useParams<{ userid: string }>();
  const userId = parseInt(userid)
  const [getLoansData, setgetLoanData] = useState<FormLoanData[]>([])
  const [getAdSalData, setgetAdSalData] = useState<AdvanceFormData[]>([])
  const [adSalSelectedData, setAdSalSelectedData] = useState<{ id: number, userId: number, emiAmount: number, advanceAmount: number }[]>([]);



  const [formData, setFormData] = useState<ReimbursementFormData>({
    id: NaN,
    type: '',
    expenseDate: '',
    description: '',
    amount: 0,
    file: "",
  });

  const [formLoanData, setFormLoanData] = useState<FormLoanData>({
    id: NaN,
    loanType: '',
    loanAmount: 0,
    emiMonth: 0,
    interestRate: 0,
    // interestType: '',
    file: ""
  });

  const [formAdSalData, SetFormAdSalData] = useState<AdvanceFormData>({
    id: NaN,
    advanceAmount: 0,
    emiAmount: 0,
    reason: '',
    remainigAmount: 0,
    emiMonth: 0
  })


  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: number, userId: number, emiAmount: number, emiMonth: number, interestRate: number, interestType: string, loanAmount: number) => {
    if (event.target.checked) {
      setSelectedData([{ id, userId, emiAmount, emiMonth, interestRate, interestType, loanAmount }]);
    }
  };
  const handleAdSalRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: number, userId: number, emiAmount: number, advanceAmount: number) => {
    if (event.target.checked) {
      setAdSalSelectedData([{ id, userId, emiAmount, advanceAmount }]);
    }
  };

  const handleLoanInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormLoanData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    getPendingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };
  const getPendingData = async () => {
    try {
      const response = await getReimbursementData(userId);
      setgetData(response.data.data);
      const loanData = await getLoanData(userId);
      setgetLoanData(loanData.data.data);
      const advanceSalary = await getadvanceSalaryData(userId)
      setgetAdSalData(advanceSalary.data.data);
    } catch (error) {
    }
  };

  const isRadioChecked = (id: number, userId: number, emiAmount: number, emiMonth: number, interestRate: number, interestType: string, loanAmount: number) => {
    return (
      selectedData.length === 1 &&
      selectedData[0].id === id &&
      selectedData[0].userId === userId &&
      selectedData[0].emiAmount === emiAmount &&
      selectedData[0].emiMonth === emiMonth &&
      selectedData[0].interestRate === interestRate &&
      selectedData[0].interestType === interestType &&
      selectedData[0].loanAmount === loanAmount
    );
  };
  const isRadioAdSalChecked = (id: number, userId: number, emiAmount: number, advanceAmount: number) => {
    return (
      adSalSelectedData.length === 1 &&
      adSalSelectedData[0].id === id &&
      adSalSelectedData[0].userId === userId &&
      adSalSelectedData[0].emiAmount === emiAmount &&
      adSalSelectedData[0].advanceAmount === advanceAmount
      // selectedData[0].interestRate === interestRate &&
      // selectedData[0].interestType === interestType &&
      // selectedData[0].loanAmount === loanAmount
    );
  };


  const handleEditDept = (id: number, deptData: any) => {
    setOpenModal(true);
    setDepartmentEdit(true);
    setFormData({
      id: deptData.id,
      type: deptData.type,
      expenseDate: deptData.expenseDate,
      description: deptData.description,
      amount: deptData.amount,
      file: deptData.file,
    });
  };
  const handleEditLoan = (id: number, deptData: any) => {
    setOpenLoanModal(true);
    setLoanDepartmentEdit(true);
    setFormLoanData({
      id: deptData.id,
      loanType: deptData.loanType,
      loanAmount: deptData.loanAmount,
      emiMonth: deptData.emiMonth,
      interestRate: deptData.interestRate,
      // interestType: deptData.interestType,
      file: deptData.file,
    });
  };
  const handleEditAdSal = (id: number, deptData: any) => {
    setOpenadSalModal(true);
    setAdSalDepartmentEdit(true);
    SetFormAdSalData({
      id: deptData.id,
      advanceAmount: deptData.advanceAmount,
      emiAmount: deptData.emiAmount,
      reason: deptData.reason,
      remainigAmount: deptData.remainigAmount,
      emiMonth: deptData.emiMonth
    });
  };
  const handleEditAdSalUser = (id: number, deptData: any) => {
    setAdSalDepartmentEdit(true);
    SetFormAdSalData({
      id: deptData.id,
      advanceAmount: deptData.advanceAmount,
      emiAmount: deptData.emiAmount,
      reason: deptData.reason,
      remainigAmount: deptData.remainigAmount,
      emiMonth: deptData.emiMonth
    });
  };
  const toggleCheckbox = (id: number, userId: number, expenseDate: string, type: string, amount: number) => {
    const isSelected = isCheckboxChecked(id, userId, expenseDate, type, amount);

    if (isSelected) {
      setSelectedItems(selectedItems.filter(item => item.id !== id));
    } else {
      setSelectedItems([...selectedItems, { id, userId, expenseDate, type, amount }]);
    }
  };

  const isCheckboxChecked = (id: number, userId: number, expenseDate: string, type: string, amount: number) => {
    return selectedItems.some(item => (
      item.id === id && item.userId === userId && item.expenseDate === expenseDate && item.type === type && item.amount === amount
    ));
  };

  const handleApproveClick = async () => {
    // Implement logic to send selected data for approval
    const response = await approvedReimursementData(selectedItems, 'Approved');
    if (response.data.status === 200) {
      toast.success(response.data.message);
      getPendingData()
    }
    else if (response.data.status === 500) {
      toast.error(response.data.message);

    }
    // Clear selected items after handling the action
    setSelectedItems([]);
  };

  const handleLoanApproveClick = async () => {
    if (selectedData.some(item =>
      Object.values(item).some(value => value === null || value === "")
    )) {
      toast.error("Selected data contains null or empty values. Please check and try again.");
      return;
    }
    const response = await approvedLoanData(selectedData, 'Approved');
    if (response.data.status === 200) {
      toast.success(response.data.message);
      getPendingData();
    }
    if (response.data.status === 500) {
      toast.error(response.data.message);
    }
    // Clear selected items after handling the action
    setSelectedData([]);
  };

  const handleAdSalApproveClick = async () => {
    if (adSalSelectedData.some(item =>
      Object.values(item).some(value => value === null)
    )) {
      toast.error("Selected data contains null or empty values. Please check and try again.");
      return;
    }
    const response = await approvedAdSalData(adSalSelectedData, 'Approved');
    if (response.data.status === 200) {
      toast.success(response.data.message);
      getPendingData();
    }
    if (response.data.status === 500) {
      toast.error(response.data.message);
    }
    // Clear selected items after handling the action
    setAdSalSelectedData([]);
  };

  const handleRejectClick = async () => {
    if (selectedData.some(item =>
      Object.values(item).some(value => value === null || value === "")
    )) {
      toast.error("Selected data contains null or empty values. Please check and try again.");
      return;
    }
    const response = await approvedReimursementData(selectedItems, 'Rejected');
    if (response.data.status === 200) {
      toast.success(response.data.message);
      getPendingData()
    }
    if (response.data.status === 500) {
      toast.error(response.data.message);
    }
    // Clear selected items after handling the action
    setSelectedItems([]);
  };

  const handleLoanRejectClick = async () => {
    const response = await approvedLoanData(selectedData, 'Rejected');
    if (response.status === 200) {
      toast.success(response.data.message);
      getPendingData()
    }
    // Clear selected items after handling the action
    setSelectedData([]);
  };

  const handleAdSalRejectClick = async () => {
    const response = await approvedAdSalData(adSalSelectedData, 'Rejected');
    if (response.status === 200) {
      toast.success(response.data.message);
      getPendingData()
    }
    // Clear selected items after handling the action
    setSelectedData([]);
  };

  const deleteData = async (id: number) => {
    try {
      const response = await deleteReimbursementData(id);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        getPendingData();
      }
      else{
        toast.error(response.data.message);
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  const deleteLoanData = async (id: number) => {
    try {
      const response = await deleteLoansData(id);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        getPendingData();
      }
      else{
        toast.error(response.data.message);
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  const deleteAdSalData = async (id: number) => {
    try {
      const response = await deleteAdvanceSalData(id);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        getPendingData();
      }
      else {
        toast.error(response.data.message);
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // const handleLoanSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = e.target;
  //   setFormLoanData({
  //     ...formLoanData,
  //     [name]: value,
  //   });
  // };
  const handleAdSalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    SetFormAdSalData((prevData) => ({
      ...prevData,
      [name]: name === 'advanceAmount' || name === 'emiAmount' ? parseFloat(value) : value,
    }));
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const files = e.target.files?.[0];
    // const fileNames = Array.from(files || []).map((file) => file.name).join(', ');
    setFormData({
      ...formData,
      file: files,
    });
  };

  const handleLoanFile = (e: React.ChangeEvent<HTMLInputElement>) => {

    const files = e.target.files?.[0];
    // const fileNames = Array.from(files || []).map((file) => file.name).join(', ');
    setFormLoanData({
      ...formLoanData,
      file: files,
    });
  };

  // const handleView = (file: string, fileUrl: string, index: number) => {
  //   setSelectedFile({ file, fileUrl });

  //   // Open the modal
  //   setModalOpen(true);
  // };




  // const closeModal = () => {
  //   // Close the modal
  //   setModalOpen(false);
  // };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (departmentEdit) {
        // If in edit mode, update the existing reimbursement
        const response = await updateReimbursementData(formData.id, formData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          getPendingData();
          setOpenModal(false);
        }
      }
      else {
        let reimbursementData = new FormData();
        reimbursementData.append('file', formData.file)
        reimbursementData.append('type', formData.type)
        reimbursementData.append('amount', String(formData.amount))
        reimbursementData.append('expenseDate', formData.expenseDate)
        reimbursementData.append('description', formData.description)
        const response = await addReimbursementData(reimbursementData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          getPendingData();
          setOpenModal(false);
          setFormData({
            id: NaN,
            type: '',
            expenseDate: '',
            description: '',
            amount: 0,
            file: '',
          });
          const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
          if (fileInput) {
            fileInput.value = ''; // Reset the file input value
          }
        }
      }

    } catch (err) {
      console.error("err");
    }
  };

  const handleLoanSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {

      if (LoanDepartmentEdit) {
        // If in edit mode, update the existing reimbursement
        const response = await updateLoanData(formLoanData.id, formLoanData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          getPendingData();
          setOpenLoanModal(false);
        }
      } else {
        let loanFormData = new FormData();
        loanFormData.append('file', formLoanData.file);
        loanFormData.append('emiMonth', String(formLoanData.emiMonth));
        loanFormData.append('interestRate', String(formLoanData.interestRate));
        // loanFormData.append('',formLoanData.interestType)
        loanFormData.append('loanAmount', String(formLoanData.loanAmount))
        loanFormData.append('loanType', formLoanData.loanType)
        const response = await userLoanData(loanFormData);
        if (response.status === 200) {
          toast.success(response.data.message);
          setOpenLoanModal(false);
          setgetLoanData([response.data.data])
          getPendingData();
          setFormLoanData({
            id: NaN,
            loanType: '',
            loanAmount: 0,
            emiMonth: 0,
            interestRate: 0,
            // interestType: '',
            file: ""
          })
        }
      }

    }
    catch (err) {
      console.error(err);
    }
  }
  const handleAdSalSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (AdSalDepartmentEdit || AdSalDepartmentEditUser) {
        const response = await updateAdSalData(formAdSalData.id, formAdSalData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          getPendingData();
          setOpenadSalModal(false);
          setOpenadSalUserModal(false)
        }
        else {
          toast.error(response.data.message);
        }
      }
      else {
        const response = await userAdSalData(formAdSalData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setOpenadSalModal(false);
          getPendingData();
          SetFormAdSalData({
            id: NaN,
            advanceAmount: 0,
            emiAmount: 0,
            reason: '',
            remainigAmount: 0,
            emiMonth: 0
          })
        }
        else {
          toast.error(response.data.message)
        }
      }

    }
    catch (err) {
      toast.error("Something is Wrong");
    }
  }

  function formatNumberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <Page>

      <Main close={props.sidebar}>
        <div className="w-full bg-white p-5 mt-5 shadow-lg rounded-lg">
          <Modal isOpen={OpenModal} onClose={() => {
            setOpenModal(false); setDepartmentEdit(false); setFormData({
              id: NaN,
              type: '',
              expenseDate: '',
              description: '',
              amount: 0,
              file: '',
            });
          }}>
            <form onSubmit={handleSubmit} className="w-full mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
              <h3 className="text-2xl font-bold mb-6 text-center text-black-500 whitespace-nowrap">
                {departmentEdit ? "Edit Reimbursement" : "Claim a Reimbursement"}
              </h3>
              {departmentEdit ? (<>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Type of reimbursement:</label>
                  <select
                    name="type"
                    value={formData?.type}
                    onChange={handleSelectChange}  // Use the same handler for input change
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  >
                    <option value="" disabled>Select Type</option>
                    <option value="Travel">Travel</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Food">Food</option>
                    <option value="Medical">Medical</option>
                    <option value="Fuel">Fuel</option>
                    <option value="Telephone">Telephone</option>
                    <option value="Others">Others</option>
                  </select>
                </div></>
              ) : (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Type of reimbursement:</label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleSelectChange}  // Use the same handler for input change
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  >
                    <option value="" disabled>Select Type</option>
                    <option value="Travel">Travel</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Food">Food</option>
                    <option value="Medical">Medical</option>
                    <option value="Fuel">Fuel</option>
                    <option value="Telephone">Telephone</option>
                    <option value="Others">Others</option>
                  </select>
                </div>)}
              {departmentEdit ? (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Expense Date:</label>
                  <input
                    type="date"
                    name="expenseDate"
                    value={formData?.expenseDate}
                    onChange={handleInputChange}
                    max={getCurrentDate()}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  />
                </div>) : (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Expense Date:</label>
                  <input
                    type="date"
                    name="expenseDate"
                    value={formData.expenseDate}
                    onChange={handleInputChange}
                    max={getCurrentDate()}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  />
                </div>)}
              {departmentEdit ? (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Description:</label>
                  <textarea
                    name="description"
                    value={formData?.description}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>) : (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Description:</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
              )}
              {departmentEdit ? (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Amount:</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    name="amount"
                    value={formData?.amount === 0 ? "" : formData.amount}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </div>) : (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Amount:</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    name="amount"
                    value={formData?.amount === 0 ? "" : formData.amount}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </div>
              )}

              {!departmentEdit && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Supporting images or documents:
                  </label>
                  <input
                    type="file"
                    name="supportingDocuments"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png, .pdf"
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
              )}
              <div className="flex justify-center space-x-20 mt-4">
                <div>
                  <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="submit">
                    {departmentEdit ? "Update" : "Submit"}
                  </button>
                </div>
                <div>
                  <button className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => {
                    setDepartmentEdit(false); setOpenModal(false); setFormData({
                      id: NaN,
                      type: '',
                      expenseDate: '',
                      description: '',
                      amount: 0,
                      file: '',
                    });
                  }}>Close</button>
                </div>
              </div>
            </form>
          </Modal>

          <Modal isOpen={OpenLoanModal} onClose={() => {
            setOpenLoanModal(false); setLoanDepartmentEdit(false); setFormLoanData({
              id: NaN,
              loanType: '',
              loanAmount: 0,
              emiMonth: 0,
              interestRate: 0,
              // interestType: '',
              file: ""
            });
          }}>
            <div className="max-w-md mx-auto mt-10 p-6 bg-white border rounded-md shadow-md">
              <h2 className="text-2xl font-semibold mb-6">{LoanDepartmentEdit ? "Edit Loan Application Form" : "Loan Application Form"}</h2>
              <form onSubmit={handleLoanSubmit}>
                {LoanDepartmentEdit ? (<>
                  <div className="mb-4">
                    <label htmlFor="loanType" className="block text-sm font-bold text-black-600">
                      Loan Type:
                    </label>
                    <input
                      type="text"
                      id="loanType"
                      name="loanType"
                      value={formLoanData?.loanType}
                      onChange={handleLoanInputChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      required
                    />
                  </div>  </>) : (
                  <div className="mb-4">
                    <label htmlFor="loanType" className="block text-sm font-bold text-black-600">
                      Loan Type:
                    </label>
                    <input
                      type="text"
                      id="loanType"
                      name="loanType"
                      value={formLoanData.loanType}
                      onChange={handleLoanInputChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      required
                    />
                  </div>
                )}
                {LoanDepartmentEdit ? (<>
                  <div className="mb-4">
                    <label htmlFor="loanAmount" className="block text-sm font-bold text-black-600"> Loan Amount: </label>
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      id="loanAmount"
                      name="loanAmount"
                      value={formLoanData?.loanAmount === 0 ? "" : formLoanData.loanAmount}
                      onChange={handleLoanInputChange}
                      required
                      className="mt-1 p-2 border rounded-md w-full"
                      pattern="[0-9,]*"
                      inputMode="numeric"
                    />
                  </div>
                </>) : (
                  <div className="mb-4">
                    <label htmlFor="loanAmount" className="block text-sm font-bold text-black-600"> Loan Amount: </label>
                    <input
                      type="text"
                      pattern="[0-9,]*"
                      inputMode="numeric"
                      placeholder="Enter Amount"
                      id="loanAmount"
                      name="loanAmount"
                      value={formLoanData?.loanAmount === 0 ? "" : formLoanData.loanAmount}
                      onChange={handleLoanInputChange}
                      required
                      className="mt-1 p-2 border rounded-md w-full"
                    />
                  </div>
                )}
                {/* {LoanDepartmentEdit && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600">
                    Interest Type:
                    <select
                      name="interestType"
                      value={formLoanData.interestType}
                      onChange={handleLoanSelectChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      required
                    >
                      <option value="">Select Interest Type</option>
                      <option value="Fixed">Fixed</option>
                      <option value="Variable">Variable</option>
                      <option value="None">None</option>
                    </select>
                  </label>
                </div>
              )} */}

                {LoanDepartmentEdit && (
                  <div className="mb-4">
                    <label className="block text-sm font-bold text-black-600">
                      Rate of Interest (% per annum):</label>
                    <input
                      type="text"
                      placeholder="Enter Interest Rate"
                      id="interestRate"
                      name="interestRate"
                      value={formLoanData.interestRate === 0 ? "" : formLoanData.interestRate}
                      onChange={handleLoanInputChange}
                      required
                      className="mt-1 p-2 border rounded-md w-full"
                      pattern="[0-9]{1,2}"
                      inputMode="numeric"
                      maxLength={2}
                    />
                  </div>
                )}

                {LoanDepartmentEdit && (
                  <div className="mb-4">
                    <label className="block text-sm font-bold text-black-600">
                      Number of Months: </label>
                    <input
                      type="text"
                      placeholder="Enter EMI Months"
                      id="emiMonth"
                      name="emiMonth"
                      value={formLoanData.emiMonth === 0 ? "" : formLoanData.emiMonth}
                      onChange={handleLoanInputChange}
                      required
                      className="mt-1 p-2 border rounded-md w-full"
                      pattern="[0-9]{1,2}"
                      inputMode="numeric"
                      maxLength={2}
                    />
                  </div>
                )}

                {!LoanDepartmentEdit && (
                  <div className="mb-4">
                    <label className="block text-black-600 text-sm font-bold mb-2">
                      Supporting images or documents:
                    </label>
                    <input
                      type="file"
                      name="supportingDocuments"
                      onChange={handleLoanFile}
                      accept=".jpg, .jpeg, .png, .pdf"
                      className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}

                <div className="flex justify-center space-x-20 mt-4 ">
                  <div >
                    <button
                      type="submit"
                      className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200"
                    >
                      {LoanDepartmentEdit ? "Update" : "Submit"}
                    </button>
                  </div>
                  <div >
                    <button
                      type="submit"
                      className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:border-red-700 focus:ring focus:ring-blue-200"
                      onClick={() => {
                        setOpenLoanModal(false); setLoanDepartmentEdit(false); setFormLoanData({
                          id: NaN,
                          loanType: '',
                          loanAmount: 0,
                          emiMonth: 0,
                          interestRate: 0,
                          // interestType: '',
                          file: ""
                        });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          <Modal isOpen={OpenadSalModal} onClose={() => {
            setOpenadSalModal(false); SetFormAdSalData({
              id: NaN,
              advanceAmount: 0,
              emiAmount: 0,
              reason: '',
              remainigAmount: 0,
              emiMonth: 0
            })
          }}>
            <div className="max-w-md mx-auto mt-10 p-6 bg-white border rounded-md shadow-md">
              <h3 className="text-2xl font-bold mb-4">{AdSalDepartmentEdit ? "Edit Advance Salary" : "Apply for Advance Salary"}</h3>
              <form onSubmit={handleAdSalSubmit} className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
                {AdSalDepartmentEdit ? (<>
                  <div className="mb-4">
                    <label htmlFor="advanceAmount" className="block text-gray-700 text-sm font-bold mb-2">
                      Advance Amount:
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Advance Amount"
                      id="advanceAmount"
                      name="advanceAmount"
                      value={formAdSalData?.advanceAmount === 0 ? "" : formAdSalData?.advanceAmount}
                      onChange={handleAdSalChange}
                      className="border p-2 w-full"
                      required
                      pattern="[0-9,]*"
                      inputMode="numeric"
                    />
                  </div>
                </>) : (
                  <div className="mb-4">
                    <label htmlFor="advanceAmount" className="block text-gray-700 text-sm font-bold mb-2">
                      Advance Amount:
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Advance Amount"
                      id="advanceAmount"
                      name="advanceAmount"
                      value={formAdSalData?.advanceAmount === 0 ? "" : formAdSalData?.advanceAmount}
                      onChange={handleAdSalChange}
                      className="border p-2 w-full"
                      required
                      pattern="[0-9,]*"
                      inputMode="numeric"
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label htmlFor="emiAmount" className="block text-gray-700 text-sm font-bold mb-2">
                    EMI Amount:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter EMI"
                    id="emiAmount"
                    name="emiAmount"
                    value={formAdSalData.emiAmount === 0 ? "" : formAdSalData.emiAmount}
                    onChange={handleAdSalChange}
                    required
                    className="border p-2 w-full"
                    pattern="[0-9,]*"
                    inputMode="numeric"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="reason" className="block text-gray-700 text-sm font-bold mb-2">
                    Reason:
                  </label>
                  <textarea
                    id="reason"
                    name="reason"
                    value={formAdSalData.reason}
                    onChange={handleAdSalChange}
                    className="border p-2 w-full"
                    required
                  />
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-10">
                  {AdSalDepartmentEdit ? "Update" : "Submit"}
                </button>
                <button type="submit" className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  onClick={() => setOpenadSalModal(false)}
                >
                  Close
                </button>
              </form>
            </div>
          </Modal>
          <Modal isOpen={OpenadSalUserModa} onClose={() => {
            setOpenadSalUserModal(false); SetFormAdSalData({
              id: NaN,
              advanceAmount: 0,
              emiAmount: 0,
              reason: '',
              remainigAmount: 0,
              emiMonth: 0
            })
          }} >
            <div className="max-w-md mx-auto mt-10 p-6 bg-white border rounded-md shadow-md">
              <h3 className="text-2xl font-bold mb-4">Edit EMI Amount that you want to pay</h3>

              <form onSubmit={handleAdSalSubmit} className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
                <div className="mb-4">
                  <label htmlFor="advanceAmount" className="block text-gray-700 text-sm font-bold mb-2">
                    Advance Amount:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Advance Amount"
                    id="advanceAmount"
                    name="advanceAmount"
                    value={formAdSalData?.advanceAmount === 0 ? "" : formAdSalData?.advanceAmount}
                    onChange={handleAdSalChange}
                    required
                    className="border p-2 w-full"
                    pattern="[0-9,]*"
                    inputMode="numeric"
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="emiAmount" className="block text-gray-700 text-sm font-bold mb-2">
                    EMI Amount:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter EMI"
                    id="emiAmount"
                    name="emiAmount"
                    value={formAdSalData.emiAmount === 0 ? "" : formAdSalData.emiAmount}
                    onChange={handleAdSalChange}
                    required
                    className="border p-2 w-full"
                    pattern="[0-9,]*"
                    inputMode="numeric"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="reason" className="block text-gray-700 text-sm font-bold mb-2">
                    Reason:
                  </label>
                  <textarea
                    id="reason"
                    name="reason"
                    value={formAdSalData.reason}
                    onChange={handleAdSalChange}
                    className="border p-2 w-full transition bg-white hover:bg-red-200 focus:outline-none"
                    required
                    readOnly
                  />
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-10">
                  Submit
                </button>
                <button type="submit" className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  onClick={() => setOpenadSalUserModal(false)}
                >
                  Close
                </button>
              </form>
            </div>
          </Modal>
          <Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
            <TabList className="flex bg-gray-100 text-black p-4 mb-4 mt-5 rounded-lg shadow-lg">
              <Tab className={`flex-1 text-center ${selectedTab === 0 ? 'bg-gray-400' : ''}`}><b>Pending</b></Tab>
              <Tab className={`flex-1 text-center ${selectedTab === 1 ? 'bg-gray-400' : ''}`}><b>Approved</b></Tab>
            </TabList>
            <TabPanel>
              {selectedTab === 0 && (
                <div >
                  <div className="mt-4 bg-gray-100 p-5 rounded-md shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Pending Data Of Reimbursement</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className=" max-w-max divide-x rounded-md shadow-md dark:divide-gray-400 overflow-hidden">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider"></th>)}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Date</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Payment Status</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Reason</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Type</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Document</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Edit</th>
                            )}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Delete</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                          {
                            getData
                              .filter((data: any) => data.status === "Pending")
                              .length > 0 ? (
                              getData.map((data: any, index: number) => (
                                data.status === "Pending" && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    {props.sidebarAccess.some(menu => menu.access === 'profile') && (
                                      <td className="text-center  p-2">
                                        <input
                                          type="checkbox"
                                          onChange={() => toggleCheckbox(data.id, data.userId, data.expenseDate, data.type, data.amount)}
                                          checked={isCheckboxChecked(data.id, data.userId, data.expenseDate, data.type, data.amount)}
                                          className="w-4 h-4 mr-4"
                                        />
                                      </td>)}
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2">{data.expenseDate}</td>
                                    <td className="text-center  p-2">{data.paidStatus}</td>
                                    <td className="text-center  p-2">{data.description}</td>
                                    <td className="text-center p-2">{formatNumberWithCommas(data.amount)}</td>
                                    <td className="text-center  p-2">{data.type}</td>
                                    {/* <td className="text-center  p-2"><FaEye className='fill-red-600 ...' onClick={() => handleView(data?.file, data?.fileUrl, index)} /></td> */}
                                    <td className="p-2">
                                      {data?.fileUrl ? (
                                        <div className="flex justify-center">
                                          <a href={data?.fileUrl} target="-blank" rel="noopener noreferrer">
                                            <AiOutlineEye />
                                          </a>
                                        </div>
                                      ) : null}
                                    </td>
                                    <td className="text-center  p-2">{data.status}</td>
                                    {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                                      <td className="text-center  p-2">
                                        <div className="flex justify-center">
                                          <FaPenAlt className='fill-red-600 ...' onClick={() => { setOpenModal(true); handleEditDept(data.id, data) }} />
                                        </div>
                                      </td>
                                    )}
                                    <td className="text-center  p-2">
                                      <div className="flex justify-center">
                                        <FaTrashAlt className='fill-red-600 ...' onClick={() => deleteData(data.id)} />
                                      </div>
                                    </td>
                                  </tr>

                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={11} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                    {(props.sidebarAccess.some(menu => menu.access === 'profile') && getData
                      .filter((data: any) => data.status === "Pending")
                      .length > 0 && (
                        <div className="text-center">
                          <div className="flex justify-left space-x-20 mt-4">
                            <button
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              onClick={handleApproveClick}
                              type="submit"
                            >
                              APPROVED
                            </button>
                            <button
                              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                              onClick={handleRejectClick}
                            >
                              REJECT
                            </button>
                          </div>
                        </div>)
                    )}

                  </div>
                  <br />
                  <div className="mt-4 rounded-md shadow-lg bg-gray-100 p-5">
                    <h2 className="text-2xl font-bold mb-4">Pending Data Of Loan</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className="w-full dark:divide-gray-400 rounded-md shadow-lg overflow-hidden">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider"></th>)}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Loan Type</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Loan Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Interest Rate</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Interest Type</th> */}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Document</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Month</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Edit</th>
                            )}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Delete</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100 text-center">
                          {
                            getLoansData
                              .filter((data: any) => data.status === "Pending")
                              .length > 0 ? (
                              getLoansData.map((data: any, index: number) => (
                                data.status === "Pending" && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    {props.sidebarAccess.some(menu => menu.access === 'profile') && (
                                      <td className="text-center  p-2">
                                        <input
                                          type="radio"
                                          onChange={(event) => handleRadioChange(event, data.id, data.userId, data.emiAmount, data.emiMonth, data.interestRate, data.interestType, data.loanAmount)}
                                          checked={isRadioChecked(data.id, data.userId, data.emiAmount, data.emiMonth, data.interestRate, data.interestType, data.loanAmount)}
                                          className="w-4 h-4 mr-4"
                                        />
                                      </td>)}
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2"> {data ? data.loanType : "NA"}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.emiAmount ? data.emiAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data ? data.loanAmount : "NA")}</td>
                                    <td className="text-center  p-2">{data && data.interestRate ? data.interestRate : "NA"}</td>
                                    <td className=" p-2">
                                      {data?.fileUrl ? (
                                        <div className="flex justify-center">
                                          <a href={data?.fileUrl} target="-blank" rel="noopener noreferrer">
                                            <AiOutlineEye />
                                          </a>
                                        </div>
                                      ) : null}
                                    </td>
                                    <td className="text-center  p-2">{data && data.emiMonth ? data.emiMonth + " Month" : "NA"}</td>
                                    <td className="text-center  p-2">{data.status}</td>
                                    {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                                      <td className="text-center  p-2">
                                        <div className="flex justify-center">
                                          <FaPenAlt className='fill-red-600 ...' onClick={() => { setOpenLoanModal(true); handleEditLoan(data.id, data) }} />
                                        </div>
                                      </td>
                                    )}
                                    <td className="text-center  p-2">
                                      <div className="flex justify-center">
                                        <FaTrashAlt className='fill-red-600 ...' onClick={() => deleteLoanData(data.id)} />
                                      </div>
                                    </td>
                                  </tr>

                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={11} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                    {(props.sidebarAccess.some(menu => menu.access === 'profile') && getLoansData
                      .filter((data: any) => data.status === "Pending")
                      .length > 0 && (
                        <div className="bg-gray-100 divide-y divide-gray-200 text-center">
                          <div className="flex justify-left space-x-20 mt-4">
                            <button
                              className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              onClick={handleLoanApproveClick}
                              type="submit"
                            >
                              APPROVED
                            </button>
                            <button
                              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                              onClick={handleLoanRejectClick}
                            >
                              REJECT
                            </button>
                          </div>
                        </div>)
                    )}
                  </div>
                  <br />
                  <div className="mt-4 bg-gray-100 p-5 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Pending Data Of Advance Salary</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className="w-full dark:divide-gray-400 rounded-md shadow-lg overflow-hidden">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider"></th>)}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Advance Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Reason</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Payment Status</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Edit</th>
                            )}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Delete</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                          {
                            getAdSalData && getAdSalData.length > 0 ? (
                              getAdSalData.map((data: any, index: number) => (
                                data.status === "Pending" && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    {props.sidebarAccess.some(menu => menu.access === 'profile') && (
                                      <td className="text-center  p-2">
                                        <input
                                          type="radio"
                                          onChange={(event) => handleAdSalRadioChange(event, data.id, data.userId, data.emiAmount, data.advanceAmount)}
                                          checked={isRadioAdSalChecked(data.id, data.userId, data.emiAmount, data.advanceAmount)}
                                          className="w-4 h-4 mr-4"
                                        />
                                      </td>)}
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2"> {formatNumberWithCommas(data && data.advanceAmount ? data.advanceAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.emiAmount ? data.emiAmount : "NA")}</td>
                                    <td className="text-center  p-2">{data && data.reason ? data.reason : "NA"}</td>
                                    <td className="text-center  p-2">{data && data.paidStatus ? data.paidStatus : "NA"}</td>
                                    <td className="text-center  p-2">{data.status}</td>
                                    {(props.sidebarAccess.some(menu => menu.access === 'profile') &&
                                      <td className="text-center  p-2">
                                        <FaPenAlt className='fill-red-600 ...' onClick={() => { setOpenadSalModal(true); handleEditAdSal(data.id, data) }} />
                                      </td>
                                    )}
                                    <td className="text-center  p-2">
                                      <div className="flex justify-center">
                                        <FaTrashAlt className='fill-red-600 ...' onClick={() => deleteAdSalData(data.id)} />
                                      </div>
                                    </td>
                                  </tr>

                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={9} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                    {(props.sidebarAccess.some(menu => menu.access === 'profile') && getAdSalData
                      .filter((data: any) => data.status === "Pending")
                      .length > 0 && (
                        <div className="bg-gray-100 divide-y divide-gray-200 text-center">
                          <div className="flex justify-left space-x-20 mt-4">
                            <button
                              className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              onClick={handleAdSalApproveClick}
                              type="submit"
                            >
                              APPROVED
                            </button>
                            <button
                              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                              onClick={handleAdSalRejectClick}
                            >
                              REJECT
                            </button>
                          </div>
                        </div>)
                    )}
                  </div>
                </div>

              )}
            </TabPanel>

            <TabPanel>
              {selectedTab === 1 && (
                <div>
                  <div className="mt-4 bg-gray-100 p-5 rounded-md shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Approved Data Of Reimbursement</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className="w-full dark:divide-gray-400 rounded-md shadow-lg overflow-hidden">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Date</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Payment Satus</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Reason</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Type</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Document</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Edit</th>
        <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Delete</th> */}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                          {
                            getData
                              .filter((data: any) => data.status === "Approved" || data.status === "Rejected")
                              .length > 0 ? (
                              getData.map((data: any, index: number) => (
                                (data.status === "Approved" || data.status === "Rejected") && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2">{data.expenseDate}</td>
                                    <td className="text-center  p-2">{data.paidStatus}</td>
                                    <td className="text-center  p-2">{data.description}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data.amount)}</td>
                                    <td className="text-center  p-2">{data.type}</td>
                                    <td className="p-2">
                                      {data?.fileUrl ? (
                                        <div className="flex justify-center">
                                          <a href={data?.fileUrl} target="-blank" rel="noopener noreferrer">
                                            <AiOutlineEye />
                                          </a>
                                        </div>
                                      ) : null}
                                    </td>
                                    <td className="text-center  p-2">{data.status}</td>
                                    {/* <td className="text-center  p-2">
            <FaPenAlt className='fill-red-600 ...' onClick={() => { setOpenModal(true); handleEditDept(data.id, data) }} />
          </td>
          <td className="text-center  p-2">
            <FaTrashAlt className='fill-red-600 ...' onClick={() => deleteData(data.id)} />
          </td> */}
                                  </tr>
                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div className="mt-4 bg-gray-100 p-5 rounded-md shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Approved Data of Loan</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className="w-full dark:divide-gray-400">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Loan Type</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Loan Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Balance Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Interest Rate</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Interest Type</th> */}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Remaining EMI Month</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Document</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Edit</th>
        <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Delete</th> */}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                          {
                            getLoansData
                              .filter((data: any) => data.status === "Approved" || data.status === "Rejected")
                              .length > 0 ? (
                              getLoansData.map((data: any, index: number) => (
                                (data.status === "Approved" || data.status === "Rejected") && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2">{data && data.loanType ? data.loanType : "NA"}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.emiAmount !== null ? data.emiAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.loanAmount ? data.loanAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.remainingAmount !== null ? data.remainingAmount : "NA")}</td>
                                    <td className="text-center  p-2">{data && data.interestRate ? data.interestRate : "NA"}</td>
                                    {/* <td className="text-center  p-2">{data && data.interestType ? data.interestType : "NA"}</td> */}
                                    <td className="text-center  p-2">{data && data.remainingMonths !== null ? data.remainingMonths : "NA"}</td>
                                    <td className="p-2">
                                      {data?.fileUrl ? (
                                        <div className="flex justify-center">
                                          <a href={data?.fileUrl} target="-blank" rel="noopener noreferrer">
                                            <AiOutlineEye />
                                          </a>
                                        </div>
                                      ) : null}
                                    </td>
                                    <td className="text-center  p-2">{data.status}</td>
                                  </tr>
                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div className="mt-4 bg-gray-100 p-5 rounded-md shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Approved Data of AdvanceSalary</h2>
                    <div className='overflow-auto rounded-md shadow-md'>
                      <table className="w-full dark:divide-gray-400 rounded-md shadow-lg overflow-hidden">
                        <thead className="dark:bg-gray-800 dark:border-gray-400">
                          <tr>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">ID</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Advance Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Balance Amount</th>
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider"> Complete Months</th>
                            {getAdSalData.some(data => 'status' in data && data.status === "Approved" && 'activeStatus' in data && data.activeStatus === "Active") && (
                              <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                Edit
                              </th>
                            )}

                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Paid Status</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Emi Month</th> */}
                            <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                            {/* <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Edit</th>
        <th className="text-center px-4 sm:px-6 py-3 bg-gray-700 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-gray-300 dark:text-gray-100 uppercase tracking-wider">Delete</th> */}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-center">
                          {
                            getAdSalData
                              .filter((data: any) => data.status === "Approved" || data.status === "Rejected")
                              .length > 0 ? (
                              getAdSalData.map((data: any, index: number) => (
                                (data.status === "Approved" || data.status === "Rejected") && (
                                  <tr key={index} className="divide-x divide-gray-300">
                                    <td className="text-center  p-2">R-{data.id}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.advanceAmount ? data.advanceAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.emiAmount ? data.emiAmount : "NA")}</td>
                                    <td className="text-center  p-2">{formatNumberWithCommas(data && data.remainingAmount !== null ? data.remainingAmount : "NA")}</td>
                                    <td className="text-center  p-2">{data && data.emiMonth ? data.emiMonth : "NA"}</td>
                                    {(data.status === "Approved" && data.activeStatus === "Active" && <td className="text-center  p-2"><div className="flex justify-center"><FaPenAlt className='fill-red-600 ...' onClick={() => { setOpenadSalUserModal(true); handleEditAdSalUser(data.id, data) }} /></div></td>)}
                                    <td className="text-center  p-2">{data && data.paidStatus ? data.paidStatus : "NA"}</td>
                                    {/* <td className="text-center  p-2">{data && data.emiMonth ? data.emiMonth : "NA"}</td> */}
                                    <td className="text-center  p-2">{data.status}</td>
                                  </tr>
                                )
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="text-center  p-2" >No data available</td>
                              </tr>
                            )
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
          </Tabs>

          {/* {modalOpen && (
          <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <div className="relative z-50 bg-transparent">
              <div className="relative flex flex-col items-center mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-xl">
                {selectedFile.fileUrl && (
                  <img src={selectedFile.fileUrl} alt={`Uploaded File`} className="max-w-lg" />
                )}
                <div className="flex justify-center mt-6">
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="px-4 py-2 bg-gray-200 text-gray-800 font-semibold rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}
        </div>
      </Main>
      <RightBar>
        { userid === undefined && (
          <div>
            <div className=' flex justify-center' >
              <Button disabled={btnDisable} onClick={() => { setOpenModal(true); setBtnDisable(false) }} >
                Apply For Reimbursement
              </Button>
            </div>
            <div className=' flex justify-center'>
              <Button disabled={btnDisable} onClick={() => { setOpenLoanModal(true); setBtnDisable(false) }} >
                Apply For Loan
              </Button>
            </div>
            <div className=' flex justify-center'>
              <Button disabled={btnDisable} onClick={() => {
                setOpenadSalModal(true); setAdSalDepartmentEdit(false); sethandleEditAdSalUser(false)
                setBtnDisable(false)
              }} >
                Apply For Advance Salary
              </Button>
            </div>
          </div>)}

      </RightBar>
    </Page >
  );
}
