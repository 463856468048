import React, { useState, ReactNode } from 'react';

interface PopoverProps {
  color?:string;
  content?: string;
  children?: ReactNode; // Use ReactNode to accept any valid React children
}

const Popover: React.FC<PopoverProps> = ({color, content ,children}) => {
  const [showPopover, setShowPopover] = useState(false);


  const handleMouseEnter = () => {
    setShowPopover(true);
  };
  const handleMouseLeave = () => {
    setShowPopover(false);
  };
  
  return (
    <div className="relative inline-block " >
    <div
      className="cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showPopover && content ? (
          <div className={`${color} p-4 text-gray-800 rounded-lg `}>
          <div className="grid auto-cols-max">
            {content.split("\n").map((line, index) => (
            <div key={index} className="min-w-1/4 font-medium p-2">
              {line}
            </div>
          ))}
          </div>
        </div>
      ):
      <div >{children}</div>}
    </div>
    </div>
  );
};

export default Popover;
