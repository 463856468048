/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface LeaveProps {
  userData: any
  userRole: string,
  isOwnAttendance: boolean,
  sidebarAccess: RoleAccess[];
  leaveRequestData: any
  BalancedLeave: any
  setleaveRequestData: (data: any) => void
  // setleaveRequestDatas:(data:any) => void
  setLeaveRender: (data: any) => void
  leaveRender: any

}
interface RoleAccess {
  access: string;
}


const ViewReqPendLeave = (props: LeaveProps) => {
  const [selectedData, setSelectedData] = useState<{ date: string, leaveType: string, halfDayType:string }[]>([]);
  const [BalancedLeave, setBalancedLeave] = useState<any>([]);
  // const [leaveRequestData, setleaveRequestData] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);

  const { approvedRejectLeave, getRequestedLeave, getBalancedLeave } = LeaveAttendanceApi();
  const { userid } = useParams<{ userid: string }>();
  const userId = parseInt(userid)

  useEffect(() => {
    getPendingLeaveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.leaveRender]);
  useEffect(() => {
    getLeaveBalanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.BalancedLeave]);
  const userIdAsString: string = `${props.userData.id}`;
  const getPendingLeaveData = async () => {
    try {
      const response = await getRequestedLeave(userId);

      props.setleaveRequestData(response.data.data);

      // props.setleaveRequestDatas(response.data.status);

    } catch (error) {
    }
  };
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const data = props.leaveRequestData.reduce((acc: any, leave: any) => {
      return acc.concat(
        leave.multipleLeave.map((m: any) => ({
          date: m.date,
          leaveType: m.leavetype,
          halfDayType: m.halfDayType,
          selected: newSelectAll  // Changed 'state' to 'selected' for consistency
        }))
      );
    }, []);

    setSelectedData(newSelectAll ? data : []); // Update selectedData based on the newSelectAll state
  };
  const getLeaveBalanceData = async () => {
    try {
      const data = await getBalancedLeave(userId);
      setBalancedLeave(data.data.data);
    } catch (error) {
    }
  };
  const toggleCheckbox = (event: any, date: string, leaveType: string, halfDayType: string) => {
    const isChecked = event.target.checked;
  
    if (isChecked) {
      setSelectedData([...selectedData, { date: date, leaveType: leaveType, halfDayType: halfDayType }]);
    } else {
      // Remove the deselected checkbox from the selectedData array
      const updatedSelectedData = selectedData.filter(
        (data) => !(data.date === date && data.leaveType === leaveType && data.halfDayType === halfDayType)
      );
      setSelectedData(updatedSelectedData);
    }
  
    // Check if all individual checkboxes are checked
    const allChecked = props.leaveRequestData.every((leave: any) =>
      leave.multipleLeave.every((m: any) =>
        selectedData.some((data) => data.date === m.date && data.leaveType === m.leavetype && data.halfDayType === m.halfDayType)
      )
    );
  
    // Update the state of the "Select All" checkbox
    setSelectAll(allChecked);
  };
  

  const handleApproveClick = async () => {
    try {
      const response = await approvedRejectLeave(selectedData, 'Approved', props.userData.id);
      if (response.status === 200) {
        toast.success("Leave Approved Successfully");
        getPendingLeaveData();
        getLeaveBalanceData();
        setSelectedData([]);
        props.setLeaveRender(response.data.data);
        setSelectAll(false)
      }
    } catch (error) {
    }
  };
  const isCheckboxChecked = (date: string, leaveType: string, halfDayType:string) => {
    return selectedData.some((data) => data.date === date && data.leaveType === leaveType && data.halfDayType === halfDayType);
  };
  function formatDate(dateString: string) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    const dayOfWeek = date.toLocaleDateString(undefined, { weekday: 'long' });

    return `${formattedDate} (${dayOfWeek})`;
  }

  const handleRejectClick = async () => {
    try {
      const response = await approvedRejectLeave(selectedData, 'Reject', props.userData.id);
      if (response.status === 200) {

        toast.success("Leave Reject Successfully");
        getPendingLeaveData();
        getLeaveBalanceData(); // Refresh the leave requests after approval
        setSelectedData([]);
        props.setLeaveRender(response.data.data);
        setSelectAll(false)
      }
    } catch (error) {
    }
  };

  return (
    <div className='w-full bg-white mt-5 p-5 rounded-xl shadow-lg'>

      {!props.isOwnAttendance && (props.sidebarAccess.some(menu => menu.access === 'approvals')) &&
        <div>
          <div>
            <h1 className="font-bold mb-5 text-left text-slate-800 text-2xl">Open Leave Request </h1>
            <div className='overflow-auto'>
              <table className="w-full dark:divide-gray-400">
                <thead className="dark:bg-gray-800 dark:border-gray-400">
                  <tr>
                    <th className="p-3 bg-green-800">
                      {/* <div className="flex items-center"> */}
                        <input checked={selectAll} type="checkbox" onChange={() => handleSelectAll()} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        {/* <label className="sr-only">checkbox</label> */}
                      {/* </div> */}
                    </th>
                    <th className="text-center p-3 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Leave Type</th>
                    <th className="text-center p-3 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Date</th>
                    <th className="text-center p-3 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Reason</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-x divide-gray-200 text-center">
                  {/* {leaveRequestData.length > 0 ? ( */}
                  {props.leaveRequestData.map((leave: any, index: number) => (
                    leave.multipleLeave.map((m: any, i: number) => (
                      <tr key={i}>
                        <td className="text-center border border-gray-400 p-2">
                          <input
                            type="checkbox"
                            onChange={(event) => toggleCheckbox(event, m.date, m.leavetype, m.halfDayType)}
                            checked={selectAll || isCheckboxChecked(m.date, m.leavetype, m.halfDayType)}
                            className="w-4 h-4"
                          />
                        </td>
                        <td className="text-center border border-gray-400 p-2">{m.leavetype}</td>
                        <td className="text-center border border-gray-400 p-2">{m.date}</td>
                        <td className="text-center border border-gray-400 p-2">{m.reason}</td>
                      </tr>
                    ))
                  ))
                    // ) : (
                    //   <tr>
                    //     <td  className="text-center p-4">No data available</td>
                    //   </tr>
                    // )
                  }
                </tbody>
              </table>
            </div>

            <div className="text-center border border-gray-400">
              {props.leaveRequestData.some((leave: any) => leave.multipleLeave.length > 0) && (
                <div className="flex justify-left space-x-3 m-4">
                  <button
                    className="bg-blue-600 hover:bg-blue-800 text-sm text-white px-3 py-2 m-1 rounded mt-3 w-20"
                    onClick={handleApproveClick}
                    type="submit"
                  >
                    Approved
                  </button>
                  <button
                    className="bg-red-600 hover:bg-red-800 text-sm text-white px-3 py-2 m-1 rounded mt-3 w-20"
                    onClick={handleRejectClick}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          </div>


        </div>

      }
      <br />
      <div className="text-left">
        <h2 className="text-left text-slate-800 font-bold text-2xl mb-4">Leave Balance </h2>
        <div className='overflow-auto'>
          <table className="w-full divide-x dark:divide-gray-400 rounded-lg overflow-hidden">
            <thead className="dark:bg-gray-800 ">
              <tr >
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Leave Type</th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Balance</th>
                <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Total</th>
              </tr>
            </thead>
            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
              {BalancedLeave.length > 0 ? (
                BalancedLeave.map((leave: any, index: number) => (
                  <tr key={index} className="divide-x divide-gray-300">
                    <td className="text-center p-2">{leave.leaveType}</td>
                    <td className="text-center p-2">{leave.defaultValueLeaveBalance}</td>
                    <td className="text-center p-2">{leave.defaultValueLeave}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-2">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>

          </table>

        </div>
      </div>
      <br />
      {props.isOwnAttendance && (props.sidebarAccess.some(menu => menu.access === 'request')) &&
        <div className="text-left">
          <h2 className="text-left text-slate-800 font-bold text-2xl mb-4">Leave Request</h2>
          <div className='overflow-auto'>
            <table className="w-full  rounded-lg overflow-hidden dark:divide-gray-400">
              <thead className="dark:bg-gray-800 dark:border-gray-400">
                <tr>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Date</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Leave Type</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Remarks</th>
                  <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">Status</th>
                </tr>
              </thead>
              <tbody className="bg-gray-100 divide-y divide-gray-200 text-center">
                {props.leaveRequestData.map((leave: any, index: number) => (
                  leave.multipleLeave.map((m: any, i: number) => (
                    <tr key={i} className="divide-x divide-gray-300">

                      <td className="text-center p-2">{m.date}</td>
                      <td className="text-center p-2">{m.leavetype}</td>
                      <td className="text-center p-2">{m.reason}</td>
                      <td className="text-center p-2">{m.approved === "0" ? 'Pending' : m.approved}</td>
                    </tr>
                  ))
                ))
                }
              </tbody>

            </table>

          </div>
        </div>}
    </div>
  )
}

export default ViewReqPendLeave;