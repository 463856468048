import React, { useEffect, useState } from "react";
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { toast } from "react-toastify";

interface LeaveProps {
  setApplyOpenLeave: (data: any) => void;
  setBalancedLeave: (data: any) => void;
  setleaveRequestData: (data: any) => void;
}
interface LeaveData {
  leaveType: string;
  startDate: string;
  endDate: string;
  reason: string;
  halfDayType: string;
}
const ApplyLeave = (props: LeaveProps) => {
  const initialLeaveData: LeaveData = {
    leaveType: '',
    startDate: '',
    endDate: '',
    reason: '',
    halfDayType: '',
  };
  const [appliedLeaveData, setappliedLeaveData] = useState<LeaveData>(initialLeaveData); // Initialize with initialLeaveData
  const { applyForLeave, getAddLeave } = LeaveAttendanceApi()


  const [validationErrors, setValidationErrors] = useState<Partial<LeaveData>>({});
  const [leave, setleaves] = useState<any>([]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // const { name, value } = event.target;
    // setappliedLeaveData({ ...appliedLeaveData, [name]: value });
    // validateForm();
    const { name, value } = event.target;
    let errors = { ...validationErrors };

    if (name === 'startDate' || name === 'endDate') {
      const currentDate = new Date().toISOString().split('T')[0];
      if (value < currentDate) {
        errors[name] = 'Date cannot be before the current date';
      } else {
        errors[name] = '';
      }

      if (name === 'startDate' && appliedLeaveData.endDate !== '' && value > appliedLeaveData.endDate) {
        errors['startDate'] = 'Start date cannot be after end date';
      } else if (name === 'endDate' && appliedLeaveData.startDate !== '' && value < appliedLeaveData.startDate) {
        errors['endDate'] = 'End date cannot be before start date';
      }
    }

    setValidationErrors(errors);
    const newEndDate = appliedLeaveData.leaveType === "Half Day Leave" ? appliedLeaveData.startDate : "";
    setappliedLeaveData({
      ...appliedLeaveData,
      [name]: value,
      endDate: newEndDate,
    });
    setappliedLeaveData({ ...appliedLeaveData, [name]: value });

  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLeaveTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const newEndDate = value === "Half Day Leave" ? appliedLeaveData.startDate : appliedLeaveData.endDate;
    setappliedLeaveData({
      ...appliedLeaveData,
      [name]: value,
      endDate: newEndDate,
    });
  };
  const handleHalfDayTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    let newEndDate = "";
    if (value === "First Half" || value === "Second Half") {
      newEndDate = appliedLeaveData.startDate;
    }
    setappliedLeaveData({ ...appliedLeaveData, [name]: value, endDate: newEndDate });
  };


  const getData = async () => {
    const response = await getAddLeave();
    setleaves(response.data.data);
  };

  const validateForm = (): boolean => {
    const errors: Partial<LeaveData> = {};


    //validation rules here
    if (appliedLeaveData.leaveType === '') {
      errors.leaveType = 'Leave Type is required';
    }
    if (appliedLeaveData.startDate === '') {
      errors.startDate = 'Start date is required';
    }

    if (appliedLeaveData.endDate === '') {
      errors.endDate = 'End date is required';
    }

    if (appliedLeaveData.reason === '') {
      errors.reason = 'Reason is required';
    }
    if (appliedLeaveData.leaveType === 'Half Day Leave' && appliedLeaveData.halfDayType === '') {
      errors['halfDayType'] = 'Please select a half day type';
    }
    // Update the validation errors state
    setValidationErrors(errors);

    // Return true if there are no validation errors
    return Object.keys(errors).length === 0;
  };
  const currentDate = new Date().toISOString().split('T')[0];

  const handleSubmit = async (event: React.FormEvent) => {

    event.preventDefault();
    if (validateForm()) {

      try {
        let errors: Record<string, string> = {};

        if (appliedLeaveData.startDate === '') {
          errors['startDate'] = 'Please select a start date';
        }

        if (appliedLeaveData.endDate === '') {
          errors['endDate'] = 'Please select an end date';
        }

        // Check if start date is before end date
        if (appliedLeaveData.startDate > appliedLeaveData.endDate) {
          errors['startDate'] = 'Start date cannot be after end date';
          errors['endDate'] = 'End date cannot be before start date';
        }
        if (appliedLeaveData.leaveType === 'Half Day Leave' && appliedLeaveData.halfDayType === '') {

          errors['halfDayType'] = 'Please select a half day type';
        }
        setValidationErrors(errors);

        // If there are any errors, prevent submission
        if (Object.keys(errors).length > 0) {
          toast.error("Kindly check Data before Submit");
          return;
        }
        const currentDateFor = new Date();

        // Get the current hour
        const currentHour = currentDateFor.getHours();
        const currentDate = new Date().toISOString().split('T')[0];
        // Validate if current time is after 1:00 PM and leave type is not "half day leave"
        if (currentHour >= 8 && !appliedLeaveData.leaveType.includes("Half") && appliedLeaveData.startDate === currentDate) {
          // Throw an error if conditions are met
          toast.error("Invalid leave request: Only half day leave is allowed after 08:00 AM.");
          return;
        }

        // You can submit the form data here
        const response = await applyForLeave(appliedLeaveData);
        // eslint-disable-next-line array-callback-return
        response.data.data.map((message: any) => {
          if (message.status === 400) {
            toast.error(message.message);
          }
          else {
            toast.success(message.message);
          }
          setTimeout(function () {
            props.setApplyOpenLeave(false);

          }, 100)
        })
        props.setBalancedLeave(response.status);
        props.setleaveRequestData(response.data.data);
        // }

        setappliedLeaveData(initialLeaveData);
        setValidationErrors({});
        // }

      } catch (error) {
        // Handle any errors
        console.error('Error:', error);
      }
    };
  }

  return (
    <div className="modal-content py-5 px-6 bg-white rounded-lg" >
      <div className=' relative block shadow-md rounded-lg w-full dark:divide-gray-400'>
        <form onSubmit={handleSubmit} >
          <div className=' relative block rounded-lg shadow-lg w-full dark:divide-gray-400'>
            <h2 className="text-xl font-bold text-center py-4 px-6 text-black">Leave Management Form</h2><br />
            <label className=" text-black block text-left mb-2 text-xl font-medium  dark:text-white" htmlFor="leaveType"><b>Leave Type:</b></label>
            <select id="leaveType" className="bg-gray-50 border border-gray-300 px-4 py-2 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-black" name="leaveType" value={appliedLeaveData.leaveType} onChange={handleLeaveTypeChange}>
              <option value="" disabled>Select Leave Type</option>
              {leave.filter((leaveItem: any) => leaveItem.status).map((leaveItem: any) => (
                <option key={leaveItem.id} value={leaveItem.leaveType}>
                  {leaveItem.leaveType}
                </option>
              ))}

            </select>
            {validationErrors.leaveType && (
              <span className="error">{validationErrors.leaveType}</span>
            )}
          </div><br />
          <div>
            <label className=" text-left block mb-2 text-xl font-medium text-black dark:text-white" htmlFor="startDate"><b>Start Date:</b></label>
            <input className="bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="date" id="startDate" name="startDate" value={appliedLeaveData.startDate} onChange={handleInputChange} min={currentDate} />
            {validationErrors.startDate && (<span className="error">{validationErrors.startDate}</span>
            )}
          </div><br />
          {appliedLeaveData.leaveType === 'Half Day Leave' || appliedLeaveData.leaveType.includes('Half') ? (
            <div>
              <label className="text-left block mb-2 text-xl font-medium text-black dark:text-white" htmlFor="halfDayType"><b>Half Day Type:</b></label>
              <select id="halfDayType" className="bg-gray-50 border border-gray-300 px-4 py-2 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-black" name="halfDayType" value={appliedLeaveData.halfDayType} onChange={handleHalfDayTypeChange}>
                <option value="" disabled>Select Value</option>
                <option value="First Half">First Half</option>
                <option value="Second Half">Second Half</option>
              </select>
              {validationErrors.halfDayType && (<span className="error">{validationErrors.halfDayType}</span>)}
            </div>
          ) : (
            <div>
              <label className="text-left block mb-2 text-xl font-medium text-black dark:text-white" htmlFor="endDate"><b>End Date:</b></label>
              <input className="bg-gray-50 border px-4 py-2 border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="date" id="endDate" name="endDate" value={appliedLeaveData.endDate} onChange={handleInputChange} min={currentDate} />
              {validationErrors.endDate && (<span className="error">{validationErrors.endDate}</span>)}
            </div>
          )}
          <br /><br />
          <div>
            <label className=" text-left block mb-2 text-xl font-medium text-black dark:text-white" htmlFor="reason"><b>Reason:</b></label>
            <textarea className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="reason" name="reason" value={appliedLeaveData.reason} onChange={handleInputChange} />
            {validationErrors.reason && (<span className="error">{validationErrors.reason}</span>
            )}
          </div><br />
          <div className="flex justify-center space-x-20 mt-4">
            <div>
              <button className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="submit">Submit</button>
            </div>
            <div>
              <button className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => props.setApplyOpenLeave(false)} >Close</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  );
}

export default ApplyLeave;
