import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { UsersApi } from "../../../services/users";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination"
interface SearchPeople {
  firstName: string;
  email: string;
}

interface Employee {
  id: number;
  employeeCode: string;
  natureOfEmployment: string;
  departmentName: string;
  managerName: string;
  employeeName: string;
  email: string;
  mobile: number;
  designation: string;
  location: string;
  date: string;
  typeOfEmployee: string;
}

// interface urls {
//   file:string;
// }

const People = () => {
  const { fetchusers, fetchDataall, fetchDataemploye, fetchurl, fetchDatacontarctors } = UsersApi();
  const [isFourthForm] = useState(true);
  const [currentItems, setCurrentItems] = useState<Employee[]>([]);
  const [isPeople, setPeople] = useState<Employee[]>([]);
  const [url, setUrl] = useState<string>('');
  const run = useRef(false);

  const initalValues: SearchPeople = {
    firstName: "",
    email: ""
  };
  const [filterInput, setFilterInput] = useState<SearchPeople>(initalValues);
  const [filterData, setFilterData] = useState<Employee[]>([]);


  useEffect(() => {
    if (run.current) return;
    run.current = true;
    People();
    urls();
  });


  const People = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res: any = await fetchusers();
    setPeople(res);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const contractor = async () => {
    const res: any = await fetchDatacontarctors();
    setPeople(res)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const employee = async () => {
    const res: any = await fetchDataemploye();
    setPeople(res)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allData = async () => {
    const res: any = await fetchDataall();
    setPeople(res)
  }

  useEffect(() => {
    filteredData(filterInput.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPeople]);


  const urls = async () => {
    const res: { data: { logoUrl: string } } = await fetchurl();
    setUrl(res.data.logoUrl);
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFilterInput({ ...filterInput, [name]: value });
    filteredData(value);
  }
  const filteredData = (email: string) => {
    const temp = isPeople.filter(item => item?.email?.toLowerCase().includes(email?.toLowerCase()));
    setFilterData(temp);
  };


  return (
    <div className="w-full p-4 bg-white mt-5 rounded-xl shadow-lg ">
      <div >
        {isFourthForm && (
          <div className="flex w-full">
            <form className="lg: h-[700px] w-full bg-slate-300  flex flex-col">
              {/* <h1 className="text-left">People</h1> */}
              <div className="w-full bg-[#f0f0f0] flex flex-row mx-auto my-0 p-5 border-2 border-solid border-[#ccc]">
                <div className="flex justify-center items-center">
                  {url && <img src={url} alt="Uploaded" className="w-full bg-transparent object-contain max-h-24" />}
                </div>
                {/* <div className="flex flex-col justify-center items-center ml-5">
                  <p className="text-lg font-semibold">EDULAB EDUCATIONAL EXCHANGE PVT LTD.</p>
                  Add your text content here
                </div> */}
              </div>
              <div >
                <input
                  className="w-full mb-2.5 p-2 max-w-[650px] border text-[17px] mt-2.5 px-2.5 py-[5px] rounded-[5px] border-solid border-black"
                  type="text"
                  name="email"
                  placeholder="Search by name or email"
                  value={filterInput.email}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  className="bg-[#0074e4] text-[white] cursor-pointer px-5 py-2.5 rounded-sm border-[none] hover:bg-[#0056b3] ml-2"
                  onClick={People}
                >
                  Search
                </button>
              </div>
              <br />
              {/* <div className="text-center">
                <u>
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 rounded"
                    onClick={allData}
                  >
                    ALL
                  </button>
                  <span style={{ margin: "0 10px" }}></span>
                  <button
                    type="button"
                    className="bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
                    onClick={employee}
                  >
                    EMPLOYEE
                  </button>
                  <span style={{ margin: "0 10px" }}></span>
                  <button
                    type="button"
                    className="bg-orange-500 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded"
                    onClick={contractor}
                  >
                    CONTRACTORS
                  </button>
                </u>
              </div> */}
              <br />
              <div className="p-1 bg-white shadow-md rounded-lg overflow-auto flex-1">
                <table className=" text-sm text-left text-gray-500 dark:text-gray-400 min-w-max w-full">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="p-4">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-2">
                        Email
                      </th>
                      <th scope="col" className="px-3 py-2">
                        Mobile
                      </th>
                      {/* <th scope="col" className="px-3 py-2">
                        Emp & Contra
                      </th> */}
                      {/* <th scope="col" className="px-3  py-2">
                        Department
                      </th>
                      <th scope="col" className="px-3 py-2">
                        Manager
                      </th> */}
                      <th scope="col" className="px-3 py-2">
                        Designation
                      </th>
                      <th scope="col" className="px-3 py-2">
                        Nature Of Employee
                      </th>
                      <th scope="col" className="px-3 py-2">
                        Type Of Employee
                      </th>
                      {/* <th scope="col" className="px-3 py-2">
                        State
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((data, index) => (
                      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        {/* <td className="px-3 py-2 max-w-xs ">{data.firstName}</td> */}
                        <td className="px-4 py-2 max-w-xs">
                          <Link to={`/people/PeopleDetails/${data.id}`} className="link-stylefont-medium text-blue-600 dark:text-blue-500 hover:underline">{data.employeeName}</Link>
                        </td>
                        <td className="px-4 py-2 max-w-xs ">{data.email}</td>
                        <td className="px-3 py-2 max-w-xs ">{data.mobile}</td>
                        {/* <td className="px-3 py-2 max-w-xs ">{data.natureOfEmployment}</td> */}
                        {/* <td className="px-3 py-2 max-w-xs ">{data.departmentName}</td> */}
                        {/* <td className="px-3 py-2 max-w-xs ">{data.managerName}</td> */}
                        <td className="px-3 py-2 max-w-xs ">{data.designation}</td>
                        <td className="px-2 py-2 max-w-xs ">{data.natureOfEmployment}</td>
                        <td className="px-2 py-2 max-w-xs ">{data.typeOfEmployee}</td>
                        {/* <td className="px-3 py-2 max-w-xs ">{data.location}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                <Pagination isValue={filterData} setCurrentItems={setCurrentItems} />
              </div>
            </form>
          </div>

        )}
      </div>
    </div>
  );
};

export default People;
