import { useAxios } from "../utils/hooks";
import { useState } from 'react';

interface HolidayEntry {
    id: number
    day: string;
    date: string;
    reason: string;
}
interface AttendanceEntry {
    checkIn: string;
    date: string;
    userId: number;
    checkOut: string;
    duration: string;
}
interface initialValues {
    id: number;
    leaveType: string;
    defaultValue: number;
    // monthlyIncrement: number;
    status:boolean;
    leaveMaxValue: number;
}
interface LeaveData {
    leaveType: string;
    startDate: string;
    endDate: string;
    reason: string;
}

interface EditAttendance {
    date: Date;
    checkIn: string;
    checkOut: string;
    status: string;
    remarks: string | null;
    userId: string;
}

interface DeleteAttendance {
    userId: string,
    date: Date,
    status: string,
}

interface GetEmployeesDetails {
    userId: number;
}

interface MyManagerDetails {
    userData: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface selectedData{
    date:string,
    leaveType:string
}


export const LeaveAttendanceApi = () => {
    const AxiosInstance = useAxios();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [checkData, setCheckData] = useState<AttendanceEntry[]>([]);

    const fetchHoliday = async () => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/leaveAttendance/getAllHolidays`);
            return res.data.data
        } catch (err: any) {
            setError(err);
            ///for prevent from crash when offline
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getOptionalHolidays = async () => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/leaveAttendance/getOptionalHolidays`);
            return res.data.data
        } catch (err: any) {
            setError(err);
            ///for prevent from crash when offline
            return [];
        } finally {
            setLoading(false);
        }
    };
    const saveOptionalHoliday = async (holiday:any) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/leaveAttendance/updateOptionalHolidays`,holiday);
            return res.data
        } catch (err: any) {
            setError(err);
            ///for prevent from crash when offline
            return [];
        } finally {
            setLoading(false);
        }
    };

    const addNewHoliday = async (holiday: HolidayEntry) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/leaveAttendance/addNewHoliday`, holiday);
            return res.data;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const updateHoliday =async (holidayData : HolidayEntry) =>{
        try {
            const res: any = await AxiosInstance.current?.post(`/route/leaveAttendance/updateHoliday`,holidayData);
            return res;
        }catch (error){
            console.error(error);
        }
    }

    const deleteHolidayid = async (data: any) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/leaveAttendance/deleteHoliday`, { id: data });
            return res.data;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const checkIn = async (checkInData: AttendanceEntry) => {
        try {
            setLoading(true);
            const res = await AxiosInstance.current?.post(`/route/attendance/checkIn`, checkInData);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const checkOut = async (checkOutData: AttendanceEntry) => {
        try {
            setLoading(true);
            const res = await AxiosInstance.current?.post(`/route/attendance/checkOut`, checkOutData);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const getCheckInCheckOutData = async (userId: number, currentDate: string) => {
        try {
            setLoading(true);
            let data = [];
            const res: any = await AxiosInstance.current?.get(`/route/attendance/getCheckInCheckOutData?userId=${userId}&date=${currentDate}`);
            if (res.data.data != null) {
                data.push(res.data.data)
            }
            setCheckData(data);
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const currentUserData = async (userId: number, currentDate: string) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/attendance/currentUserData?userId=${userId}&date=${currentDate}`);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const setEditAttendance = async (editAttendanceData: EditAttendance) => {
        try {
            setLoading(true);
            const res = await AxiosInstance.current?.post(`/route/attendance/setEditAttendance`, editAttendanceData);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };
    const deleteSingleAttendance = async (deleteAttendanceData: DeleteAttendance) => {
        try {
            setLoading(true);
            const res = await AxiosInstance.current?.post(`/route/attendance/deleteAttendance`, deleteAttendanceData);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const weekendDataSave = async (weekendData: any) => {
        try {
            setLoading(true);
            const res = await AxiosInstance.current?.post(`/route/attendance/weekendDataSave`, weekendData);
            return res;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const getMonthData = async (userId: string, currentDate: any) => {
        try {
            setLoading(true);
            if (userId !== 'undefined' || userId !== undefined) {
                const res: any = await AxiosInstance.current?.get(`/route/attendance/getMonthData?userId=${userId}&date=${currentDate}`);
                return res;
            }
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const getAttendanceRequest = async (userId: number) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/attendance/getAttendanceRequest?userId=${userId}`);
            return res.data;
        } catch (err: any) {
            setError(err);
            ///for prevent from crash when offline
            return [];
        } finally {
            setLoading(false);
        }
    };

    const setApprovedAttendance = async (userId: string, date: string) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/attendance/setApprovedAttendance`, { userId: userId, date: date });
            return res;
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const setRejectedAttendance = async (userId: string, date: string) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/attendance/setRejectedAttendance`, { userId: userId, date: date });
            return res;
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getEmployeesDetails = async (userId: GetEmployeesDetails) => {
        try {
            if(userId !== undefined){
                setLoading(true);
                const res: any = await AxiosInstance.current?.get(`/route/attendance/getEmployeesDetails?userId=${userId}`);
                return res;
            }
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getMyManagerDetails = async (userData: MyManagerDetails) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/attendance/getMyManagerDetails?userData=${userData}`);
            return res;
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const addNewLeave = async (salData: initialValues) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/leavetype/addLeave`, salData);
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    // const deleteLeave = async (taskId: number) => {
    //     try {
    //         const res: any = await AxiosInstance.current?.post(`/route/leavetype/deleteLeaveById`, { taskId });
    //         return res
    //     }
    //     catch (err: any) {
    //         setError(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    const updateLeave = async (salData: initialValues) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/leavetype/updateLeave`, salData);
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    const getAddLeave = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/leavetype/getAddLeave`);
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    const applyForLeave = async (appliedLeaveData: LeaveData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/applyLeave/applyForLeave`, appliedLeaveData)
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    const getRequestedLeave = async (userId?: number) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/applyLeave/getRequestedLeave?userId=${userId}`)
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    const getBalancedLeave = async (userId:number)=>{
        
        try{            
            const res:any = await AxiosInstance.current?.get(`/route/leaveManage/getBalancedLeave?userId=${userId}`);
            return res
        }
        catch(err:any){
            
           setError(err) 
        }
        finally {            
            setLoading(false);
        }
    }

    const getLeavetypeByDate = async(userid:number)=>{
        try{
            const res:any = await AxiosInstance.current?.get(`/route/leaveManage/getLeavetypeByDate?userid=${userid}`);
            return res
        }
        catch(err:any){
            setError(err)
        }
    }

    const approvedRejectLeave = async (selectedData:any, valueType:string, userId:number) => {    
            
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/applyLeave/approvedRejectLeave`,{selectedData: selectedData,valueType:valueType,userId:userId});
            return res;
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getUserData = async (userid: string) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/users/getUserData?userId=${userid}`);
            return res;
        } catch (err: any) {
            setError(err);
            return [];
        } finally {
            setLoading(false);
        }
    };


    const addOrRemoveLeave = async (salData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/leavetype/addOrRemoveLeave`, salData);
            return res
        }
        catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    return { loading, error, checkData, fetchHoliday, addNewHoliday, updateHoliday, deleteHolidayid, checkIn, checkOut, currentUserData, weekendDataSave, getCheckInCheckOutData, setEditAttendance, deleteSingleAttendance, getMonthData, addNewLeave, updateLeave, getAddLeave, applyForLeave, getRequestedLeave,  getAttendanceRequest, setApprovedAttendance,setRejectedAttendance, getEmployeesDetails, getMyManagerDetails,getBalancedLeave,getLeavetypeByDate,approvedRejectLeave, getUserData ,getOptionalHolidays,saveOptionalHoliday, addOrRemoveLeave};
};
