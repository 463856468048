import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Fullpage, Page } from '../../../components/Sidebar';
import { ReportsApi } from "../../../services/reports";
import Pagination from "../../../components/Pagination";


interface ResponseType {
    checkbox: boolean,
    id: number,
    firstName: string,
    employeeCode: string,
    value: string[]
};

interface SearchCriteria {
    id: string,
    firstName: string,
    employeeCode: string,
}

export default function MissingInformationPage({ sidebar }: any) {
    const { getMissingInformation, MultiRemider } = ReportsApi();
    const run = useRef(false);
    const [data, setData] = useState<ResponseType[]>([]);
    const [currentItems, setCurrentItems] = useState<ResponseType[]>([]);
    const [isModal, setModal] = useState(false);
    const initalValues: SearchCriteria = {
        id: "",
        firstName: "",
        employeeCode: ""
    };
    const [filterInput, setFilterInput] = useState<SearchCriteria>(initalValues);
    const [filterData, setFilterData] = useState<ResponseType[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (run.current) return;
        run.current = true;
        missinginformation();
    });
    useEffect(() => {
        filteredData("firstName", filterInput.firstName);
        filteredData("employeeCode", filterInput.employeeCode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        // Update the state of all checkboxes in the rows
        const updatedRows = data.map(row => ({ ...row, checkbox: newSelectAll }));
        setData(updatedRows);
    };

    const toggleCheckbox = (index: number, id: number) => {
        const updatedRows = data.map(row =>
            row.id === id ? { ...row, checkbox: !row.checkbox } : row
        );
        setData(updatedRows);
        const allSelected = updatedRows.every(row => row.checkbox);
        setSelectAll(allSelected);
    };
    const missinginformation = async () => {
        const data = await getMissingInformation();
        const res = data.data.data
        setData(res);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterInput({ ...filterInput, [name]: value });
        filteredData(name, value);
    };

    const filteredData = (name: string, value: string) => {
        if (name === "firstName") {
            const temp = data.filter(item => item?.firstName?.toLowerCase().includes(value?.toLowerCase()));
            setFilterData(temp);
        } else {
            const temp = data.filter(item => item.employeeCode.toString().includes(value));
            setFilterData(temp);
        }
    };

    const sendReminder = async (data: ResponseType[]) => {
        const filteredId: number[] = data.map(item => item.checkbox ? item.id : null).filter(id => id !== null) as number[];
        const responce = await MultiRemider(filteredId);
        if (responce.status === 200) {
            toast.success(responce.message);
            setTimeout(() => {
                setModal(false);
            }, 500)
        }
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-4/5 mt-4 mb-4 p-5 bg-white rounded-xl shadow-lg">
                    <div className="transition-transform duration-200 ">
                        <div className=" p-3 m-2">
                            <p className="font-bold text-2xl">MISSING INFORMATION</p>
                        </div>

                        <div className="text-left bg-gray-100 shadow-md rounded-md w-full flex ">
                            <div className="justify-center flex-col text-xl font-medium text-slate-800 dark:text-white pt-2">
                                <div>
                                    <label className="text-left p-2  m-4" htmlFor="firstName">Employee Name</label>
                                </div>
                                <input className="p-3 m-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" name="firstName" type="text" value={filterInput.firstName} onChange={handleInputChange} placeholder="Employee Name" />
                            </div>
                            <div className="justify-center text-xl font-medium text-slate-800 dark:text-white pt-2">
                                <div>
                                    <label className="text-left p-2  m-4" htmlFor="firstName">Employee Code</label>
                                </div>
                                <input className="p-3 m-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" name="employeeCode" type="text" value={filterInput.employeeCode} onChange={handleInputChange} placeholder="Employee Name" />
                            </div>
                            {/* <button className="p-3 m-3 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={missinginformation} >Search</button> */}
                        </div>
                        <table className="table-auto mt-5 shadow-md rounded-md bg-green-800 min-w-max w-full">
                            <thead >
                                <tr>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input checked={selectAll} type="checkbox" onChange={() => handleSelectAll()} className="w-4 h-4 text-blue-600 bg-gray-100  rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="p-4 mr-4 font-semibold ">
                                        Employee code
                                    </th>
                                    <th scope="col" className="p-4 mr-4 font-semibold ">
                                        Employee Name
                                    </th>
                                    <th scope="col" className="p-4 font-semibold ">Missing Information</th>
                                </tr>
                            </thead>

                            <tbody className="divide-y bg-gray-100 divide-gray-300">
                                {currentItems?.map((row, index) => (
                                    <tr
                                        key={index}
                                        // className={`border-b-2 border-solid border-opacity-50 transition-background-color duration-200 hover:bg-opacity-25 hover:border-opacity-100 ${index % 6 === 0
                                        //     ? 'border-teal-200 hover:bg-teal-50'
                                        //     : index % 6 === 1
                                        //         ? 'border-cyan-200 hover:bg-cyan-50'
                                        //         : index % 6 === 2
                                        //             ? 'border-red-200 hover:bg-red-50'
                                        //             : index % 6 === 3
                                        //                 ? 'border-orange-200 hover:bg-orange-50'
                                        //                 : index % 6 === 4
                                        //                     ? 'border-yellow-200 hover:bg-yellow-50'
                                        //                     : 'border-cyan-200 hover:bg-cyan-50'
                                        //     }`}
                                        className="divide-x"
                                    ><td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input checked={row?.checkbox} type="checkbox" onChange={() => toggleCheckbox(index, row.id)} className="w-4 h-4 text-blue-600 bg-gray-100 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-3 py-2 font-bold">
                                            <span className="font-semibold text-sm tracking-wider">
                                                {row.employeeCode}
                                            </span>
                                        </td>
                                        <td className="px-3 py-2 font-bold">
                                            <span className="font-semibold text-sm tracking-wider">
                                                {row.firstName}
                                            </span>
                                        </td>
                                        <td className="px-3 py-2 text-sm text-center">
                                            {row.value.length === 1 &&
                                                row.value.map((missing, index) => (
                                                    <span key={index}>{missing}</span>
                                                ))}
                                            {row.value.length > 1 &&
                                                row.value.map((missing, index) => (
                                                    <span key={index}>{missing + ','}</span>
                                                ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {isModal && (
                        <div className=" z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                                    <div className=" transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <h3 className="text-xl font-semibold leading-6 text-slate-800" id="modal-title">Send Reminder Email</h3>
                                                    <hr className="my-4 border-gray-400" />
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">We will send a reminder email to this user, asking them to fill in their details.This will help payroll process payments accurately and on time</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button type="button" onClick={() => sendReminder(data)} className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-800 sm:ml-3 sm:w-auto">SEND-REMINDER</button>
                                            <button type="button" onClick={() => setModal(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 sm:mt-0 sm:w-auto">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className=" p-3 m-2">
                    <p className="font-bold text-2xl">MISSING INFORMATION</p>
                </div> */}
                    <Pagination isValue={filterData} setCurrentItems={setCurrentItems} />
                    <div className="flex">
                        <button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => setModal(true)}>
                            Send Reminder Email
                        </button>
                    </div>
                </div>
            </Fullpage>
        </Page>
    );
}
