import React, { ChangeEvent, useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UsersApi } from "../../../services/users";
import { useParams } from "react-router-dom";
import { AiOutlineEye, AiOutlineDelete } from 'react-icons/ai';
import { DocumentApi } from "../../../services/document";
import { FaArrowLeft } from "react-icons/fa";
import { Fullpage, Page } from "../../../components/Sidebar";



interface userDoc {
    id: number,
    firstName: string
}
interface props {
    sidebar?: boolean;
    openDocuments?: () => void;
}
interface ResponseType {
    id: number;
    createdAt: string;
    documentType: string;
    fileName: string;
    fileUrl: string;
    description: string;
    optionalProperty?: string;
}


export default function DocumentsPage({ sidebar, openDocuments }: props) {
    const { getDocument, deleteDocument, postDocument } = DocumentApi();
    const { userId } = useParams<{ userId: string }>()
    const user = parseInt(userId);
    const { getUserId, } = UsersApi();
    const [data, setData] = useState<userDoc>({ id: NaN, firstName: "" });
    const [documentType, setDocumentType] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const [datass, setdatass] = useState<ResponseType[] | null>(null);



    useEffect(() => {
        funt();
        if (!isNaN(data.id)) {
            Document();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id]);


    const funt = async () => {
        const data1 = await getUserId(user);
        setData(data1);

    }

    const handleDelete = async (fileName: string | undefined) => {
        try {
            const res = await deleteDocument(fileName, data.id);
            if (res.status === 200) {
                toast.success(res.data.message);
                Document();
            } else {
                // Handle non-200 status code, if needed
                console.error("Failed to delete document. Status code:", res.status);
            }
        } catch (error: any) {
            // Handle any other errors that might occur during the API call
            console.error("Error deleting document:", error.message);
        }
    };


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        setFile(selectedFile || null);
    };
    const handleDocumentTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setDocumentType(e.target.value);
    };
    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };


    const handleUpload = async () => {
        if (!file || !documentType || !description) {
            toast.error("Please select a file and document type");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('documentType', documentType);
        formData.append('description', description);
        formData.append('userId', String(data.id));

        try {
            const response = await postDocument(data.id, formData);
            if (response.status === 200) {
                toast.success("Document uploaded successfully");
                Document();
            } else {
                toast.error("Error uploading document");
            }
        } catch (error) {
            console.error("Upload failed", error);
            toast.error("Error uploading document");
        }
    };


    // fetch document
    const Document = async () => {
        try {
            const res = await getDocument(data.id);
            if (res && res.data && res.data.documents) {
                const datas = res.data.documents;
                setdatass(datas);
            } else {
                // Handle the case where data.documents is null or undefined
                console.error("No documents found in the response");
            }
        } catch (error: any) {
            // Handle any other errors that might occur during the API call
            console.error("Error fetching document:", error.message);
        }
    };

    return (

        <>
            <Page>
                <Fullpage close={sidebar}>
                    <div className="w-4/5 p-5 mt-4 mb-4 min-h-svh bg-white rounded-lg shadow-lg">
                        <div className="text-left">
                            <p>People |{data.firstName}|Document
                                {userId && <div>
                                    <FaArrowLeft className=' fill-blue-500 font-bold text-3xl float-right' onClick={() => openDocuments?.()}
                                        data-tooltip-id={'my-tooltip'} data-tooltip-content="Back to PeopleDetails" />
                                </div>
                                }
                            </p>
                        </div>
                        <div className="text-left font-bold text-3xl text-slate-800">
                            <p className="">Document ({data.firstName})</p>
                        </div>
                        <div className='text-left'>
                            <label htmlFor="documentType" className="block mb-2 text-sm font-medium text-slate-800 dark:text-white">
                                Upload new documents (* is mandatory)
                            </label>
                            <select
                                id="documentType"
                                name="documentType"
                                className="bg-gray-50 border border-gray-300 text-slate-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={handleDocumentTypeChange}
                            >
                                <option value='' disabled>Select document type</option>
                                <option value="Professional Document">Professional Document</option>
                                <option value="Degree">Degree</option>
                                <option value="Aadhaar">Aadhaar</option>
                                <option value="Tax Deduction Supporting Document">Tax Deduction Supporting Document</option>
                                <option value="Employment Contract">Employment Contract</option>
                                <option value="Previous Employment Documents">Previous Employment Documents</option>
                                <option value="Bank Account Details">Bank Account Details</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='text-left'>
                            <label htmlFor="select" className="block mb-2 text-sm font-medium text-slate-800 dark:text-white">Description?</label>
                            <input
                                className="bg-gray-50 border border-gray-300 text-slate-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="text"
                                name="description"
                                id="description"
                                onChange={handleDescriptionChange}
                            />
                        </div>
                        <div>
                            <div className="flex flex-col items-start mt-8 ">
                                <input
                                    type="file"
                                    accept=".pdf, .doc, .img, .jpeg, .png, .jpg"
                                    className="border p-2 mb-4"
                                    onChange={handleFileChange}
                                />
                                <button
                                    onClick={handleUpload}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Upload Document
                                </button>
                                <ToastContainer />
                            </div>
                            <br />
                            {datass && (
                                // <div className="">
                                <table className=" text-sm text-left text-gray-500 dark:text-gray-400 min-w-max w-full rounded-md overflow-hidden shadow-lg">
                                    <thead className="text-sm text-slate-800 uppercase bg-gray-100 dark:bg-slate-800 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th scope="col" className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                                Type
                                            </th>
                                            <th scope="col" className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                                Description
                                            </th>
                                            <th scope="col" className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                                Attachment
                                            </th>
                                            <th scope="col" className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-green-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-green-100 uppercase tracking-wider">
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {datass?.map((data, index) => (
                                            <tr key={index} className="bg-white text-slate-800 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600">
                                                <td className="px-3 py-2 max-w-xs text-center">{data?.createdAt}</td>
                                                <td className="px-3 py-2 max-w-xs text-center">{data?.documentType}</td>
                                                <td className="px-3 py-2 max-w-xs text-center">{data?.description}</td>
                                                <td className="px-3 py-2 max-w-xs text-center">
                                                    {data?.fileUrl ? (
                                                        <div className="inline-block">
                                                            <a href={data?.fileUrl} target="_blank" rel="noopener noreferrer">
                                                                <AiOutlineEye />
                                                            </a>
                                                        </div>
                                                    ) : null}
                                                </td>
                                                <td className="px-3 py-2 max-w-xs text-center " onClick={() => handleDelete(data?.fileName)}>
                                                    {data?.fileUrl ? <div className="inline-block"><AiOutlineDelete /></div> : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                // </div>
                            )}
                        </div>
                    </div>
                </Fullpage >
            </Page >
        </>

    );
}
