import { useEffect, useState } from "react"
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance"
import { useParams } from "react-router-dom";

const RemainingLeave = () =>{

  const [LeavetypeByDate,setLeavetypeByDate] = useState<any>([]);

  const {getLeavetypeByDate} = LeaveAttendanceApi()
  const { userid } = useParams<{ userid: string }>();
  const userId = parseInt(userid)

  const showBalancedLeave = async ()=>{
    try{
      const leaveData = await getLeavetypeByDate(userId);
      
      setLeavetypeByDate(leaveData.data.data)
    }
    catch(e){

    }
  }

  useEffect(()=>{
    showBalancedLeave()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

return(
    <div>
<div>
<div className="bg-gray-100 bg-100 p-8 bg-size-lg bg-cover bg-center">
      <h1 className="text-3xl font-bold mb-6">Leaves Taken</h1>
      <div className="leave-types">
      {LeavetypeByDate.length > 0 ? (
  <div className="leave-type mb-4">
    {LeavetypeByDate.map((data: any, index: number) => (
      <div key={index} className="mb-4">
        <h2 className="text-xl font-semibold">{data.leavetype}</h2>
        {data.dates.map((date: string, index: number) => (
          <p key={index} className="text-lg">{date}</p>
        ))}
      </div>
    ))}
  </div>
) : (
  <p>Data not available</p>
)}


        </div>
      
    </div>
</div>
    </div>
)
}

export default RemainingLeave;