import Keycloak from 'keycloak-js';
// import env from "react-dotenv";
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL as string,
  realm:  process.env.REACT_APP_KEYCLOAK_REALM as string ,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID as string ,
});


export default keycloak;
