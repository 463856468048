import React, { useState } from "react";
import { toast } from "react-toastify";
import { Main, Page, RightBar } from '../../components/Sidebar';
import { PayableSalaryApi } from "../../services/payableSalary";


export default function PaymentSlipPage({ sidebar }: any) {
    const { postSalarySlip } = PayableSalaryApi()
    const [selectedValue, setSelectedValue] = useState<any>();

    const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(e.target.value);
    }

    const handleDownloadSlip = async () => {
        try {
            const promise = postSalarySlip(selectedValue);
            setTimeout(() => {
                toast.promise((promise), {
                    pending: 'Loading......',
                    success: 'Downloading...... ',
                    error: 'Something wrong !!'
                });
            }, 100);
            const response = await promise;
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Salary_Slip.pdf';
                link.click();
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong!');
        }
    }

    return (
        <Page>
            <Main close={sidebar}>
                <div className="w-full p-4 min-h-svh mb-5 bg-white mt-5 rounded-xl shadow-lg" >
                    <p className='text-left text-slate-800 font-bold text-2xl mb-4'>Salary Slips</p>
                    <hr className="my-4 border-t-2 border-gray-300" />
                    <label className="block text-black text-left font-bold mb-2">Select Financial Year:</label>
                    <div className="flex items-center justify-between">
                        <select
                            name="months"
                            // value={selectedDate?.monthDate}
                            onChange={handleSelectChange}
                            required
                            className="w-full px-3 py-2 border rounded focus:outline-none focus:shadow-outline max-w-xs"
                        >
                            <option value="" disabled selected>Select Months</option>
                            <option value="3">Select Last 3 Months</option>
                            <option value="6">Select Last 6 Months</option>
                        </select>
                        {selectedValue && (<button
                            className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                            onClick={handleDownloadSlip}
                            type="submit"
                        >
                            Download Payslips
                        </button>)}
                    </div>
                </div>
            </Main>
            <RightBar></RightBar>
        </Page>
    );
}
