import { useEffect, useRef, useState } from 'react';
import { UsersApi } from "../../../services/users";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAxios } from '../../../utils/hooks';

interface usersUpdate {
  id: number,
  firstName: string
}

interface ProfilePhoto {
  // setisProfilePhoto: any,
  setisProfilePhoto: () => void,
  funt: () => void;
}

function PeopProfPhoto(props: ProfilePhoto) {
  const AxiosInstance = useAxios();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null);
  const { userId } = useParams<{ userId: string }>()
  const user = parseInt(userId);
  const { getUserId } = UsersApi();
  const run = useRef(false);
  const [data, setData] = useState<usersUpdate>({ id: NaN, firstName: "" });

  useEffect(() => {
    if (run.current) return;
    run.current = true;
    funt();
  });
  const funt = async () => {
    const data = await getUserId(user);
    setData(data);

  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewImage(null);
    }
  };
  const closeProfilePhoto = () => {
    props.setisProfilePhoto();
    props.funt();
  }
  const handleUpload = async () => {
    try {
      if (selectedFile) {
        // Create a FormData object
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('userId', String(data.id));

        const response = await AxiosInstance.current?.post(
          `/route/users/profilePhoto?userId=${data.id}`,
          formData,

        );

        // Check if response status is 200 for success
        if (response && response.status === 200) {
          toast.success('File uploaded successfully!');
          props.setisProfilePhoto?.();
          props.funt?.()
        } else {
          toast.error('File upload failed!');
        }
      } else {
        toast.error('Please select an image.');
      }
    } catch (error) {
      toast.error('File upload failed due to an error.');
    }
  };



  return (
    <div className='bg-white p-5 rounded-md shadow-md '>
      <div className='text-left'>
        <p>People |{data.firstName}|Upload Photo
        </p>
      </div>
      <div className='text-left'>
        <p className='text-3xl'>Upload Photo</p>
      </div>
      <div className='text-left'>
        <p className='text-sm'>Please upload a photo of yourself so your colleagues can identify you.</p>
        <p className='text-sm'>We suggest a close-up of the face, of 300x300 pixels dimensions.</p>
        <p className='text-sm'>Large photos will automatically get resized.</p>
        <p className='text-sm'> You can use an online editor to edit your photo before uploading it here.</p>
      </div>
      <div>
        <div className="flex flex-col items-start mt-8 ">
          {previewImage && (
            <img
              src={previewImage as string}
              alt="Preview"
              className="max-w-xs min-w-xs max-h-64 mb-4"
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="border p-2 mb-4"
          />

          <div className="flex">
            <button
              onClick={handleUpload}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-8"
            >
              Upload Image
            </button>
            <button
              onClick={closeProfilePhoto}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeopProfPhoto;
