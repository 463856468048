
import { useEffect, useState } from 'react';
import { useIdleTimerContext } from 'react-idle-timer'
import styles from './Idletimer.module.css';
interface IPromptProps {
    open: React.MutableRefObject<boolean>
    keycloak?: any
}

const promptBeforeIdle = 10000
export function Child(props: IPromptProps) {
    const { getRemainingTime } = useIdleTimerContext()
    const [remaining, setRemaining] = useState<number>(NaN)

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })


    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    // const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'
    if (timeTillPrompt === 0) {
        props.open.current = true;
    } else if (timeTillPrompt > 0) {
        props.open.current = false;
    }
    // if (timeTillPrompt > 0) {
    //     return (
    //         <p>
    //             {timeTillPrompt} {seconds} until prompt
    //         </p>
    //     )
    // }

    return null
}

export function Prompt(props: IPromptProps) {
    const [remaining, setRemaining] = useState<number>(NaN)

    const { activate, getRemainingTime } = useIdleTimerContext()

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    if (remaining === 0) {
        props.keycloak.logout()
    }

    return (
        <div
            className={styles['modal']}
            style={{
                display: props.open.current ? 'flex' : 'none'
            }}
        >
            <h3>Are you still here?</h3>
            <p>Logging out in {remaining} seconds</p>
            <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={activate}>Im still here</button>
        </div>
    )
}