import { useAxios } from "../utils/hooks";

export const SalaryStructureApi = () => {

    const AxiosInstance = useAxios();

    const postSalaryStruct = async (salaryData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/salaryStructure/salaryData`, salaryData);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const updateSalaryStruct = async (salaryData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/salaryStructure/updateSalaryData`, salaryData);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const getSalaryStruct = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/salaryStructure/getStructure`);
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const deleteSalaryStruct = async (taskId: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/salaryStructure/deleteStruct`, { taskId });
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const deleteDepartment = async (taskId: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/Company/deletedepartment`, { taskId });
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }
    return { postSalaryStruct, updateSalaryStruct, getSalaryStruct, deleteSalaryStruct, deleteDepartment }
}