
import React, { useState } from 'react'
import People from './Components/People'
import Button from '../../components/Button';
import PeopleUser from './Components/PeopleUser';
import PeopAddEmp from './Components/PeopAddEmp';
import { Main, Page, RightBar } from '../../components/Sidebar';

interface RoleAccess {
  access: string;
}

interface PeopleProps {
  sidebar: boolean;
  sidebarAccess: RoleAccess[];
}

export default function PeoplePage(props: PeopleProps) {

  const [isFirstFormOpen, setFirstFormOpen] = useState(true);
  const [isSecondFormOpen, setSecondFormOpen] = useState(false);
  const [isThirdFormOpen, setThirdFormOpen] = useState(false);
  const [, setIsFourthForm] = useState(true);
  const [isButtonForm, setIsButtonForm] = useState(true);

  const openSecondForm = () => {
    setFirstFormOpen(false);
    setSecondFormOpen(true);
    setThirdFormOpen(false);
    setIsFourthForm(false);
    setIsButtonForm(false);
  };

  const openThirdForm = () => {
    setFirstFormOpen(false);
    setSecondFormOpen(false);
    setThirdFormOpen(true);
    setIsFourthForm(false);
    setIsButtonForm(false);
  };

  const closeSecondForm = () => {
    setFirstFormOpen(true)
    setSecondFormOpen(false);
    setThirdFormOpen(false);
    setIsFourthForm(false);
    setIsButtonForm(true)
  };

  const closeThirdForm = () => {
    setFirstFormOpen(true);
    setIsFourthForm(true);
    setSecondFormOpen(false);
    setThirdFormOpen(false);
    setIsButtonForm(true)
  };
  return (
    <Page>
      <Main close={props.sidebar}>
        {isFirstFormOpen && (
          <People />)
        }
        {isSecondFormOpen && (
          <PeopleUser
            close={closeSecondForm}
          />
        )}
        {isThirdFormOpen && (
          <PeopAddEmp close={closeThirdForm} />
        )}
      </Main>
      <RightBar>
        {(isButtonForm && props.sidebarAccess.some(menu => menu.access === "addUser")) && (
          <div>
            <div>
              <Button onClick={openSecondForm}>Add One <h1 className="text-xs">Add single employee and contractor</h1></Button>
            </div>
            <div>
              <Button onClick={openThirdForm}>Add Many <h1 className="text-xs">Add many employe and contractors</h1></Button>
            </div>
          </div>
        )}
      </RightBar>
    </Page>
  )
}