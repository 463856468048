import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

//@ts-ignore
const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    const checkAuthentication = async () => {
      if (initialized && !keycloak.authenticated) {
        localStorage.setItem('redirectedOnce', 'false');
        await keycloak.login();
      } else if (localStorage.getItem('redirectedOnce') === 'false') {
        localStorage.setItem('redirectedOnce', 'true');
        window.history.pushState({}, '', '/');
      }
    };

    checkAuthentication();
  }, [initialized, keycloak]);


  return keycloak.authenticated ? children : null;
};

export default PrivateRoute;
