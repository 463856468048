
import { useEffect, useState } from 'react';
import { Fullpage, Page } from '../../components/Sidebar';
import ResignationForm from './Components/resignationForm';
import { ProfileApi } from '../../services/Profile';
import { Link } from "react-router-dom";
import UserResignationTable from './Components/UserResignationTable';




interface ResponseType {
    userId: number,
    employeeCode: string;
    isTeacher: string;
    separationdate: string;
    firstName: string;
    reasonforleaving: string;
    workingagain: string;
    liketheorganization: string;
    organizationstaff: string;
    sharewithus: string;
    vehiclehanded: string;
    equipmentshanded: string;
    librarybooks: string;
    security: string;
    exitinterviewconducted: string;
    noticeperiod: string;
    resignationlettersubmitted: string;
    managerclearance: string;
}


export default function Resignation({ user, sidebar, sidebarAccess }: any) {
    const { getAllResignation } = ProfileApi();
    const [isResignationData, setResignationData] = useState(true);
    const [isOpenResignation, setOpenResignation] = useState(false);
    const [isUserResignationTable, setUserResignationTable] = useState(false);
    const [data, setdata] = useState<ResponseType[]>([]);
    const [userId, setUserId] = useState<number>(NaN)
    const [id, setId] = useState<number>(NaN)


    useEffect(() => {
        Resignation();
        if (user !== 'SuperAdmin' && user !== undefined){
            setUserResignationTable(true)
            setId(id)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const Resignation = async () => {
        const data = await getAllResignation();
        setdata(data)
    }

    const getIdFormUser = (id:number) => {
        setId(id)
    }

   const handleClick = (id:number, userId:number) =>{

    setOpenResignation(true);
    setId(id)
    setUserId(userId);
    setResignationData(false);
   }

   const resignationForm = () =>{
    Resignation();
    setOpenResignation(false);
    setResignationData(true);
     
   }


    return (
         <Page >
            <Fullpage close={sidebar}>
                {user === 'SuperAdmin' && isResignationData && (
                    <div>
                        <div className="mt-5">
                            <p className='text-center text-slate-800 p-3 font-bold text-3xl'>Exit Employee</p>
                        </div>
                        <div className="overflow-y-auto">
                            <table className=" table-auto w-full  rounded-md shadow-lg bg-green-800">
                                <thead className=" border-gray-100 text-green-100">
                                    <tr>
                                        <th scope="col" className="px-6 py-4">
                                            Employee Id
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Employee Name
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Separation date
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Reason for leaving
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Working for this organization again
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            What did you like the most of the organization
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Think the organization due to improve staff welfare
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Anything you wish to share with us
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Company vehicle handed in
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            All equipments handed in
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            All library books submitted
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Security
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Exit interview conducted
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Notice period followed
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Resignation letter submitted
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Manager/Superviser clearance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((data: any, index: any) => (
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" style={{ color: data.status === 'Approved' ? 'green' : data.status === 'Rejected' ? 'red' : 'gray' }}>
                                            <td className="px-4 py-2 ">
                                            <Link onClick={() => handleClick(data.id, data.UserId)} to='#' >{data.employeeCode}</Link>    
                                            </td>
                                            <td className="px-4 py-2">
                                                <Link onClick={() => handleClick(data.id, data.UserId)} to='#' >{data.firstName}</Link>
                                            </td>
                                            <td className="px-4 py-2 text-center" >
                                                {data.status}

                                            </td>
                                            <td className="px-4 py-2 text-center">
                                                {data.separationdate}

                                            </td>
                                            <td className="px-4 py-2 text-center" >
                                                {data.reasonforleaving}

                                            </td>
                                            <td className="px-4 py-2 text-center">

                                                {data.workingagain}
                                            </td>
                                            <td className="px-4 py-2 text-center" >

                                                {data.liketheorganization}
                                            </td>
                                            <td className="px-4 py-2 text-center">
                                                {data.organizationstaff}

                                            </td>
                                            <td className="px-4 py-2 text-center" >
                                                {data.sharewithus}

                                            </td>
                                            <td className="px-4 py-2 text-center">

                                                {data.vehiclehanded}
                                            </td>
                                            <td className="px-4 py-2 text-center" >

                                                {data.equipmentshanded}
                                            </td>
                                            <td className="px-4 py-2 text-center">
                                                {data.librarybooks}

                                            </td>
                                            <td className="px-4 py-2 text-center" >
                                                {data.security}

                                            </td>
                                            <td className="px-4 py-2 text-center">
                                                {data.exitinterviewconducted}

                                            </td>
                                            <td className="px-4 py-2 text-center" >

                                                {data.noticeperiod}
                                            </td>
                                            <td className="px-4 py-2 text-center">
                                                {data.resignationlettersubmitted}

                                            </td>
                                            <td className="px-4 py-2 text-center" >
                                                {data.managerclearance}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>

                )}

                 { isOpenResignation && (
                    <ResignationForm userRole={user} id={id} userId={userId}  close={resignationForm} setOpenResignation = {setOpenResignation} setUserResignationTable={setUserResignationTable}  />
                )} 

                {isUserResignationTable && (
                    <UserResignationTable getIdFormUser={getIdFormUser} setOpenResignation = {setOpenResignation} setUserResignationTable={setUserResignationTable}/>
                )}
            </Fullpage>
         </Page> 
    )
}