import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Fullpage, Page } from '../../../components/Sidebar';
import { ReportsApi } from "../../../services/reports";
import { AiOutlineEye } from 'react-icons/ai';
import Pagination from "../../../components/Pagination";




interface ResponseType {
    firstName: string;
    createdAt: string;
    fileName: string;
    docName: string;
    filePath: string;
    description: string;
}

interface SearchCriteria {
    firstName: string,
    docName: string
}

export default function DocumentsReportsPage({ sidebar }: any) {
    const { getReportdocument } = ReportsApi()
    const [data, setData] = useState<ResponseType[]>([]);
    const [currentItems, setCurrentItems] = useState<ResponseType[]>([]);
    const [openModal, setModals] = useState(false);
    const run = useRef(false);
    const initalValues: SearchCriteria = {
        firstName: "",
        docName: ""
    };
    const [filterData, setFilterData] = useState<SearchCriteria>(initalValues);

    useEffect(() => {
        if (run.current) return;
        run.current = true;
        report();
    });
    const report = async () => {
        const res: any = await getReportdocument(filterData);
        setData(res.data.data);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const EmployeeDocument = () => {
        setModals(true)
    }
 
    const closeModal = () =>{
        setModals(false)
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
                    <div >
                        <p className='text-center text-slate-800 p-5 font-bold text-2xl'>Documents Report</p>
                    </div>

                    <div className=" text-left bg-gray-100 shadow-md sm:rounded-lg min-w-max p-3 m-5">
                        <div className="flex items-center justify-center space-x-10">
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left  ml-1" htmlFor="firstName">Employee Name</label>
                                <input className="p-3 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" name="firstName" type="text" value={filterData.firstName} onChange={handleInputChange} />
                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left  ml-1" htmlFor="firstName">Document Type</label>
                                <input className="p-3 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" name="docName" type="text" value={filterData.docName} onChange={handleInputChange} />
                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <button className="p-3 my-3 mt-9 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={report} >Search</button>
                            </div>
                        </div>
                    </div>


                    {openModal && (
                        <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                            <div className="relative z-50 bg-transparent">
                            <div className="flex justify-end mt-10">
                                        <button
                                            type="button"
                                            onClick={() => closeModal()}
                                            className="px-4 py-2 text-red-800 font-semibold rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                                        >
                                            X
                                        </button>
                                    </div>
                                <div className="relative flex flex-col items-center mx-auto p-6 bg-gray-100 rounded-lg shadow-xl">
                                    <div>
                                        <p className="text-2xl">Download Employee Documents</p>
                                    </div>
                                    <br />
                                    <div className="text-left">
                                        <label >Employee Status</label>
                                        <select
                                            id="employeesStatus"
                                            name="employeesStatus"
                                            className="w-full border mb-3 p-3 rounded-[3px] border-solid border-[#ccc]"

                                        >

                                            <option value='' disabled>Select Employee</option>
                                            <option value="allEmployees">All Employees</option>
                                            <option value="activeEmployees">Active Employees</option>
                                            <option value="dismissedEmployees">Dismissed Employees</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="p-5 m-5 bg-gray-100 rounded-md shadow-lg">
                        <div className="text-right mb-5">
                            <button onClick={EmployeeDocument} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Export</button>
                        </div>
                        <table className=" table-fixed w-full rounded-md shadow-lg bg-green-800 overflow-hidden">
                            <thead className=" border-gray-100 text-green-100">
                                <tr>
                                    <th scope="col" className="p-4">
                                        Name
                                    </th>
                                    <th scope="col" className="p-4">
                                        Date
                                    </th>
                                    <th scope="col" className="p-4">
                                        Type
                                    </th>
                                    <th scope="col" className="p-4">
                                        Description
                                    </th>
                                    <th scope="col" className="p-4">
                                        Attachement
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {currentItems.map((data, index) => (
                                    <tr key={index} className="bg-white text-center"
                                    // className={`border-b-2 border-solid border-opacity-50 transition-background-color duration-200 hover:bg-opacity-25 hover:border-opacity-100 ${index % 6 === 0
                                    //     ? 'border-teal-200 hover:bg-teal-50'
                                    //     : index % 6 === 1
                                    //         ? 'border-cyan-200 hover:bg-cyan-50'
                                    //         : index % 6 === 2
                                    //             ? 'border-red-200 hover:bg-red-50'
                                    //             : index % 6 === 3
                                    //                 ? 'border-orange-200 hover:bg-orange-50'
                                    //                 : index % 6 === 4
                                    //                     ? 'border-yellow-200 hover:bg-yellow-50'
                                    //                     : 'border-cyan-200 hover:bg-cyan-50'
                                    //     }`}
                                    >
                                        <td className="px-3 py-2 max-w-xs">{data.firstName}</td>
                                        <td className="px-3 py-2 max-w-xs">{data.createdAt}</td>
                                        <td className="px-3 py-2 max-w-xs">{data.docName}</td>
                                        <td className="px-3 py-2 max-w-xs">{data.description}</td>
                                        <td className="px-3 py-2 max-w-xs flex justify-center items-center">
                                            {data.filePath ? (
                                                <a href={data.filePath} className="" target="-blank" rel="noopener noreferrer">
                                                    <AiOutlineEye />
                                                </a>
                                            ) : null}


                                        </td>
                                        <ToastContainer />
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                        <Pagination isValue={data} setCurrentItems={setCurrentItems} />
                    </div>
                </div>
            </Fullpage>
        </Page>
    );
}
