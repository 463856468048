import React, { useEffect, useState } from "react";

import { Fullpage, Page } from '../../../components/Sidebar';
import { ReportsApi } from "../../../services/reports";
import Pagination from "../../../components/Pagination";

interface userActivity {
    activity: string;
    Employeecode: string;
    Email: string;
    Activity: string;
    ActivityData: string;
    createdAt: string;
}
export interface SearchActivityCriteria{
    type:string;
    employeeCode:string;
}
export default function AuditReportPage({ sidebar }: any) {

    const { getActivityData, getAllActivityData } = ReportsApi()
    const [ActivityData, setActivityData] = useState<userActivity[]>([])
    const [AllActivityData, setAllActivityData] = useState<userActivity[]>([])
    const [currentItems, setCurrentItems] = useState<userActivity[]>([]);
    const initalValues: SearchActivityCriteria = {
        type: '',
        employeeCode:''
    };
    const [filterData, setFilterData] = useState<SearchActivityCriteria>(initalValues);

    useEffect(() => {
        getActivity()
        search()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getActivity = async () => {
        const response = await getActivityData();
        setActivityData(response.data.data);
    }

    const handleSelectChnage = (e:React.ChangeEvent<HTMLSelectElement>)=>{
        const {name, value} = e.target;
        setFilterData({...filterData, [name]:value});
    }
    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        const{name, value} = e.target;
        setFilterData({...filterData, [name]:value})
    }
    const search = async () => {
        const response = await getAllActivityData(filterData);
        setAllActivityData(response.data.data);
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
                    <div >
                        <p className="text-center text-slate-800 p-5 font-bold text-2xl">EMPLOYEE ACTIVITY REPORT</p>
                    </div>
                    <div className=" text-left bg-gray-100 shadow-md sm:rounded-lg min-w-max p-3 m-5">
                        <div className="flex items-center justify-center space-x-10">
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="type">Activity Type</label>
                                <select className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="type" name="type" value={filterData.type} onChange={handleSelectChnage}>
                                    <option value="" disabled selected>Select Type</option>
                                    <option value="All" >All</option>
                                    {ActivityData.map((item: any) => (
                                        <option key={item.activity} value={item.activity}>
                                            {item.activity}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="employeeCode">Employee Code:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="text" id="employeeCode" name="employeeCode" value={filterData.employeeCode} onChange={handleInputChange}/>

                            </div>
                            {/* <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="toDate">To Date:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="toDate" name="toDate" value={filterData.toDate} onChange={handleInputChange} />

                            </div> */}
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <button className="p-3  my-3 mt-9 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Apply Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-auto p-5'>
                        <table className="table-fixed rounded-md shadow-lg bg-green-800 overflow-hidden">
                            <thead className=" text-green-100">
                                <tr>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Employee Id</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Employee Name</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Employee Mail Id</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Activity</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Activity Data</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Activity Date</th>
                                    {/* <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Interest Rate</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Remaining Emi Month</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Remiaining EMi Amount</th> */}
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
                                {
                                    currentItems.length > 0 ? (
                                        currentItems.map((data: any, index: number) => (
                                            <tr key={index}>
                                                <td className="text-center border border-gray-400 p-2">R-{data && data.Employeecode ? data.Employeecode : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.FullName ? data.FullName : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.Email ? data.Email : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.Activity ? data.Activity : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.ActivityData ? data.ActivityData : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.createdAt ? data.createdAt : "N/A"}</td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td colSpan={8} className="text-center  p-2" >No data available</td>
                                        </tr>
                                    )}


                            </tbody>
                        </table>
                        <Pagination isValue={AllActivityData} setCurrentItems={setCurrentItems} />

                    </div>

                </div>
            </Fullpage>
            
        </Page>
    );
}
