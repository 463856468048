import { useEffect, useState } from "react";
import { CompanyApi } from "../../../services/company";
import { toast } from "react-toastify";

interface User {
    user: string;
    sidebar: boolean;
    setCompanyDetails: (data: any) => void;
    getCompanyData: (data: any) => void;

}

interface CompanyData {
    id: number,
    companyName: string,
    companyEmail: string,
    companyAddress: string,
    companyContactNumber: string,
    companyLogo: string,
    pf: number,
    pt: number
    payrollDate: string,
    attendanceFromdate: string,
    attendanceTodate: string,
}

export default function CompanyDetails(userData: User) {
    const { getCompanyDetails, saveCompanyDetails, getCompanyDataValue } = CompanyApi();
    const [company, setCompany] = useState<CompanyData>({ id: 0, companyName: "", companyEmail: "", companyAddress: "", companyContactNumber: "", companyLogo: "", pf: 0, pt: 0, payrollDate: "", attendanceFromdate: "", attendanceTodate: "" });
    const [validationErrors, setValidationErrors] = useState<Partial<CompanyData>>({});
    const [companyData, setCompanyData] = useState<CompanyData>({ id: 0, companyName: "", companyEmail: "", companyAddress: "", companyContactNumber: "", companyLogo: "", pf: 0, pt: 0, payrollDate: "", attendanceFromdate: "", attendanceTodate: "" })
    const getCompanyDatas = async () => {
        const response = await getCompanyDetails();
        setCompany(response.data.data);
        userData.getCompanyData('');
    };

    useEffect(() => {
        getCompanyDatas();
        getOnlyCompanyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setCompanyData({ ...companyData, [name]: value });
    };

    const getOnlyCompanyData = async ()=>{
        const response = await getCompanyDataValue();
        setCompanyData(response.data.data)
    }
    // const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { name, value } = event.target;
    //     setCompany({ ...company, [name]: value });
    // };

    // const handleToDay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { name, value } = event.target;
    //     setCompany({ ...company, [name]: value });
    // }

    // const handleFromDay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { name, value } = event.target;
    //     setCompany({ ...company, [name]: value });
    // }

    const validateForm = (): boolean => {
        const errors: Partial<CompanyData> = {};

        //validation rules here
        if (companyData?.companyName === '') {
            errors.companyName = 'Company Name is required';
        }
        if (!companyData?.companyEmail) {
            errors.companyEmail = 'Company Email is required';
        } else if (!/\S+@\S+\.\S+/.test(companyData.companyEmail)) {
            errors.companyEmail = 'Invalid email address';
        }

        if (companyData?.companyAddress === '') {
            errors.companyAddress = 'Company Address is required';
        }

        if (!companyData?.companyContactNumber) {
            errors.companyContactNumber = 'Company Contact is required';
        } else if (companyData.companyContactNumber.replace(/\D/g, '').length > 10) {
            errors.companyContactNumber = 'Phone number cannot exceed 10 digits';
        }else if (companyData.companyContactNumber.replace(/\D/g, '').length < 10) {
            errors.companyContactNumber = 'Phone number cannot exceed 10 digits';
        }

        // Update the validation errors state
        setValidationErrors(errors);

        // Return true if there are no validation errors
        return Object.keys(errors).length === 0;
    };

    // const daysOfMonth = Array.from({ length: 31 }, (_, index) => {
    //     const day = index + 1;
    //     return day < 10 ? `0${day}` : `${day}`;
    // });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await saveCompanyDetails(companyData);
                if (response.status === 200) {
                    toast.success(response.data.message);
                    getCompanyDatas();
                    userData.setCompanyDetails(false);
                }
                setCompany(company);
                setValidationErrors({});
            } catch (error) {
                // Handle any errors
                console.error('Error:', error);
            }
        };
    }

    return (
        <div className="modal-content py-4 px-6 bg-white rounded-lg shadow-lg" style={{ maxHeight: "80vh", overflowY: "auto", overflowX: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }}>
            <style>
                {`
                    .modal-content::-webkit-scrollbar {
                    width: 12px;
                    }

                    .modal-content::-webkit-scrollbar-thumb {
                    background-color: #yourColor; /* Replace 'yourColor' with the desired color code or name */
                    }

                    .modal-content::-webkit-scrollbar-track {
                    background-color: #anotherColor; /* Replace 'anotherColor' with the desired color code or name */
                    }
                `}
            </style>
            <div className=' relative block max-h-[40vw]  rounded-lg shadow-lg bg-white w-[35vw] '>
                <form onSubmit={handleSubmit} >
                    <div>
                        <h2 className="text-xl font-bold text-center py-4 px-6 text-black">Company Details</h2><br />
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="companyName">Company Name:</label>
                        <input type="text" className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="companyName" name="companyName" value={companyData?.companyName} onChange={handleInputChange} />
                        {validationErrors.companyName && (<span className=" text-red-700 error text-left block">{validationErrors.companyName}</span>)}
                    </div>
                    <div>
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="companyEmail">Company Email:</label>
                        <input type="text" className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="companyEmail" name="companyEmail" value={companyData?.companyEmail} onChange={handleInputChange} />
                        {validationErrors.companyEmail && (<span className=" text-red-700 error text-left block">{validationErrors.companyEmail}</span>)}
                    </div>
                    <div>
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="companyContactNumber">Company Contact:</label>
                        <input type="text" className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="companyContactNumber" name="companyContactNumber" value={companyData?.companyContactNumber} onChange={handleInputChange} />
                        {validationErrors.companyContactNumber && (
                            <span className="text-red-700 error text-left block">
                                {validationErrors.companyContactNumber}
                            </span>
                        )}
                    </div>
                    <div>
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="companyAddress">Company Address:</label>
                        <textarea className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="companyAddress" name="companyAddress" value={companyData?.companyAddress} onChange={handleInputChange} />
                        {validationErrors.companyAddress && (<span className="text-red-700 error text-left block">{validationErrors.companyAddress}</span>)}
                    </div>
                    <div className="flex justify-center space-x-20 mt-4">
                        <div>
                            <button className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="submit">Submit</button>
                        </div>
                        <div>
                            <button className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => userData.setCompanyDetails(false)} >Close</button>
                        </div>
                    </div>
                </form>
            </div>
            {/* <hr className="my-4 border-t-2 border-gray-300" /> */}

            {/* <div className=' relative block max-h-[40vw] shadow-md rounded-lg bg-white w-[35vw] '>
                <form onSubmit={handleSubmit} >
                    <div>
                        <h2 className="text-xl font-bold text-center py-4 px-6 text-black">Provident Fund / Professional Tax / Payroll Data</h2><br />
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="pf">Provident Fund :</label>
                        <input type="text" className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="pf" name="pf" value={company?.pf} onChange={handleInputChange} />
                        
                    </div>
                    <br />
                    <div>
                        <label className=" text-black block text-left mb-2 text-md font-bold  dark:text-white" htmlFor="pt">Professional Tax :</label>
                        <input type="text" className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="pt" name="pt" value={company?.pt} onChange={handleInputChange} />
                        
                    </div>
                    <br />
                    <div>
                        <label className="text-black block text-left mb-2 text-md font-bold dark:text-white" htmlFor="payrollDate">
                            Payroll Date:
                        </label>
                        <select
                            className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            id="payrollDate" name="payrollDate" value={company?.payrollDate} onChange={handleDayChange} >
                            <option value="" disabled>Select Payroll Date</option>
                            {daysOfMonth.map(day => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        
                    </div>
                    <br />
                    <div>
                        <label className="text-black block text-center mb-2 text-md font-bold dark:text-white" htmlFor="attendanceDate">
                            Attendance Cycle:
                        </label>
                        <div className="flex">
                            <div className="flex-1">
                                <label className="text-black block text-left mb-2 text-md font-bold dark:text-white" htmlFor="attendanceFromdate">
                                    From :
                                </label>
                                <select
                                    className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    id="attendanceFromdate" name="attendanceFromdate" value={company?.attendanceFromdate} onChange={handleFromDay} >
                                    <option value="" disabled>Select Payroll Date</option>
                                    {daysOfMonth.map(day => (
                                        <option key={day} value={day}>
                                            {day}
                                        </option>
                                    ))}
                                </select>
                                
                            </div>
                            <div className="mx-4"></div>
                            <div className="flex-1">
                                <label className="text-black block text-left mb-2 text-md font-bold dark:text-white" htmlFor="attendanceTodate">
                                    To :
                                </label>
                                <select
                                    className="block p-2.5 w-full text-sm px-4 py-2 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    id="attendanceTodate" name="attendanceTodate" value={company?.attendanceTodate} onChange={handleToDay} >
                                    <option value="" disabled>Select Payroll Date</option>
                                    {daysOfMonth.map(day => (
                                        <option key={day} value={day}>
                                            {day}
                                        </option>
                                    ))}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-20 mt-4">
                        <div>
                            <button className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="submit">Submit</button>
                        </div>
                        <div>
                            <button className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => userData.setCompanyDetails(false)} >Close</button>
                        </div>
                    </div>
                </form>
            </div> */}
        </div>
    )
}

