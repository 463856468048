import { IAdditionSalary, IDeductionSalary } from "../pages/payrollComponent/modals/editPayableSalaryModal";
import { EmployeeHistory } from "../pages/payrollComponent/payableMatrix";
import { PayrollData } from "../pages/payrollComponent/runPayrol";
import { useAxios } from "../utils/hooks";

export const PayableSalaryApi = () => {

    const AxiosInstance = useAxios();

    const getPayableSalary = async (filteredDate: string | null) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/payableSalary/getPayableSalary?filteredDate=${filteredDate}`);
            return res;
        } catch (error) {
            console.error("error > ", error);
        }
    }

    const postEditSalary = async (salaryData: IAdditionSalary | null, deduction: IDeductionSalary | null, userId: number, id: number) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/payableSalary/postAdditionAmount`, { salaryData, deduction, userId, id });
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const getEditSalary = async (userId: number, id: number) => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/payableSalary/getEditSalary?userId=${userId}&id=${id}`);
            return res;
        } catch (error) {
            console.error("errot >>", error);
        }
    }

    const postFinalizeDate = async (finalizeMonth: string, finalizeUsers: boolean) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/payableSalary/postFinalizeMonth`, { finalizeMonth, finalizeUsers });
            return res;
        } catch (error) {
            console.error(error);
        }
    }

    const postPayrollData = async (payrollData : PayrollData) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/payableSalary/postPayrollData`, {payrollData});
            return res;
        } catch (error) {
            console.error(error);
            
        }
    }

    const postSalarySlip = async (selectedValue: number) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/salarySlip/postSalarySlips`, { selectedValue }, {
                responseType: 'blob', // Set the response type to blob
            });
            return res;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const getMatrixData = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/matrixPay/getMatrixData`);
            return res;
        } catch (error) {
            console.error(error);

        }
    }

    const getEmployeeHistoryDetails = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/employeeHistory/getEmployeeHistory`);
            return res;
        } catch (error) {
            console.error(error);

        }
    }

    const postEmployeeHistory = async (editHistory: EmployeeHistory) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/employeeHistory/postEmployeeHistory`, { editHistory });
            return res;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const getExcelEmployeeData = async () => {
        try {
            const res: any = await AxiosInstance.current?.get(`/route/employeeHistory/downloadExcelHistoryData`, {
                responseType: 'blob',
              });
            return res.data;
        } catch (error) {
            console.error("error >> ", error);
        }
    }

    const uploadEmployeeFile = async (formData: any) => {
        try {
            const res: any = await AxiosInstance.current?.post(`/route/employeeHistory/uploadEmployeeFile`, formData);
            return res;
        } catch (error) {
            console.error(error);
        }
      }

    return { getPayableSalary, postEditSalary, getEditSalary, postFinalizeDate, postPayrollData, postSalarySlip, getMatrixData, getEmployeeHistoryDetails, postEmployeeHistory, getExcelEmployeeData, uploadEmployeeFile }
}