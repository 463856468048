import React, { useEffect, useState } from "react";
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import Popover from '../../../components/Popover'
import moment from "moment";

interface HolidayEntry {
  id: number
  day: string;
  date: string;
  reason: string;
}
interface DateInfo {
  currentMonth: boolean;
  date: Date;
  today?: boolean;
  sat?: boolean;
  holidaydate?: string;
  holidaydetails?: string
}
interface HolidayProps {
  holidays: HolidayEntry[];
  leaveRequestData: any;
  setCalendarDates: (dates: any[]) => void;
  attendanceData: any;
  setTodayDate: (data: any) => void;
}

export default function Calendar({ holidays, setCalendarDates, attendanceData, leaveRequestData, setTodayDate }: HolidayProps) {
  const days: string[] = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const currentDate: Date = new Date();
  var sat = [false, false, false, false, false];
  const [today, setToday] = useState<Date>(currentDate);
  const [selectDate, setSelectDate] = useState<Date>(currentDate);

  useEffect(() => {
    setCalendarDates(currentMonthDates);
    setTodayDate(today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today, holidays]);


  const addMonths = (date: Date, months: number): Date => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + months);
    return newDate;
  };

  const selectMonths = (date: Date, months: number): Date => {
    const newDate = new Date(date);
    newDate.setMonth(months);
    return newDate;
  };

  const selectYears = (date: Date, years: number): Date => {
    const newDate = new Date(date);
    newDate.setFullYear(years);
    return newDate;
  };

  const getYearRange = (() => {
    let startYear = new Date().getFullYear() - 5;
    let endYear = new Date().getFullYear() + 5;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  })

  const generateDate = (
    month: number,
    year: number,
    sat: boolean[],
    holiday: HolidayEntry[]
  ): DateInfo[] => {
    const firstDateOfMonth = new Date(year, month, 1);
    const lastDateOfMonth = new Date(year, month + 1, 0);

    const arrayOfDate: DateInfo[] = [];
    var holidaydate;
    var dateObject;
    var j = 0;
    // create prefix date
    for (let i = firstDateOfMonth.getDay() - 1; i >= 0; i--) {
      const date = new Date(year, month, -i);
      arrayOfDate.push({
        currentMonth: false,
        date,
      });
    }

    // generate current date
    for (let i = 1; i <= lastDateOfMonth.getDate(); i++) {
      const date = new Date(year, month, i);
      let holidaydetail = '';
      //for saturday checkbox
      let saturday = false;

      if (date.getDay() === 6) {
        saturday = sat[j];
        j++
      }

      //for holiday 
      for (let i = 0; i < holiday.length; i++) {
        dateObject = new Date(holiday[i].date).toDateString();
        if (dateObject === date.toDateString()) {
          holidaydate = dateObject;
          holidaydetail = holiday[i].reason

        }
      }

      arrayOfDate.push({
        currentMonth: true,
        date,
        today: date.toDateString() === new Date().toDateString(),
        sat: saturday,
        holidaydate: holidaydate,
        holidaydetails: holidaydetail,
      });

    }

    const remaining = 42 - arrayOfDate.length;

    // create suffix date
    for (let i = 1; i <= remaining; i++) {
      const date = new Date(year, month, lastDateOfMonth.getDate() + i);

      arrayOfDate.push({
        currentMonth: false,
        date,
      });
    }

    return arrayOfDate;
  };

  function cn(...classes: (string | boolean)[]): string {
    return classes.filter(Boolean).join(" ");
  }

  const calData = generateDate(today.getMonth(), today.getFullYear(), sat, holidays)
  const currentMonthDates = calData.filter((dateInfo) => dateInfo.currentMonth);

  // const isLeaveStatus = (status: any) => {
  //   // console.log(status);

  //   return (
  //     (status !== 'Present' ||
  //       status !== 'Absent') && (
  //       status !== null)
  //   );
  // };

  const getContent = (date: any, holidaydetails: any, isLeaveData: any) => {
    const attendanceDataItem = attendanceData.find(
      (data: any) => data.date === moment(date).format("YYYY-MM-DD")
    );
    let contentString = ``;
    if (attendanceDataItem) {
      const checkIn = attendanceDataItem.checkIn;
      const checkOut = attendanceDataItem.checkOut;
      const leavetype = attendanceDataItem.status

      if (!isLeaveData && leavetype && !(checkIn || checkOut)) {
        contentString += `${leavetype}`
      } else
        if (!isLeaveData && (checkIn || checkOut)) {
          contentString += `Check In: ${checkIn}\nCheck Out: ${checkOut}\n`;
        }
        else if (isLeaveData) {
          contentString += `${isLeaveData}`;
        }
    }
    if (holidaydetails) {
      contentString += `${holidaydetails}`;
    }
    return contentString;
  };

  return (
    <div className=" w-full bg-white mt-5 rounded-xl shadow-lg p-4">
      <p className="text-left text-slate-800 font-bold text-2xl mb-4">Calendar</p>
      <div className="flex w-full justify-between bg-gray-700 rounded-t-lg items-center ">
        <div className="flex">
          {/* month select */}
          <div className="p-1">
            <select className=" select-none bg-gray-700 text-slate-300 font-semibold p-4 border-none"
              value={today.getMonth()}
              onChange={(e) => {
                setToday(cd => currentDate);
                setToday(cd => selectMonths(cd, Number(e.target.value)));
                setToday(cd => selectYears(cd, today.getFullYear()));
              }}
            >
              {months.map((option, index) => (
                <option key={index} value={index}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* month select */}
          <select className=" select-none bg-gray-700 text-slate-300 font-semibold p-1 border-none"
            value={today.getFullYear()}
            onChange={(e) => {
              setToday(cd => currentDate);
              setToday(cd => selectYears(cd, Number(e.target.value)));
            }}>
            {getYearRange().map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-7 items-center pr-4">
          <MdNavigateBefore
            className="w-7 h-7 fill-slate-300 cursor-pointer hover:scale-105 transition-all"
            onClick={() => {
              setToday(addMonths(today, -1));
            }}
          />
          <h1
            className="cursor-pointer font-semibold text-slate-300 hover:scale-105 transition-all"
            onClick={() => {
              setToday(currentDate);
              setSelectDate(currentDate);
            }}
          >
            Today
          </h1>
          <MdNavigateNext
            className="w-7 h-7 fill-slate-300 cursor-pointer hover:scale-105 transition-all"
            onClick={() => {
              setToday(addMonths(today, 1));
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-7 ">
        {days.map((day, index) => {
          return (
            <h1
              key={index}
              className={`text-sm text-center bg-gray-700 h-8 grid place-content-center select-none ${index === 0 ? "text-red-500" : "text-slate-300"}`}
            >
              {day}
            </h1>
          );
        })}
      </div>

      <div className=" grid grid-cols-7 bg-gradient-to-b from-slate-100 via-sky-200 to-indigo-200 lg:h-[75vh] " >
        {generateDate(today.getMonth(), today.getFullYear(), sat, holidays).map(
          ({ date, sat, holidaydate, holidaydetails, currentMonth, today }, index) => {
            const attendanceDataItem = attendanceData.find(
              (data: any) => data.date === moment(date).format("YYYY-MM-DD")
            );
            const attendanceDataItem2 = leaveRequestData
              .map((data: { [x: string]: any; }) => data["multipleLeave"])
              .flat()
              .find((leave: { [x: string]: string; }) => leave["date"] === moment(date).format("YYYY-MM-DD"));
            if (attendanceDataItem2) {
            }
            const attendace = attendanceDataItem?.status !== 'Present' && attendanceDataItem?.status !== 'Absent' && attendanceDataItem?.status !== 'Pending' && attendanceDataItem?.status !== null && attendanceDataItem?.status !== undefined && attendanceDataItem?.status !== "";
            const isLeave = attendanceDataItem2 && attendanceDataItem2.approved === "0" && attendace === false;
            // const isLeave = attendanceDataItem && isLeaveStatus(attendanceDataItem ? attendanceDataItem.status : '');
            return (
              <div
                key={index}
                className="p-2 text-center  h-14 grid place-content-center text-sm "
              >
                <h1
                  className={cn(
                    sat ? "text-red-500" : "",
                    index % 7 === 0 && currentMonth ? "text-red-500 hover:bg-black hover:text-red" : "",
                    currentMonth && !(index % 7 === 0) ? "hover:bg-black hover:text-white" : "text-gray-400 ",
                    today ? "bg-blue-600 text-white" : "",
                    holidaydate === date.toDateString() ? "bg-red-500 text-white" : "",
                    selectDate.toDateString() === date.toDateString() && currentMonth
                      ? "bg-black text-white"
                      : "",
                    "h-10 w-10 rounded-full grid place-content-center  transition-all cursor-pointer select-none",
                    currentMonth && attendace ? "bg-green-500 text-white" : "",
                    currentMonth && isLeave ? "bg-yellow-500 text-white" : "",
                  )}
                  onClick={() => {
                    setSelectDate(date);
                  }}
                >
                  {/* <Popover color="bg-gradient-to-b from-slate-100 via-sky-200 to-indigo-200" content={holidaydetails}>{date.getDate()}</Popover> */}
                  <Popover color="bg-gradient-to-b from-slate-100 via-sky-200 to-indigo-200"
                    content={getContent(date, holidaydetails, attendanceDataItem2?.leavetype)}>
                    {/* content={holidaydetails}> */}
                    {date.getDate()}</Popover>
                </h1>

              </div>
            );
          }
        )}
      </div>
    </div>
  );
}
