import { useEffect, useRef } from 'react';
import axios from 'axios';
import  { AxiosInstance } from 'axios';
import { useKeycloak } from '@react-keycloak/web';
// import env from "react-dotenv";
export const useAxios = () => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';
  
  const baseURL = process.env.REACT_APP_BACKEND_SERVER_URL;

  useEffect(() => {

    axiosInstance.current = axios.create({
      baseURL : baseURL,
      // withCredentials : true,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
      },
    });
    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL, initialized, kcToken]);

  return axiosInstance;
};
