import React, {  useState } from "react";
import { Page, Fullpage } from '../../../components/Sidebar';

export default function FnfSettlement({ sidebar }: any) {
    const [rows, setRows] = useState([{ id: 1 }]);

    const handleAddRow = () => {
        const newRow = { id: rows.length + 1 };
        setRows([...rows, newRow]);
    };

    const handleRemoveRow = (idToRemove: number) => {
        setRows(rows.filter(row => row.id !== idToRemove));
    };


    return (
        <>
            <Page>
                <Fullpage close={sidebar}>
                    <div className="w-full">
                        
                        <div className="border border-gray-300 p-4 mt-10 rounded-lg w-1/2">
                        <div className="mb-4 text-left text-gray-800 font-semibold text-3xl">
                            <h1> Full & final settlement htmlFor { }</h1>
                        </div>
                            <div>
                                <h1 className="text-left text-lg font-bold">Dismissal information</h1>
                                <label htmlFor="last_working_date" className="text-left font-semibold text-base text-gray-800 block mb-1">Last working date</label>
                                <input type="date" id="last_working_date" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Last working date" />
                            </div>
                            <div className="mt-4">
                                <label htmlFor="reason_for_dismissal" className="text-left font-semibold text-base text-gray-800 block mb-1">Reason for dismissal</label>
                                <input type="text" id="reason_for_dismissal" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Reason for dismissal" />
                            </div>
                            <div className="mt-8">
                                <h1 className="text-left text-lg font-bold">Leave Encashment</h1>
                                <label htmlFor="number_of_leaves_to_encash" className="text-left font-semibold text-base text-gray-800 block mb-1">Number of leaves to encash</label>
                                <input type="text"  id="number_of_leaves_to_encash" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Number of leaves to encash" />
                            </div>
                            <div className="mt-4">
                                <label htmlFor="leave_encashment_amount" className="text-left font-semibold text-base text-gray-800 block mb-1">Leave encashment amount</label>
                                <input className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                type="text" pattern="[0-9]*" inputMode="numeric"
                                id="leave_encashment_amount" 
                                placeholder="Leave encashment amount" />
                            </div>
                            <div className="mt-8">
                                <h1 className="text-left text-lg font-bold ">Additions (excluding gratuity)</h1>
                                <div>
                                    <label htmlFor="leave_type" className="text-left font-semibold text-base text-gray-800 block mb-1">Leave Type</label>
                                    <input type="text" id="leave_type" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6" placeholder="E.g. Earned leave" />
                                </div>
                                <div>
                                    <label htmlFor="amount" className="text-left font-semibold text-base text-gray-800 block mb-1">Amount</label>
                                    <input className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                    type="text" pattern="[0-9]*" inputMode="numeric"
                                    placeholder="Amount" id="amount"/>
                                </div>
                            </div>
                            <div className="mt-8">
                                <h1 className="text-left text-lg font-bold">Loss of pay deductions</h1>
                                <label htmlFor="loss_of_pay_days" className="text-left font-semibold text-base text-gray-800 block mb-1">Loss of pay days</label>
                                <input type="text" id="loss_of_pay_days" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Loss of pay days" />
                            </div>
                            <div className="mt-8">
                                <h1 className="text-left text-lg font-bold">Deduction (excluding salary advance)</h1>
                                {rows.map((row, index) => (
                                    <div key={row.id} className="flex mb-4">
                                        <div className="mr-2">
                                            <label htmlFor={`label${index}`} className="text-left font-semibold text-base text-gray-800 block mb-1">Label</label>
                                            <input type="text" id={`label${index}`} className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="E.g. Laptop recovery" />
                                        </div>
                                        <div className="mr-2">
                                            <label htmlFor={`amount${index}`} className="text-left font-semibold text-base text-gray-800 block mb-1">Amount</label>
                                            <input className="block w-24 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                            placeholder="Amount" id={`amount${index}`}
                                            type="text" pattern="[0-9]*" inputMode="numeric"/>
                                        </div>
                                        <div className="">
                                            <label htmlFor={`pay${index}`} className="text-left font-semibold text-base text-gray-800 block mb-1">Deduction</label>
                                            <input type="text" id={`pay${index}`} className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Gross pay" />
                                        </div>
                                        <button type="button" onClick={() => handleRemoveRow(row.id)} className="text-red-600 ml-2">
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                type="button"
                                onClick={handleAddRow}
                                className="float-left bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            >
                                + Add
                            </button>
                            <br/>
                            <div className="mt-8">
                                <h1 className="text-left text-lg font-bold">Personal Email Address</h1>
                                <label htmlFor="deduction" className="text-left font-semibold text-base text-gray-800 block mb-1">Email</label>
                                <input type="email" id="deduction" className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Enter Your Personal Email Here" />
                            </div>
                            <br/>
                            <button type="button" className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                                        Process Final Settlement
                                    </button>
                        </div>
                        
                    </div>

                </Fullpage>
                {/* <RightBar></RightBar> */}
            </Page>
        </>
    )
}

