import React, { Dispatch, SetStateAction, useState } from "react";
import { SalaryStructureApi } from "../../../services/salaryStructure";
import { toast } from "react-toastify";

interface SalaryStructure {
    id: number;
    salaryComponent: string;
    percentageAmount: number; 
}

interface props { fetchSalary: () => Promise<void>; setSalaryStructure: Dispatch<SetStateAction<boolean>>; }

export default function SalaryStructures(props:props) {
    const { postSalaryStruct} = SalaryStructureApi();
    const [salData, setSalData] = useState<SalaryStructure>({ id: NaN, salaryComponent: "", percentageAmount: 0 });

    const handleAddTask = async () => {
        if (salData.salaryComponent.trim() !== "") {
            const response = await postSalaryStruct(salData);
            if (response.data.status === 200) {
                toast.success(response.data.message);
                props.fetchSalary();
                props.setSalaryStructure(false);
                setSalData({ id: NaN, salaryComponent: "", percentageAmount: 0 });
            } else {
                toast.error(response.data.errors);
            } 
        }
    };

    return (
      
                <div className="w-full p-4 bg-white mt-5 rounded-xl shadow-lg" >
                    <h4 className=""><b>Salary Category</b></h4>
                    <div className=' overflow-y-auto shadow-md sm:rounded-lg  p-4 m-4'>
                        <table className='table-auto border-collapse'>
                            <tbody>
                                <tr>
                                    <td>
                                        <label><b>Salary Category</b></label><br />
                                        <input
                                            placeholder="   Category"
                                            value={salData.salaryComponent}
                                            onChange={(e) => setSalData({ ...salData, salaryComponent: e.target.value })}
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                        />
                                    </td>
                                    <td>
                                        <label><b>Percentage Of Amount</b></label><br />
                                        <input
                                            placeholder="   Percentage Of Amount"
                                            type='number'
                                            step="0.01"
                                            value={salData.percentageAmount}
                                            onChange={(e) => setSalData({ ...salData, percentageAmount: parseFloat(e.target.value) })}
                                            className="px-4 py-2.5 mr-2 mb-2 h-9 m-1 rounded-lg"
                                        />
                                    </td>
                                  
                                    <td><br />
                                        <button className=' bg-blue-500 hover:bg-blue-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 m-1' onClick={handleAddTask}>Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className=' overflow-y-auto shadow-md sm:rounded-lg'>
                      
                    </div>
                </div>
       
    );
}
