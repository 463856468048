import CryptoJS from "crypto-js";

export default class Functions {

    static async decryptedData(res:any) {
        const secretKey = process.env.REACT_APP_SECRETKEY;        
        if (!secretKey) throw Error("Environment: REACT_APP_SECRETKEY is not defined");
        const iv = process.env.REACT_APP_IV;
        if (!iv) throw Error("Environment: REACT_APP_IV is not defined");
        const secretKeyWordArray = CryptoJS.enc.Utf8.parse(secretKey);
        const ivWordArray = CryptoJS.enc.Hex.parse(iv);
        const decipher = CryptoJS.AES.decrypt(res, secretKeyWordArray, { iv: ivWordArray, mode: CryptoJS.mode.CBC }); 
        return JSON.parse(decipher.toString(CryptoJS.enc.Utf8));
    }
}