import { useAxios } from "../utils/hooks";
import { useState } from 'react';




export const DocumentApi = () => {
    const AxiosInstance = useAxios();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const postDocument = async (id:number, formData:any) =>{
        try{
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/users/postDocumentDetails?userId=${id}`, formData);
            return res.data
        }catch(error:any){
            setError(error)
        }finally {
            setLoading(false)
          }
    }

    const getDocument = async (id: number) => {
        try {
          setLoading(true);
          const res: any = await AxiosInstance.current?.get(`/route/users/getDocumentDetails?userId=${id}`);
          return res.data
        } catch (error: any) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }

    const deleteDocument = async (fileName:string | undefined, id:number)=>{
        try{
        const res:any = await AxiosInstance.current?.delete(`/route/users/deleteDocument?fileName=${fileName}&&id=${id}`);
        return res
        }catch(error:any){
            setError(error)
        } finally {
            setLoading(false)
          }
    }

      return {loading, error, getDocument, deleteDocument,postDocument}
}