/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import DeleteConfirmationModal from './DeleteConfirmationModal';

interface EditAttendanceModalProps {
  editDate: any,
  deleteStatus: any,
  isEditModalOpen: any,
  toggleEditModal: any,
  userId: any,
  getFullMonthTableDataHandle: any,
  onDeleteAttendance: (date: any) => void;
}

const EditAttendanceModalPage = ({ editDate, deleteStatus, isEditModalOpen, toggleEditModal, userId, getFullMonthTableDataHandle, onDeleteAttendance }: EditAttendanceModalProps) => {
  const [getEditDate, seteditDate] = useState(null);
  const [updatedCheckIn, setUpdatedCheckIn] = useState('');
  const [updatedCheckOut, setUpdatedCheckOut] = useState('');
  const [updatedRemarks, setUpdatedRemarks] = useState('');
  const [EditsuccessMessage, setEditsuccessMessage] = useState('');
  const [EditfailureMessage, setEditfailureMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState("Present");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [deletedStatus, setDeletedStatus] = useState('');
  const { setEditAttendance } = LeaveAttendanceApi();


  const toggleModal = (date: any) => {
    seteditDate(date);
    toggleEditModal(date);
  };
  const handleCancelEdit = () => {
    resetEditAttendanceForm();
    toggleModal(editDate);
  };

  const resetEditAttendanceForm = () => {
    setUpdatedCheckIn('');
    setUpdatedCheckOut('');
    setUpdatedRemarks('');
  };

  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };

  const handleCheckInChange = (event: any) => {
    const inputTime = event.target.value;
    const formattedTime = moment(inputTime, 'HH:mm').format('hh:mm A');
    setUpdatedCheckIn(formattedTime);
  };

  const handleCheckOutChange = (event: any) => {
    const inputTime = event.target.value;
    const formattedTime = moment(inputTime, 'HH:mm').format('hh:mm A');
    setUpdatedCheckOut(formattedTime);
  };

  const handleRemarks = (event: any) => {
    const remarks = event.target.value;
    setUpdatedRemarks(remarks);
  };

  const handleUpdateAttendance = async () => {
    if (!selectedStatus || !updatedCheckIn || !updatedCheckOut || !updatedRemarks) {
      setEditfailureMessage("Please fill all fields before updating attendance.");
      setTimeout(() => {
        setEditfailureMessage('')
      }, 2000);
      return;
    }
    // Create an object with updated attendance data
    const updatedAttendanceData: any = {
      date: editDate,
      status: selectedStatus,
      checkIn: updatedCheckIn !== '' ? updatedCheckIn : '',
      checkOut: updatedCheckOut !== '' ? updatedCheckOut : '',
      remarks: updatedRemarks !== '' ? updatedRemarks : '',
      userId: userId,
    };
    try {
      let fetchedData;
      const updatedEditAttendanceData = await setEditAttendance(updatedAttendanceData);
      if (updatedEditAttendanceData?.data.status === 200 || updatedEditAttendanceData?.data.status === 201) {
        getFullMonthTableDataHandle();
        setTimeout(() => {
          handleCancelEdit();
        }, 2000);
        setEditsuccessMessage(updatedEditAttendanceData?.data.message)
        setTimeout(() => {
          setEditsuccessMessage('')
        }, 2000);
        fetchedData = updatedEditAttendanceData?.data.data;
        return fetchedData;
      } else {
        setEditfailureMessage(updatedEditAttendanceData?.data.message)
        setTimeout(() => {
          setEditfailureMessage('')
        }, 2000);
        return fetchedData;
      }

    } catch (error) {
      console.error("Error updating attendance data:", error);
    }
  };

  const openDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
    toggleModal(editDate);
    setDeletedStatus(deleteStatus);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

          <div
            className="modal-container bg-white w-4/5 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
            style={{
              transform: `translateY(${isEditModalOpen ? '0' : '-100%'})`,
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            {/* Modal Header */}
            <div className="modal-header py-4 px-6 flex justify-between items-center bg-slate-800 text-white">
              <h2 className="text-xl font-semibold">Change Attendance</h2>
              {/* Close Button */}
              <button className="modal-close p-2" onClick={() => toggleModal(moment(editDate).format("YYYY-MM-DD"))}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <hr />
            {/* Modal Content */}
            <div className="modal-content py-4 px-6 bg-slate-800">
              <p className="block text-sm font-semibold text-left ml-1 mb-5 text-white">
                Change your attendance on {editDate}
              </p>
              <form>
                <div className="mb-4">
                  <label htmlFor="status" className="block text-white text-sm font-semibold mb-2 text-left ml-1">
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    className="w-full px-4 py-2 border bg-slate-600 text-white rounded-lg focus:outline-none focus:border-blue-500"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="Present">Present</option>
                    <option value="Absent">Absent</option>
                    {/* <option value="Casual Leave">Casual Leave</option>
                    <option value="Half Casual Leave">Half Casual Leave</option>
                    <option value="Medical Leave">Medical Leave</option>
                    <option value="Half Medical Leave">Half Medical Leave</option>
                    <option value="Earned Leave">Earned Leave</option>
                    <option value="Half Earned Leave">Half Earned Leave</option>
                    <option value="Bereavement Leave">Bereavement Leave</option>
                    <option value="Half Bereavement Leave">Half Bereavement Leave</option>
                    <option value="Unpaid Leave">Unpaid Leave</option>
                    <option value="Unpaid Half Day">Unpaid Half Day</option> */}
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="checkIn" className="block text-white text-sm font-semibold mb-2 text-left ml-1">
                    Check In
                  </label>
                  <input
                    type="time"
                    id="checkIn"
                    name="checkIn"
                    className="w-full px-4 py-2 border rounded-lg bg-slate-600 text-white focus:outline-none focus:border-blue-500"
                    placeholder="hh:mm"
                    onChange={handleCheckInChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="checkOut" className="block text-white text-sm font-semibold mb-2 text-left ml-1">
                    Check Out
                  </label>
                  <input
                    type="time"
                    id="checkOut"
                    name="checkOut"
                    className="w-full px-4 py-2 border rounded-lg bg-slate-600 text-white focus:outline-none focus:border-blue-500"
                    placeholder="hh:mm"
                    onChange={handleCheckOutChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="remarks" className="block text-white dark:text-white text-sm font-semibold mb-2 text-left ml-1">
                    Remarks
                  </label>
                  <textarea
                    id="remarks"
                    name="remarks"
                    className="w-full px-4 py-2 border rounded-lg bg-slate-600 text-white focus:outline-none focus:border-blue-500 dark:bg-gray-600 dark:text-white"
                    rows={4}
                    placeholder="(optional)"
                    onChange={handleRemarks}
                    required
                  ></textarea>
                </div>
                <div className="flex justify-between mt-10">
                  {/* Buttons */}
                  <div className="space-x-4">
                    <button
                      type="button"
                      className='px-4 py-2 bg-blue-500 hover:bg-blue-600 
                text-white rounded-md transition duration-300 ease-in-out'
                      onClick={handleUpdateAttendance}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-md transition duration-300 ease-in-out"
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition duration-300 ease-in-out"
                    onClick={openDeleteConfirmation}
                  >
                    Delete Attendance
                  </button>
                </div>
              </form>

              {/* Alert inside Edit */}
              {EditsuccessMessage && (
                <div className="bg-green-500 text-white px-4 py-4 mt-4 rounded-md">{EditsuccessMessage}</div>
              )}

              {EditfailureMessage && (
                <div className="bg-red-500 text-white px-4 py-4 mt-4 rounded-md">{EditfailureMessage}</div>
              )}

            </div>
          </div>
        </div>
      )}

      <DeleteConfirmationModal isDeleteConfirmationOpen={isDeleteConfirmationOpen} userId={userId} editDate={editDate} resetEditAttendanceForm={resetEditAttendanceForm} closeDeleteConfirmation={closeDeleteConfirmation} getFullMonthTableDataHandle={getFullMonthTableDataHandle} onDeleteAttendance={onDeleteAttendance} deletedStatus={deletedStatus} />
    </>
  )
}

export default EditAttendanceModalPage