import { useAxios } from "../utils/hooks";
import { useState } from 'react';

interface FormData {
  employeeCode: string;
  managerFName: string;
  separationdate: string;
  firstName: string;
  lastName: string;
  reasonforleaving: string;
  workingagain: string;
  liketheorganization: string;
  organizationstaff: string;
  sharewithus: string;
  vehiclehanded: string;
  equipmentshanded: string;
  librarybooks: string;
  security: string;
  exitinterviewconducted: string;
  noticeperiod: string;
  resignationlettersubmitted: string;
  managerclearance: string;
  // resonforreject: string;
}

interface BankDetails {
  accountNo: number;
  id: number;
  ifscCode: string;
  panCardNo: string;
  beneficiaryName: string;
  status?: string;
}

export const ProfileApi = () => {
  const AxiosInstance = useAxios();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postResignation = async (data: FormData) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/profile/postResignation`, data);
      return res.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getResignation = async (id: number) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/profile/getResignation?id=${id}`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getResignationByEmpCode = async (selectedEmployeeCode: string) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/profile/getResignationByEmpCode?selectedEmployeeCode=${selectedEmployeeCode}`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getAllResignation = async () => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/profile/getAllResignation`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const AprroveReject = async (status: string, BankDetails: BankDetails) => {
    try {
      setLoading(true);
      const res = await AxiosInstance.current?.put(`/route/bankDetails/AprrovedRejectBankDetails?status=${status}`, BankDetails);
      return res;
    } catch (err: any) {
      return err;
    } finally {
      setLoading(false);
    }
  }
  const getAprovedResignation = async () => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.get(`/route/profile/getAllAprovedResigna`);
      return res.data.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateResignation = async (data: FormData) => {
    try {
      setLoading(true);
      const res: any = await AxiosInstance.current?.post(`/route/profile/updateResignation`, data);
      return res.data
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

 const getResignationStatus = async () =>{
   try{
     setLoading(true);
   const res: any = await AxiosInstance.current?.get(`/route/profile/getResignationStatus`)
   return res.data  
  } catch (error: any){
    setError(error)
  } finally {
    setLoading(false)
  }
 }

 const getResignationById = async (id:number) =>{
  try{
    setLoading(true);
    const res:any =  await AxiosInstance.current?.get(`/route/profile/getResignationById?id=${id}`)
    return res.data.data
  }catch (error: any){
    setError(error)
  } finally {
    setLoading(false)
  }
 }

  return { loading, error, getResignationById, getAprovedResignation, getResignationStatus, getResignationByEmpCode, getAllResignation, postResignation, getResignation, updateResignation, AprroveReject }
}