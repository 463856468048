import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web'
// import env from "react-dotenv";
import keycloak from './utils/keycloak';
import { AuthService } from './services/AuthService';
import HomePage from './pages/Home/Home';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import CustomLoader from './components/loader';
import ErrorPage from './ErrorPages/ErrorPage';
import { IdleTimerProvider, PresenceType } from 'react-idle-timer';

const container: any = document.getElementById('root');
const Main = () => {
  const keyCloak: Keycloak = keycloak;
  const [count, setCount] = useState();
  const [err, setErr] = useState<string>();
  // const [state, setState] = useState<string>('Active');
  // const [open, setOpen] = useState<boolean>(false)
  // const [remaining, setRemaining] = useState<number>(0)

  const state = useRef<string>('Active');
  useEffect(() => {
    getCount();
  }, [])

  const eventLogger = (event: unknown, error: unknown) => {
  }
  const tokenLogger = async (tokens: unknown) => {
    try {
      const userProfile = await keyCloak.loadUserProfile();
      const userData = {
        id: userProfile.id,
        username: userProfile.email,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        role: keyCloak.resourceAccess?.[`${process.env.REACT_APP_BE}`].roles[0]
      }
      const { setTokenAndPayload } = AuthService();
      await setTokenAndPayload(tokens as string, userData);
    } catch (error) {
      console.error('Error loading user profile:', error);
    }

  }
  async function getCount() {
    try {
      await axios.get(process.env.REACT_APP_BACKEND_SERVER_URL + '/route/users/getUserCount').then((response) => {
        setCount(response.data.data);
      })
    } catch (error) {
      console.error("errr", error);
      setErr("error")
    }
  }

  const onPresenceChange = (presence: PresenceType) => {
    // const isIdle = presence.type === 'idle'
    // const isPrompted = presence.type === 'active' && presence.prompted
    // const isActive = presence.type === 'active' && !presence.prompted
    // console.log("eeeeeeeeeeee", { isIdle, isPrompted, isActive })
  }

  const onActive = () => {
    state.current = 'Active'
    // setState('Active')

  }

  const handleOnIdle = () => {
    state.current = 'Idle';
    // setState('Idle');
    // Perform actions when the user becomes idle
  };

  const onPrompt = () => {
    state.current = 'Prompted'
  }

  if (err === "error") {
    return <ErrorPage />

  }

  if (count === undefined) {
    return <CustomLoader size={200} colors={'green'} styling={"fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 z-[2]"} />
  }
  return (
    <div>
      {(count > 0) ?
        (
          <IdleTimerProvider
            timeout={1000 * 60 * 15} // 15 minutes in milliseconds
            // timeout={20000}
            onIdle={handleOnIdle}
            // debounce={500}
            promptBeforeIdle={10000}
            throttle={500}
            onPresenceChange={onPresenceChange}
            onPrompt={onPrompt}
            onActive={onActive}
          >
            <ReactKeycloakProvider
              authClient={keyCloak}
              onEvent={eventLogger}
              onTokens={tokenLogger}
            >
              <App />
            </ReactKeycloakProvider>
          </IdleTimerProvider>
        ) : (
          <ReactKeycloakProvider
            authClient={keyCloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
          >
            <HomePage />
          </ReactKeycloakProvider>
        )}
    </div >
  )
}
ReactDOM.createRoot(container).render(<Main />);