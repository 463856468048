/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { LeaveAttendanceApi } from "../../../services/LeaveAttendance";
import { toast } from 'react-toastify';

interface DeleteConfirmationModalProps {
  isDeleteConfirmationOpen: any,
  userId: any,
  editDate: any,
  deletedStatus: any,
  resetEditAttendanceForm: any,
  closeDeleteConfirmation: any,
  getFullMonthTableDataHandle: any,
  onDeleteAttendance: (date: any) => void;
}
const DeleteConfirmationModalPage = ({ isDeleteConfirmationOpen, userId, editDate, deletedStatus, resetEditAttendanceForm, closeDeleteConfirmation, getFullMonthTableDataHandle, onDeleteAttendance }: DeleteConfirmationModalProps) => {
  const { deleteSingleAttendance } = LeaveAttendanceApi();
  // const [successDeleteMessage, setsuccessDeleteMessage] = useState('');
  // const [failureDeleteMessage, setfailureDeleteMessage] = useState('');


  const deleteAttendance = async () => {
    const deleteData = {
      userId: userId,
      date: editDate,
      status: deletedStatus,
    }
    let fetchedData;
    const deletedAttendanceData = await deleteSingleAttendance(deleteData);
    if (deletedAttendanceData?.data.status === 200) {
      getFullMonthTableDataHandle();
      resetEditAttendanceForm();
      // setTimeout(() => {
      closeDeleteConfirmation();
      // }, 2000);
      onDeleteAttendance(editDate);
      toast.success(deletedAttendanceData?.data.message);
      // setsuccessDeleteMessage(deletedAttendanceData?.data.message)
      // setTimeout(() => {
      //   setsuccessDeleteMessage('')
      // }, 2000);
      fetchedData = deletedAttendanceData?.data.data;
      return fetchedData;
    } else if (deletedAttendanceData?.data.status === 404) {
      getFullMonthTableDataHandle();
      resetEditAttendanceForm();
      // setfailureDeleteMessage(deletedAttendanceData?.data.message);
      toast.warn(deletedAttendanceData?.data.message);
      // setTimeout(() => {
      //   setfailureDeleteMessage('')
      // }, 2000);
      return fetchedData;
    } else if (deletedAttendanceData?.data.status === 500) {
      getFullMonthTableDataHandle();
      resetEditAttendanceForm();
      // setfailureDeleteMessage(deletedAttendanceData?.data.message)
      toast.error(deletedAttendanceData?.data.message);
      // setTimeout(() => {
      //   setfailureDeleteMessage('')
      // }, 2000);
      return fetchedData;
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      {isDeleteConfirmationOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-75"></div>

          <div className="modal-container bg-white w-4/5 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
            {/* Modal Content */}
            <div className="modal-content py-4 px-6 bg-slate-800 text-white">
              <p className="text-xl font-semibold">Delete Attendance</p>
              <p className="mt-2 text-left mb-4">Are you sure you want to delete this attendance record?</p>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md mr-2"
                  onClick={deleteAttendance}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-md"
                  onClick={closeDeleteConfirmation}
                >
                  Cancel
                </button>
              </div>
              <br />
              {/* Alert for delete attendance */}
              {/* {successDeleteMessage && (
                <div className="bg-green-500 text-white px-4 py-4 mt-4 rounded-md">{successDeleteMessage}</div>
              )} */}

              {/* {failureDeleteMessage && (
                <div className="bg-red-500 text-white px-4 py-4 mt-4 rounded-md">{failureDeleteMessage}</div>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DeleteConfirmationModalPage