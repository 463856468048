export interface User {
    id : string,
    username : string,
    firstName : string,
    lastName : string,
    role : string
}

export const AuthService = () => {
    const getUserPayload = async() =>{
        const user  = await (localStorage.getItem('userDetails')) ? JSON.parse(localStorage.getItem('userDetails') as string) : null;
        return user;
    }
   
    const getToken = () => {
        return localStorage.getItem('token');
    }

    const setTokenAndPayload = (tokens : string, userData : any) =>{
        localStorage.setItem('token',JSON.stringify(tokens))
        localStorage.setItem('userDetails',JSON.stringify(userData));
    }

    const resetTokenAndPayload = () =>{
        localStorage.setItem('token','')
        localStorage.setItem('userDetails','');
    }

    return { getUserPayload, getToken,setTokenAndPayload,resetTokenAndPayload };
};