import React from 'react'
import * as FaIcons from 'react-icons/fa'
import { GiExitDoor } from "react-icons/gi";

export const SidebarData = [
    {
        title: 'Dashboard',
        path: '/home',
        icon: <FaIcons.FaHome />,
        text: 'dashboard'
    },
    {
        title: 'People',
        path: '/people',
        icon: <FaIcons.FaUsers />,
        text: 'people'

    },
    {
        title: 'Leave & Attendance',
        path: '/leaveAttendance',
        icon: <FaIcons.FaTasks />,
        text: 'attendanceLeave'
    },
    {
        title: 'Salary Slips',
        path: '/salarySlip',
        icon: <FaIcons.FaMoneyBillWave />,
        text: 'salarySlip'

    },
    // {
    //     title: 'Payroll',
    //     path: '/payroll',
    //     icon: <FaIcons.FaMoneyBill />,
    //     text: 'payroll'

    // },
    {
        title: 'Run Payroll',
        path: '/runpayroll',
        icon: <FaIcons.FaRegMoneyBillAlt />,
        text: 'runPayroll'
    },
    {
        title: 'Documents',
        path: '/documents',
        icon: <FaIcons.FaPassport />,
        text: 'documents'

    },
    {
        title: 'Reimbursement',
        path: '/reimbursement',
        icon: <FaIcons.FaMoneyCheck />,
        text: 'reimbursement'

    },
    {
        title: 'Employee History',
        path: '/payablematrix',
        icon: <FaIcons.FaMoneyCheck />,
        text: 'payablematrix'

    },
    {
        title: 'Matrix Details',
        path: '/matrix',
        icon: <FaIcons.FaTable />,
        text: 'matrix'
    },
    {
        title: 'Designation',
        path: '/designation',
        icon: <FaIcons.FaCriticalRole />,
        text: 'designation'

    },

    {
        title: 'Exit',
        path: '/exit',
        icon: <GiExitDoor/>,
        text: 'exit'

    },

    {
        title: 'Reports',
        path: '/reports',
        icon: <FaIcons.FaPrint />,
        text: 'report'

    },

    {
        title: 'Setting',
        path: '/setting',
        icon: <FaIcons.FaScrewdriver />,
        text: 'setting'
    }
]
