import React, { useState } from "react";
import * as Yup from 'yup';

interface errFormData {
    name: string;
    email: string;
    isTeacher: string;
    dateOfResignation: string;
    lastWorkingDay:string;
    remarks:string
    reasonForResignation: string;
    comment: string;
}

interface FormData {
    name: string;
    email: string;
    isTeacher: string;
    dateOfResignation: string;
    lastWorkingDay:string;
    remarks:string
    reasonForResignation: string;
    comment: string;
}
interface ResignationProps {
    onClose: (data: any) => void;
}
const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name Must Contain Only Letters').required('Name is required'),
    email: Yup.string().email('Invalid Email Pattern').required('Email is required'),
    isTeacher: Yup.string().matches(/^[A-Za-z\s]+$/, 'Manager Name Must Contain Only Letters').required("Manager Name Required !!"),
    dateOfResignation: Yup.string().required('Date of Resignation is required'),
    reasonForResignation: Yup.string().required('Reason for Resignation is required'),
    comment: Yup.string(),
});
const initial: errFormData = {name:"", email:"", lastWorkingDay:"", isTeacher:"", comment:"", dateOfResignation: "", reasonForResignation:"", remarks: "" }


export default function ResignationEmp(props: ResignationProps) {
    const [err, setErr] = useState<errFormData>(initial);
    const [validation, setValidation] = useState(false);
    const [formData, setFormData] = useState<FormData>({name:"", email:"", lastWorkingDay:"", isTeacher:"", comment:"", dateOfResignation: "", reasonForResignation:"", remarks: "" });

    const handleChange = async (field: string, value: string) => {
        try {
            setErr((prevErrors) => ({
                ...prevErrors,
                [field]: '',
            }));

            setFormData((data) => ({
                ...data,
                [field]: value,
            }));

            await validationSchema.validate({ ...formData, [field]: value }, { abortEarly: false });

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errFormData]: message,
                }), {} as Partial<errFormData>);
                if (validation === true) {
                    setErr(prevErrors => ({ ...prevErrors, ...errors }));
                }
            }
        }
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        try {
            e.preventDefault();
            await validationSchema.validate(formData, { abortEarly: false });
            if (JSON.stringify(err) === JSON.stringify(initial)) {

                // const data = await postResignation(formData);
                // if (data.status === 200) {
                //     toast.success(data.message);
                //     props.onClose(false);
                // }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = error.inner.reduce((acc, { path, message }) => ({
                    ...acc,
                    [path as keyof errFormData]: message,
                }), {} as Partial<errFormData>);

                setErr(prevErrors => ({ ...prevErrors, ...errors }));
                setValidation(true);
            }
        }

    };
    return (
        <div className="bg-white mx-auto mt-14 p-4 rounded-lg">
            <h1 className="text-2xl">Employee Resignation</h1>
            <form className="max-w-md mx-auto p-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                        Name :
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) => {
                            handleChange('name', e.target.value)
                        }}
                        className="w-full p-1 mb-1 border border-gray-300 rounded-md"
                    />
                </div>
                {err.name && <div className='text-red-500 text-left text-xs '>{err.name}</div>}
                <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                    Email :
                </label>
                <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={(e) => {
                        handleChange('email', e.target.value)
                    }}
                    className="w-full p-1 mb-1 border border-gray-300 rounded-md"

                />
                {err.email && <div className='text-red-500 text-left text-xs '>{err.email}</div>}
                <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                    Manager :
                </label>
                <input
                    type="text"
                    name="isTeacher"
                    value={formData.isTeacher}
                    onChange={(e) => {
                        handleChange('isTeacher', e.target.value)
                    }}
                    className="w-full p-1 mb-1 border border-gray-300 rounded-md"
                />
                {err.isTeacher && <div className='text-red-500 text-left text-xs '>{err.isTeacher}</div>}
                <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                    Date of Resignation :
                </label>
                <input
                    type="date"
                    name="dateOfResignation"
                    value={formData.dateOfResignation}
                    onChange={(e) => {
                        handleChange('dateOfResignation', e.target.value)
                    }}
                    className="w-full p-1 mb-1 border border-gray-300 rounded-md"
                />
                {err.dateOfResignation && <div className='text-red-500 text-left text-xs '>{err.dateOfResignation}</div>}
                <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                    Reason for Resignation :
                </label>
                <textarea
                    name="reasonForResignation"
                    value={formData.reasonForResignation}
                    onChange={(e) => {
                        handleChange('reasonForResignation', e.target.value)
                    }}
                    className="w-full p-1 mb-1 border border-gray-300 rounded-md"
                ></textarea>
                {err.reasonForResignation && <div className='text-red-500 text-left text-xs '>{err.reasonForResignation}</div>}
                <label className="block mb-2 text-sm text-left font-bold text-gray-600">
                    Comment (optional) :
                </label>
                <textarea
                    name="comment"
                    value={formData.comment}
                    onChange={(e) => {
                        handleChange('comment', e.target.value)
                    }}
                    className="w-full p-1 mb-1 border border-gray-300 rounded-md"
                ></textarea>
                {err.comment && <div className='text-red-500 text-left text-xs '>{err.comment}</div>}
                <button
                    type="submit"
                    className=" p-2 bg-blue-500 text-sm text-white rounded-md hover:bg-blue-600"
                >
                    SUBMIT RESIGNATION
                </button>
            </form>
        </div>
    );
}
