import { useAxios } from "../utils/hooks";
import { useState } from 'react';



export const FingerMachineApi = () => {
    const AxiosInstance = useAxios();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchMachine = async () => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.get(`/route/attendance/getMachines`);
            return res.data.data
        } catch (err: any) {
            setError(err);
            ///for prevent from crash when offline
            return [];
        } finally {
            setLoading(false);
        }
    };


    const AddMachine = async (machine: any) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.post(`/route/attendance/addMachine`, machine);
            return res.data;
        } catch (err: any) {

        }
    };

    const deleteMachineid = async (data: number) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.delete(`/route/attendance/deleteMachine`, { data: { id: data } });
            return res.data;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const updateMachineid = async (machine: any) => {
        try {
            setLoading(true);
            const res: any = await AxiosInstance.current?.put(`/route/attendance/updateMachineid`, { id: machine.id, machineID: machine.machineID, ipAddress: machine.ipAddress });
            return res.data;
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };


    return { loading, error, AddMachine, fetchMachine, deleteMachineid, updateMachineid }

}