import React from 'react';
import { AiOutlineClose } from 'react-icons/ai'

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (

    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className=" rounded-lg shadow-lg">
        <div className="flex justify-end ">
          <AiOutlineClose
            className=" fill-red-600 hover:fill-red-700 hover:border-red-900 focus:ring-2 focus:outline-none focus:ring-red-300 active:fill-red-900 rounded-md text-3xl "
            onClick={onClose}
          />
        </div>
        <div >{children}</div>

      </div>
    </div>
  );
};

export default Modal;
