import React, { useEffect, useState } from "react";
import { Fullpage, Page } from '../../../components/Sidebar';
import { ReportsApi } from "../../../services/reports";
import moment from "moment";
import '../Reports.css'

interface Filter {
    viewValue: string,
    value: string
}

interface SearchCriteria {
    attribute: string;
    timeline: string;
}

export default function VarianceReportPage({ sidebar }: any) {
    const { getVarianceData, downloadExcelReport } = ReportsApi();
    const attributeFilter: Filter[] = [{ viewValue: 'Salary Disbursed', value: 'salaryDisbursed' },
    { viewValue: 'Employee Count', value: 'employeeCount' }, { viewValue: 'TDS/PF/PT/ESI', value: 'PFPT' }];

    const timelineFilter: Filter[] = [{ viewValue: 'Current Financial Year', value: 'currentFinancialYear' },
    { viewValue: 'Current Quarter', value: 'currentQuarter' }, { viewValue: 'Last Quarter', value: 'lastQuarter' },
    { viewValue: 'Last 3 Months', value: 'lastThreeMonths' }, { viewValue: 'Last 6 Months', value: 'lastSixMonths' },
    { viewValue: 'Last 12 Months', value: 'lastTwelveMonths' }];
    const currentYear = moment().year();
    for (let i = 2; i <= 10; i++) {
        let year = { viewValue: 'FY-' + (currentYear - i), value: (currentYear - i).toString() };
        timelineFilter.push(year);
    }



    const initalValues: SearchCriteria = {
        attribute: 'salaryDisbursed',
        timeline: 'currentFinancialYear'
    };
    const [filterData, setFilterData] = useState<SearchCriteria>(initalValues);
    const [varianceData, setVarianceData] = useState([]);

    useEffect(() => {


        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const search = async () => {
        const response = await getVarianceData(filterData);
        setVarianceData(response.data.data);
    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const downloadExcel = async () => {
        const response = await downloadExcelReport(varianceData, 'varianceReport');
        const blob = new Blob([response?.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'varianceReport.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <Page>
            <Fullpage close={sidebar}>
                <div className="w-11/12 p-5 mt-4 mb-4 min-h-svh bg-white rounded-lg shadow-lg">
                    <div className="w-full text-center p-3 m-3">
                        <p className="text-center font-bold text-2xl">VARIANCE REPORT</p>
                    </div>

                    <div className=" p-5 bg-gray-100 shadow-md sm:rounded-lg">
                        {/* <div className="justify-center text-xl font-medium text-slate-800 dark:text-white w-full" >
                            <label className="text-left p-9 m-6" htmlFor="type">Select Attribute:</label>
                            <label className="text-left p-9 m-6" htmlFor="fromDate">Select Timeline:</label>
                        </div> */}
                        <div className="flex items-center justify-center space-x-10 text-xl font-medium text-slate-800 dark:text-white">
                            <div className="grid col-span-1">
                                <label className="mt-2" htmlFor="type">Select Attribute:</label>
                                <select className="p-3 my-2 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="attribute" name="attribute" value={filterData.attribute} onChange={handleSelectChange}>
                                    <option value="">Select Attributes</option>
                                    {attributeFilter.map((item: any) => (
                                        <option key={item.value} value={item.value}>
                                            {item.viewValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid col-span-1">
                                <label className="mt-2 " htmlFor="fromDate">Select Timeline:</label>
                                <select className="p-3 m-2 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="timeline" name="timeline" value={filterData.timeline} onChange={handleSelectChange}>
                                    <option value="">Select Timeline</option>
                                    {timelineFilter.map((item: any) => (
                                        <option key={item.value} value={item.value}>
                                            {item.viewValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button className=" focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mt-8 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Search</button>
                        </div>
                    </div>
                    <br /><br />

                    <table className="table-fixed w-full rounded-lg shadow-lg bg-green-800 ">
                        <thead  >
                            <tr >
                                <th></th>
                                {Object.keys(varianceData[0] || {}).map((headItem) => (
                                    <th className="pt-5 pr-2">{headItem}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
                            {varianceData.map((item) => (

                                <tr className="h-20 divide-x divide-gray-300">
                                    {filterData.attribute === 'salaryDisbursed' && (
                                        <td >Salary</td>
                                    )}
                                    {filterData.attribute !== 'salaryDisbursed' && (
                                        <td >Count</td>
                                    )}
                                    {Object.keys(item).map((key) => (

                                        <td className="pt-1 pr-2">{item[key]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="p-3 m-2">
                        <div>
                            <button className="p-3 m-6 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={downloadExcel} >Download Excel</button>
                        </div>
                    </div>
                </div>
            </Fullpage>
        </Page>
    );
}
