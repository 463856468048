import React, { useEffect, useState } from "react";

import { Fullpage, Page } from '../../../components/Sidebar';
import moment from "moment";
import { ReportsApi } from "../../../services/reports";
import Pagination from "../../../components/Pagination";
// import { AiOutlineEye } from "react-icons/ai";

interface Filter {
    viewValue: string,
    value: string
}
export interface SearchLaonCriteria {
    type: string;
    fromDate: string;
    toDate: string;
}
export default function LoanSummaryPage({ sidebar }: any) {

    const typeFilter: Filter[] = [{ viewValue: 'All', value: 'All' },
    { viewValue: 'Active', value: 'Active' },
    { viewValue: 'Inactive', value: 'Inactive' }];
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentMonthDay = moment().daysInMonth().toString();
    const { getLoanSummaryData } = ReportsApi();
    const [ReimbursementData, setReimbursementData] = useState([])
    const initalValues: SearchLaonCriteria = {
        type: 'NULL',
        fromDate: moment([currentYear, currentMonth]).format('YYYY-MM-DD'),
        toDate: moment([currentYear, currentMonth, currentMonthDay]).format('YYYY-MM-DD')
    };
    const [filterData, setFilterData] = useState<SearchLaonCriteria>(initalValues);
    const [currentItems, setCurrentItems] = useState<SearchLaonCriteria[]>([]);

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const search = async () => {
        const response = await getLoanSummaryData(filterData);
        setReimbursementData(response.data.data);
    }

    return (
        <Page>

            <Fullpage close={sidebar}>
                <div className="w-4/5 min-h-svh mt-5 mb-5 bg-white rounded-xl shadow-lg">
                    <div >
                        <p className="text-center text-slate-800 p-5 font-bold text-2xl">LOAN SUMMARY REPORT</p>

                    </div>
                    <div className=" text-left bg-gray-100 shadow-md sm:rounded-lg min-w-max p-3 m-5">
                        <div className="flex items-center justify-center space-x-10">
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="type">Type:</label>
                                <select className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" id="type" name="type" value={filterData.type} onChange={handleSelectChange}>
                                    <option value="NULL">Select Type</option>
                                    {typeFilter.map((item: any) => (
                                        <option key={item.value} value={item.value}>
                                            {item.viewValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="fromDate">From Date:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="fromDate" name="fromDate" value={filterData.fromDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <label className="text-left font-bold ml-1" htmlFor="toDate">To Date:</label>
                                <input className="p-2 my-3 bg-gray-50 border border-gray-300 text-gray-800  text-sm rounded-lg" type="date" id="toDate" name="toDate" value={filterData.toDate} onChange={handleInputChange} />

                            </div>
                            <div className="grid col-span-1 text-xl font-medium text-slate-800 dark:text-white">
                                <button className="p-3  my-3 mt-9 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={search} >Apply Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-auto p-5'>
                        <table className="table-fixed rounded-md shadow-lg bg-green-800 overflow-hidden">
                            <thead className=" text-green-100">
                                <tr>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Employee Id</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Name</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Amount</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-100 uppercase tracking-wider">Emi Amount</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Emi Month</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Interest Rate</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Remaining Emi Month</th>
                                    <th className="text-center px-4 sm:px-6 py-3 bg-green-800 dark:bg-gray-800 text-xs sm:text-sm leading-4 font-medium text-green-100 dark:text-gray-300 uppercase tracking-wider">Remiaining EMi Amount</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 divide-y divide-gray-300 text-center">
                                {
                                    currentItems.length > 0 ? (
                                        currentItems.map((data: any, index: number) => (
                                            <tr key={index}>
                                                <td className="text-center border border-gray-400 p-2">R-{data && data.EmployeeCode ? data.EmployeeCode : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.NAME ? data.NAME : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.TotalLoanAmount ? data.TotalLoanAmount : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.EmiAmount ? data.EmiAmount : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.EmiMonth ? data.EmiMonth : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">
                                                    {data.InterestRate}
                                                </td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.RemainingEmiMonth ? data.RemainingEmiMonth : "N/A"}</td>
                                                <td className="text-center border border-gray-400 p-2">{data && data.RemainingAmount ? data.RemainingAmount : "N/A"}</td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td colSpan={8} className="text-center  p-2" >No data available</td>
                                        </tr>
                                    )}


                            </tbody>
                        </table>
                        <Pagination isValue={ReimbursementData} setCurrentItems={setCurrentItems} />

                    </div>

                </div>
            </Fullpage>
            
        </Page>
    );
}
