import React from 'react';
import { AppRouter } from './routes'
import './App.css';
import PrivateRoute from './routes/utils';
import { GlobalStyle } from './utils/global';
const App =  ()=> {
  
    return (
      <div className="App">
        <header className="App-header">
          <PrivateRoute>
          <GlobalStyle />
              <AppRouter />
          </PrivateRoute>
        </header>
      </div>
    );
}

export default App;


