import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProfileApi } from "../../../services/Profile";
import { Link } from "react-router-dom";


interface ResponseType {
    status: string;
    userId: number,
    employeeCode: string;
    isTeacher: string;
    separationdate: string;
    firstName: string;
    reasonforleaving: string;
}

interface props {
    setOpenResignation: Dispatch<SetStateAction<boolean>>;
    setUserResignationTable: Dispatch<SetStateAction<boolean>>;
    getIdFormUser: (id: number) => void
}


export default function UserResignationTable(props: props) {
    const { getResignationStatus } = ProfileApi();
    const [data, setdata] = useState<ResponseType[]>([])


    useEffect(() => {
        ResignationStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ResignationStatus = async () => {
        const data = await getResignationStatus();
        setdata(data.data)
    }

    const handleClick = () => {
        props.setOpenResignation(true);
        props.setUserResignationTable(false);
        props.getIdFormUser(NaN);
    }

    const handleForm = (id: number) => {
        props.setOpenResignation(true);
        props.setUserResignationTable(false);
        props.getIdFormUser(id);
    }


    return (
        <>
            <div className="mt-5 mr-28">
                <div className="flex justify-between">
                    <div className='text-center text-slate-800 p-3 font-bold text-3xl'>
                        Exit Employee Status
                    </div>
                    <div className="text-right">
                        {(data.length === 0 || data.some(item => item.status !== 'Approved')) && (
                            <button onClick={handleClick} className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5  dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                                Exit Form
                            </button>
                        )}
                    </div>

                </div>
                <table className=" table-auto w-full  rounded-md shadow-lg bg-green-800">
                    <thead className=" border-gray-100 text-green-100">
                        <tr>
                            <th scope="col" className="px-6 py-4">
                                Employee Id
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Employee Name
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Separation date
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Approved By
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Reason for leaving
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Reason for Reject
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((data: any, index: any) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" style={{ color: data.status === 'Approved' ? 'green' : data.status === 'Rejected' ? 'red' : 'gray' }} >
                                <td className="px-4 py-2 ">
                                    <Link onClick={() => handleForm(data.id)} to='#' >{data.employeeCode}</Link>
                                </td>
                                <td className="px-4 py-2">
                                    <Link onClick={() => handleForm(data.id)} to='#' >{data.firstName}</Link>
                                </td>
                                <td className="px-4 py-2 text-center" >
                                    {data.status}

                                </td>
                                <td className="px-4 py-2 text-center">
                                    {data.separationdate}

                                </td>
                                <td className="px-4 py-2 text-center">
                                    {data.approvedby}

                                </td>
                                <td className="px-4 py-2 text-center" >
                                    {data.reasonforleaving}

                                </td>
                                <td className="px-4 py-2 text-center" >
                                    {data.reasonforreject}

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}