import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { UsersApi } from '../../services/users';
import { FaArrowLeft } from 'react-icons/fa';

interface createTamplateProps {
    placeholder: string,
    createTamplate?: () => void,
}

export default function CreateDocTemplate(props: createTamplateProps) {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const { generateDoc, generateTemplate, readTemplate } = UsersApi();

    useEffect(() => {
        getTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: props.placeholder || 'Start typing...'
        }),
        [props.placeholder]
    );
    const generatePdfTemplete = async () => {
        const data = { Document_Type: "Customize", content: content }
        await generateTemplate(data);
    }
    const generatePdf = async () => {
        const data = { Document_Type: "Customize", content: content }
        await generateDoc(data);
    }

    const getTemplate = async () => {
        let response = await readTemplate("Customize");
        setContent(response.data);
    }


    return (
        <div className="w-full bg-white p-5 mt-5 mb-5 rounded-lg shadow-lg" >
            <div>
                <div className='text-left text-3xl font-semibold'>Create Tamplate
                    <FaArrowLeft className='fill-blue-500 font-bold float-right' onClick={() => props.createTamplate?.()}
                        data-tooltip-id={'my-tooltip'} data-tooltip-content="PeopleDetails" />
                </div>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    // @ts-ignore
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => { }}
                />

                <div className='text-left m-5'>
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={generatePdfTemplete}>Save Template</button>
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={generatePdf}>Generate Pdf</button>
                </div>
            </div>

        </div>
    );
};